import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import {setSelectedElementTypesGroupProxyInEditMeasurements} from "Features/elementTypes/elementTypesSlice";
import {
  setSelectedRessourceGroupProxyInEditMeasurements,
  setSelectedRessourceIdInEditMeasurements,
} from "Features/ressources/ressourcesSlice";
import {
  setSelectedElementTypeId,
  setSelectedMeasurementIds,
  setShortcutElementTypeGroups,
  setShortcutElementTypes,
} from "Features/measurements/measurementsSlice";
import ListElementTypesMultipleWithGroups from "Features/elementTypes/components/ListElementTypesMultipleWithGroups";
import ListRessourcesMultipleWithGroups from "Features/ressources/components/ListRessourcesMultipleWithGroups";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import getElementTypesWithMainQties from "Features/elementTypes/utils/getElementTypesWithMainQties";
import getMeasurementsCountByElementTypeGroupIdMap from "Features/elementTypes/utils/getMeasurementsCountByElementTypeGroupIdMap";
import {updateTempMeasurementFromElementType} from "Features/measurements/measurementsSlice";
import useSelectedMeasurementsModel from "Features/measurements/useSelectedMeasurementsModel";
import useFilteredMeasurementsFromPackages from "Features/measurements/useFilteredMeasurementsFromPackages";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import getRessourcesWithQuantities from "Features/ressources/utils/getRessourcesWithQuantities";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import getMeasurementsCountByRessourceGroupIdMap from "Features/ressources/utils/getMeasurementsCountByRessourceGroupIdMap";
import getItemsMapById from "Utils/getItemsMapById";
import getFilteredNomenclatureWithItems from "Utils/getFilteredNomenclatureWithItems";

import getMeasurementsByRessourceWithQuantity from "Features/measurements/utils/getMeasurementsByRessourceWithQuantity";
import useRessourcesGroupsProxyByScene from "Features/ressources/hooks/useRessourcesGroupsProxy";
import ListMeasurements from "Features/measurements/components/ListMeasurements";
import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";
import SectionArticlesInLeftPanel from "Features/articles/components/SectionArticlesInLeftPanel";

export default function SectionEditMeasurementsInLeftPanelV2({
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // data - tab

  const tab = useSelector((s) => s.leftPanel.tabInEditMeasurements);

  // data - measuremnts

  const measurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesBySelectedMeasurementsModel: true,
  });
  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  // data - groups

  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );
  const selectedRessourceGroupProxy = useSelector(
    (s) => s.ressources.selectedRessourceGroupProxyInEditMeasurements
  );

  // data - selected model

  const selectedModel = useSelectedMeasurementsModel();

  // data - types

  let types = useElementTypesBySceneProxy(scene);
  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );
  const shortcutElementTypeGroups = useSelector(
    (s) => s.measurements.shortcutElementTypeGroups
  );
  const restrictedIds = selectedModel?.restrictedTypes;
  const elementTypesGroupsProxy = useElementTypesGroupsProxyByScene(scene, {
    filterByIds: restrictedIds,
    filterByScope: true,
  });

  // data - ressources

  // const ressources = useRessourcesByScene(scene);

  // data - other entities

  const zones = useZonesByScene(scene);
  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);

  const entitiesMap = {
    zone: zones,
    sectors: sectors,
    rooms: rooms,
  };

  // helpers - restricted types

  const groups = selectedModel?.restrictedTypes;
  if (groups?.length > 0) {
    const gs = new Set(groups);
    types = types.filter((t) => gs.has(t.groupId));
  }

  // helpers - qties

  const typesWithMainQties = getElementTypesWithMainQties(
    types,
    measurements,
    filterNoVoids
  );

  // let ressourcesWithQty = getRessourcesWithQuantities(
  //   ressources,
  //   measurements,
  //   types,
  //   entitiesMap,
  //   filterNoVoids
  // );

  // const ressourcesOfSelectedGroup = ressourcesWithQty.filter(
  //   (r) => r.group === selectedRessourceGroupProxy?.name
  // );

  // const ressourcesWithNotNullQuantities = ressourcesWithQty.filter(
  //   (r) => typeof r.qtyRaw === "number"
  // );

  // // compute the groups to display in the list : groups with no null qties / or not
  // const groupsProxy = useRessourcesGroupsProxyByScene(scene, {
  //   sortByName: true,
  //   filterByScope: true,
  // });

  // const groupsProxyWithNoNullRessources = [];
  // ressourcesWithNotNullQuantities.forEach((res) => {
  //   if (res.qtyRaw) {
  //     if (
  //       groupsProxyWithNoNullRessources.filter(
  //         (groupProxy) => groupProxy.name === res.group
  //       ).length === 0
  //     ) {
  //       groupsProxyWithNoNullRessources.push({
  //         id: res.group,
  //         name: res.group,
  //         fromScene: true,
  //       });
  //     }
  //   }
  // });

  // data - selection

  const selectedElementTypeId = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );
  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceIdInEditMeasurements
  );

  // helper - elementTypesMap

  const elementTypesMap = getItemsMapById(types);

  // helpers - groupCountMap

  const groupCountMap = getMeasurementsCountByElementTypeGroupIdMap(
    measurements,
    types
  );
  // const ressourceGroupCount = getMeasurementsCountByRessourceGroupIdMap(
  //   measurements,
  //   ressources
  // );

  // helper - measurementsWithType

  const measurementsWithType = measurements.map((m) => ({
    ...m,
    elementType: elementTypesMap[m.elementTypeId],
  }));

  // handlers

  function handleSelectionChangeMeasurements(ids) {
    dispatch(setSelectedMeasurementIds(ids));
  }

  function handleSelectionChange(ids) {
    let id = null;
    if (Array.isArray(ids) && ids.length > 0) {
      id = ids[0];
    }
    dispatch(setSelectedElementTypeId(id));
    if (id) dispatch(updateTempMeasurementFromElementType(elementTypesMap[id]));
  }

  function handleGroupProxyChange(groupProxy) {
    console.log("click on groupProxy", groupProxy);
    dispatch(setSelectedElementTypesGroupProxyInEditMeasurements(groupProxy));
  }

  function handleRessourceSelectionChange(ids) {
    console.log("selected ressource group", ids);
    let id = null;
    if (Array.isArray(ids) && ids.length > 0) {
      id = ids[0];
    }
    dispatch(setSelectedRessourceIdInEditMeasurements(id));
  }
  function handleRessourceGroupProxyChange(groupProxy) {
    console.log("click on groupProxy", groupProxy);
    dispatch(setSelectedRessourceGroupProxyInEditMeasurements(groupProxy));
  }
  function handleVisibleGroupIdsChange(ids) {
    dispatch(setShortcutElementTypeGroups(ids));
  }
  //
  return (
    <Box
      sx={{
        flexGrow: 1,
        //border: "2px solid red",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        //justifyItems: "stretch",
        minHeight: 0, // make it works !!!
      }}
    >
      {tab === "ELEMENT_TYPES" && (
        <ListElementTypesMultipleWithGroups
          elementTypes={typesWithMainQties}
          selectedElementTypeIds={[selectedElementTypeId]}
          onSelectionChange={handleSelectionChange}
          multipleSelection={false}
          groupsProxy={elementTypesGroupsProxy}
          selectedGroupId={selectedGroupProxy?.id}
          onSelectedGroupProxyChange={handleGroupProxyChange}
          counters={groupCountMap}
          showZero={true}
          forceSelection={true}
          maxHeightGroups={180}
          variant="list"
          showVisibility={true}
          visibleIds={shortcutElementTypes}
          onVisibleIdsChange={(ids) => dispatch(setShortcutElementTypes(ids))}
          visibleGroupIds={shortcutElementTypeGroups}
          onVisibleGroupIdsChange={handleVisibleGroupIdsChange}
          showMainQuantities={true}
        />
      )}
      {tab === "ARTICLES" && (
        <SectionArticlesInLeftPanel scene={scene} caplaEditor={caplaEditor} />
      )}
      {tab === "MEASUREMENTS" && (
        <ListMeasurements
          caplaEditor={caplaEditor}
          multiple={true}
          measurements={measurementsWithType}
          selection={selectedMeasurementIds}
          onSelectionChange={handleSelectionChangeMeasurements}
        />
      )}
    </Box>
  );
}

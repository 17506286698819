import useScopesByScene from "Features/scopes/hooks/useScopesByScene";

import {Box, List, ListItemButton, Typography, Paper} from "@mui/material";

export default function SectionSelectorScopeList({onClick, scene}) {
  // strings

  const selectS = "Sélectionnez une sous-bimbox";

  // data

  const scopes = useScopesByScene(scene, {sortByName: true, addScopeAll: true});

  // handlers

  function handleClick(scope) {
    onClick(scope);
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        justifyItems: "stretch",
        justifyContent: "center",
        color: "grey.400",
        p: 3,
      }}
    >
      <Box sx={{p: 2}}>
        <Typography>{selectS}</Typography>
      </Box>
      <Paper
        elevation={12}
        sx={{
          bgcolor: "common.caplaBlack",
          p: 1,
          overflowY: "auto",
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
          color: "inherit",
        }}
      >
        <List>
          {scopes.map((scope) => (
            <ListItemButton key={scope.id} onClick={() => handleClick(scope)}>
              <Box>
                <Typography>{scope.name}</Typography>
              </Box>
            </ListItemButton>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

import {nanoid} from "@reduxjs/toolkit";
import {client} from "API/capla360";

import {
  createScene,
  updateScene,
  deleteScene,
  fetchSceneById,
} from "Database/services";

import {createStoredModel} from "Database/models/services";

import {createStoredFile, deleteStoredFile} from "Database/files/services";
// import {createStoredRessource} from "Database/ressources/services";
import {
  createStoredScene,
  fetchStoredScene,
  updateStoredScene,
  fetchStoredScenes,
} from "Database/scenes/services";

import {downloadFileService, uploadFileService} from "Features/files/services";

import {urlToFile} from "Features/files/utils";

import {
  remoteModeltoClientModel,
  sharedRemoteSceneToState,
  remoteSceneToState,
} from "./utils";

/*
 * clientId may be undefined.
 * clientId defined when converting remote to local scenes & model.
 */

export async function createSceneService({
  title,
  bimboxMode,
  clientReference,
  description,
  operationName,
  createdAt,
  createdBy,
  imageFile,
  id,
  imageUrl,
  clientId,
  version,
  data,
  fromRemote = false,
  localStorage = 1,
  lat,
  lng,
  altitude,
  worksite,
  offset,
  logosUrl,
  remoteDataOnly,
  tradesName,
  buildingsName,
  prodStatus,
  prodDeadline,
  contactEmail,
  onboardingUrl,
}) {
  // file

  let file = imageFile;
  let storedFile;
  //if (!remoteDataOnly) storedFile = await createStoredFile({file});

  // next sync

  let nextSync = {action: true, create: true, data: true, image: Boolean(file)};

  if (fromRemote) nextSync = {action: false, data: false, image: false};

  // scene

  const scene = {
    id,
    clientReference,
    bimboxMode,
    clientId: clientId ? clientId : nanoid(),
    nextSync,
    version,
    data,
    title,
    description,
    operationName,
    createdBy,
    createdAt,
    imageUrl,
    imageClientUrl: storedFile && URL.createObjectURL(file),
    imageFileClientId: storedFile?.clientId,
    localStorage,
    lat,
    lng,
    altitude,
    worksite,
    offset,
    logosUrl,
    tradesName,
    buildingsName,
    prodStatus,
    prodDeadline,
    contactEmail,
    onboardingUrl,
  };

  //createStoredScene({storedScene: scene});

  console.log("SCENE CREATED IN STATE 41", scene);

  return {scene};
}

export async function createRemoteSceneService(
  {sceneClientId, accessToken},
  {getState}
) {
  const state = getState();

  const scenes = state.scenes.items;
  const stateScene = scenes.find((s) => s.clientId === sceneClientId);

  console.log("CREATE REMOTE SCENE SERVICE", stateScene);

  let file;
  if (stateScene.imageClientUrl)
    file = await urlToFile(stateScene.imageClientUrl, stateScene.clientId);

  //const {scene: storedScene, file} = await fetchStoredScene(sceneClientId);

  const remoteScene = {...stateScene};
  remoteScene.data = JSON.stringify(stateScene.data);

  if (!stateScene.id) {
    // no remote scene...
    // create scene
    const response = await client.post(`scenes/`, remoteScene, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response?.data) {
      // the scene was created server side
      const newStateScene = {
        ...stateScene,
        id: response.data.id,
        version: response.data.version,
        nextSync: {action: false},
      };

      // send file if any
      if (file) {
        const container = "scene-image";
        const sceneId = newStateScene.id;
        const url = await uploadFileService({
          file,
          sceneId,
          accessToken,
          container,
        });
        newStateScene.imageUrl = url;

        const updatedRemoteScene = {id: newStateScene.id, imageUrl: url};
        // update with the new files
        const {data: updatedScene} = await client.put(
          `/scenes/${newStateScene.id}`,
          updatedRemoteScene,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        newStateScene.version = updatedScene.version;
      }

      updateStoredScene({scene: newStateScene});
      return newStateScene;
    }
  }
}

export async function updateSceneService({
  scene,
  file,
  imageOnly = false,
  fromRemote = false,
}) {
  // const {scene: storedScene} = await fetchStoredScene(scene.clientId);
  // const newScene = {...storedScene, ...scene};
  const newScene = {...scene};

  let nextSync = {...scene.nextSync, action: true};
  if (fromRemote) nextSync = {action: false};

  if (file) {
    if (scene.imageFileClientId)
      await deleteStoredFile(scene.imageFileClientId);
    const imageFile = await createStoredFile({file});
    newScene.imageClientUrl = URL.createObjectURL(file);
    newScene.imageFileClientId = imageFile.clientId;
    nextSync.image = true;
  }

  if (!nextSync.data) nextSync.data = !imageOnly;

  const updatedScene = {...newScene, nextSync};

  // await updateStoredScene({scene: updatedScene});

  console.log("updatedSceneSSS :", updatedScene);

  return updatedScene;
}

export async function updateRemoteScenePartialService({
  accessToken,
  sceneId,
  updates,
}) {
  console.log("[SERVICES] updateRemoteScenePartial", sceneId, updates);
  const response = await client.put(`/scenes/${sceneId}`, updates, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const updatedFields = Object.keys(updates);
  const remoteScene = response.data;
  return {updatedFields, remoteScene, sceneId};
}

export async function updateRemoteSceneService(
  {sceneClientId, accessToken, updatedScene},
  {getState}
) {
  const state = getState();
  const scenes = state.scenes.items;
  const allScenes = [...scenes];

  let scene = updatedScene; // to manage case where the scene doesn't yet exist in the store. (update from home page)
  if (!scene) scene = allScenes.find((s) => s.clientId === sceneClientId);

  console.log("UPDATE REMOTE SCENE", scene);
  // fetch stored scene
  //const {scene: storedScene, file} = await fetchStoredScene(sceneClientId);

  // use state scene instead
  const stateScene = scene;

  const action = stateScene.nextSync.action;
  const syncData = stateScene.nextSync.data;
  const syncImage = stateScene.nextSync.image;

  let newImageUrl = stateScene.imageUrl;

  if (syncImage) {
    const file = await urlToFile(scene.imageClientUrl, scene.clientId);
    newImageUrl = await uploadFileService({
      file,
      sceneId: stateScene.id,
      accessToken,
      container: "scene-image",
    });
  }

  // data transform

  let newScene = {...stateScene};
  const data = stateScene.data ? stateScene.data : {};
  newScene.data = JSON.stringify(data);

  // delete unnecessary fields (or with object instead of id)

  delete newScene.createdBy;

  if (
    Object.prototype.toString.call(stateScene.worksite) === "[object Object]"
  ) {
    newScene.worksite = scene.worksite.id;
  }

  if (!syncData && syncImage) {
    newScene = {id: newScene.id, imageUrl: newImageUrl};
  }

  if (syncData && syncImage) {
    newScene.imageUrl = newImageUrl;
  }

  // update remote scene
  const response = await client.put(`/scenes/${stateScene.id}`, newScene, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const updatedRemoteScene = response.data;

  // new stateScene

  const newStateScene = {
    ...updatedRemoteScene,
    data: updatedRemoteScene.data ? JSON.parse(updatedRemoteScene.data) : {},
    nextSync: {action: false},
  };

  console.log("updatedSceneAAA", newStateScene);
  //updateStoredScene({scene: newStateScene});

  return newStateScene;
}

export async function deleteSceneService(scene) {
  await deleteScene(scene);
  return scene;
}

export async function deleteRemoteSceneService({scene, accessToken}) {
  const response = await client.delete(`/scenes/${scene.id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  await deleteSceneService(scene);
  return scene;
}

export async function fetchStoredSceneService(sceneClientId) {
  const {scene, file} = await fetchStoredScene(sceneClientId);
  let imageClientUrl;
  if (file) imageClientUrl = URL.createObjectURL(file);
  return {...scene, imageClientUrl};
}

export async function fetchStoredScenesService() {
  const scenes = await fetchStoredScenes();
  return scenes;
}

export async function updateRemoteLogosUrlService({
  scene,
  logosUrl,
  accessToken,
}) {
  const newScene = {id: scene.id, logosUrl};
  // update remote scene
  const response = await client.put(`/scenes/${scene.id}`, newScene, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const newStoredScene = {...scene, logosUrl};
  await updateStoredScene({scene: newStoredScene});
  return newStoredScene;
}

// share link

export async function readShareLinkService({scene, accessToken}) {
  const sharedurlId = scene?.shareLink?.split("/").pop();
  const response = await client.get(`sharedurls/${sharedurlId}/update`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function getShareLinkService({sceneId, scopeId, accessToken}) {
  try {
    if (sceneId) {
      const params = {};
      if (scopeId) params.scope = scopeId;
      const response = await client.get(`scenes/${sceneId}/sharedurls/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
      });

      const shareLinkId = response.data.id;
      const baseURL = process.env.REACT_APP_SHARED_BOX_BASE_URL;
      const shareLink = `${baseURL}/${shareLinkId}`;

      return {shareLink, sceneId: sceneId, scopeId: scopeId};
    }
  } catch (e) {
    console.log("error", e);
  }
}

export async function updateShareLinkOgImageService({
  scene,
  accessToken,
  shareLink,
  url,
}) {
  const sharedurlId = shareLink.split("/").pop();
  const sharedurl = {id: sharedurlId, ogImageUrl: url};
  const response = await client.put(
    `sharedurls/${sharedurlId}/update`,
    sharedurl,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  console.log("share link updated", response.data);
}

// ---------------------
// ---* Fetch scene *---
// ---------------------

export async function fetchRemoteSceneOverviewService({sceneId, accessToken}) {
  try {
    const response = await client.get(`scenes/${sceneId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        variant: "overview",
      },
    });
    const remoteSceneOverview = response.data;
    console.log("fetch remoteSceneOverview", remoteSceneOverview);
    return {remoteSceneOverview, sceneId};
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function fetchRemoteScenesService({accessToken}) {
  try {
    const response = await client.get("scenes/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const remoteScenes = response.data;
    return remoteScenes;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

// /!\ scene & models data will be updated in the state, without any local storage...
export async function fetchRemoteSceneService({sceneId, accessToken, options}) {
  const updateSceneStateOnly = options?.updateSceneStateOnly;
  try {
    const response = await client.get(`scenes/${sceneId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const remoteScene = response.data;
    const stateRemoteScene = remoteSceneToState(remoteScene);
    console.log("debugafa 1408 remoteScene", stateRemoteScene);
    return {remoteScene: stateRemoteScene, updateSceneStateOnly};
  } catch (e) {
    console.log("ERROR fetching remote scene", e);
  }
}

export async function fetchRemoteSceneLightService({sceneId, accessToken}) {
  try {
    const response = await client.get(`scenes/${sceneId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        variant: "light",
      },
    });
    const remoteSceneLight = response.data;
    const stateSceneLight = remoteSceneToState(remoteSceneLight);

    return stateSceneLight;
  } catch (e) {
    console.log(e);
  }
}

export async function readRemoteSceneService({sceneId, accessToken}) {
  try {
    const response = await client.get(`scenes/${sceneId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const remoteScene = response.data;
    const stateRemoteScene = remoteSceneToState(remoteScene);
    return stateRemoteScene;
  } catch (e) {
    console.log(e);
  }
}

// used in sync Dialog to check versions diff
export async function fetchSceneToSyncService({sceneId, accessToken}) {
  try {
    const response = await client.get(`scenesToSync/${sceneId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const remoteScene = response.data;
    const stateRemoteScene = remoteSceneToState(remoteScene);
    return stateRemoteScene;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchSharedSceneService({shareLink, accessToken}) {
  const response = await client.get(`sharedurls/${shareLink}`, {
    // headers: {
    //   Authorization: `Bearer ${accessToken}`,
    // },
  });
  const fetchScene = response.data;
  const blob = await downloadFileService({
    url: fetchScene.imageUrl,
    fileName: fetchScene.title,
  });

  // create stored scene if it doesn't exist

  const existingScene = fetchSceneById(fetchScene.id);
  let newScene;
  if (existingScene) {
    newScene = existingScene;
  } else {
    newScene = {
      clientId: nanoid(),
      id: fetchScene.id,
      title: fetchScene.title,
      imageClientUrl: URL.createObjectURL(blob),
      imageUrl: fetchScene.imageUrl,
    };
    await createScene({scene: newScene, blob});
  }

  // create stored models

  await Promise.all(
    fetchScene.models.map(async (rm) => {
      const model = remoteModeltoClientModel(rm, newScene.clientId);
      const blob = await downloadFileService({
        url: rm.fileRemoteUrl,
        fileName: rm.name,
      });
      createStoredModel({model, file: blob});
    })
  );

  return newScene;
}

export async function fetchSharedRemoteSceneService({
  shareLink,
  caplaEditor,
  options,
}) {
  const debug = options?.debug;
  const response = await client.get(`sharedurls/${shareLink}`);
  const sharedRemoteScene = response.data;
  // we create the client ids
  console.log(
    "[DEBUG98] sharedRemoteScene",
    sharedRemoteScene,
    sharedRemoteScene.models.filter((m) => m.type === "MEASUREMENTS")
  );
  //
  try {
    const {
      scene,
      scope,
      models,
      notes,
      issues,
      listings,
      elementTypesGroups,
      articlesGroups,
    } = sharedRemoteSceneToState(sharedRemoteScene);

    if (!debug) {
      models
        .filter((m) => m.type === "MEASUREMENTS")
        .forEach((model) => {
          if (!model.archived)
            caplaEditor?.measDataManager?.createOrUpdateModelInManager(model);
        });

      return {
        scene,
        scope,
        models,
        notes,
        issues,
        listings,
        elementTypesGroups,
        articlesGroups,
        //ressourcesGroups,
      };
    } else {
      return {};
    }
  } catch (e) {
    console.log("error", e);
  }
}

// ---------------------
// ---* Memberships *---
// ---------------------

export async function fetchSceneMembershipsService({accessToken, sceneId}) {
  try {
    const response = await client.get(`scenes/${sceneId}/memberships/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function addSceneMembershipService({
  accessToken,
  sceneId,
  membership,
}) {
  try {
    const response = await client.post(
      `scenes/${sceneId}/memberships/`,
      membership,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function updateSceneMembershipsService({
  accessToken,
  sceneId,
  memberships,
}) {
  try {
    const response = await client.put(
      `scenes/${sceneId}/memberships/`,
      {memberships},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return {scene: response.data};
  } catch (e) {
    console.log(e);
  }
}

export async function deleteSceneMembershipsService({
  accessToken,
  sceneId,
  memberships,
}) {
  try {
    const response = await client.delete(`scenes/${sceneId}/memberships/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        memberships,
      },
    });
    return {scene: response.data};
  } catch (e) {
    console.log(e);
  }
}

// -----------------------
// ---* Remote Models *---
// -----------------------

export async function deleteRemoteModelService({
  accessToken,
  modelRemoteId,
  modelId,
}) {
  try {
    const response = await client.delete(`models/${modelRemoteId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return {modelRemoteId, modelId, data: response.data};
  } catch (e) {
    console.log(e);
  }
}

// -------------------
// ---* Liveviews *---
// -------------------

export async function updateLiveviewsService({
  accessToken,
  sceneId,
  liveviews,
}) {
  //const remoteLiveviews = {data: JSON.stringify(liveviews.data)};
  try {
    const response = await client.put(
      `scenes/${sceneId}/liveviews/`,
      liveviews,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const views = response.data;
    //const liveviews = {data: JSON.parse(views.data)};
    return views;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchLiveviewsService({accessToken, sceneId}) {
  try {
    const response = await client.get(`scenes/${sceneId}/liveviews/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const liveviews = response.data;
    return liveviews;
  } catch (e) {
    console.log(e);
  }
}

import {Route, Routes, BrowserRouter} from "react-router-dom";

import App from "Pages/App";

import PageToolbox from "Features/toolbox/components/PageToolbox";
import PageOrganisations from "Features/organisations/components/PageOrganisations";

// import Home from "Pages/Home"; // DEPRECATED ? Entire Pages/Home ?
// import Landing from "Pages/Landing";
// import Listings from "Pages/Listings";
// import CreateListing from "Pages/CreateListing";
// import Settings from "Pages/Settings";
import Test from "Pages/Test";
// import Draft from "Pages/Draft";
import Auth from "Pages/Auth";
import Logout from "Pages/Logout";
// import User from "Pages/User";
// import Images from "Pages/Images";
// import Report from "Pages/Report";
// import Note from "Pages/Note";
// import Apps from "Pages/Apps";
// import Operations from "Pages/Operations";
// import Operation from "Pages/Operation";
// import Docset from "Pages/Docset";
// import DocsetAdmin from "Pages/DocsetAdmin/DocsetAdmin";
// import Mapset from "Pages/Mapset/Mapset";
// import Members from "Pages/Members";
// import Noteset from "Pages/Noteset";
// import Objectset from "Pages/Objectset";
// import Psetset from "Pages/Psetset";
// import Rowset from "Pages/Rowset";
// import Contributionset from "Pages/Contributionset";
// import ExcelContributions from "Pages/ExcelContributions";
// import RelatedListings from "Pages/RelatedListings";
// import Services from "Pages/Services";
// import ListingDebug from "Pages/ListingDebug";
// import Mapviewset from "Pages/Mapviewset";
// import SharedOpeningChange from "Pages/SharedOpeningChange";
import Scenes from "Pages/Scenes";
// import SceneNew from "Pages/SceneNew";
import SceneHome from "Pages/SceneHome";
import PageConfigModules from "Features/navigation/components/PageConfigModules";
import PageHomeAdmin from "Features/boxhome/components/PageHomeAdmin";
// import PageDatasets from "Features/datasets/components/PageDatasets";
// import SceneBuild from "Pages/SceneBuild";
// import SceneBuildScenario from "Pages/SceneBuildScenario";
// import SceneData from "Pages/SceneData";
import PageInputs from "Features/inputs/components/PageInputs";
import PageRooms from "Features/rooms/components/PageRooms";
import PageSceneLibrary from "Features/library/components/PageSceneLibrary";
// import SceneNomenclature from "Pages/SceneNomenclature";
import SceneMeasurements from "Pages/SceneMeasurements";
// import SceneQuantities from "Pages/SceneQuantities";
// import SceneNotes from "Pages/SceneNotes";
import SceneIssues from "Pages/SceneIssues";
// import PageIssuesets from "Features/issuesets/components/PageIssuesets";
import PageBimapps from "Features/bimapps/components/PageBimapps";
import PageSharedboxes from "Features/sharedboxes/components/PageSharedbox";
import SceneZones from "Pages/SceneZones";
import SceneSectors from "Pages/SceneSectors";
import ScenePhases from "Pages/ScenePhases";
import SceneVersions from "Pages/SceneVersions";
import SceneOnboarding from "Pages/SceneOnboarding";

import SceneRessources from "Pages/SceneRessources";
import SceneElementTypes from "Pages/SceneElementTypes";
import PageRelations from "Features/relations/components/PageRelations";
import SceneProgress from "Pages/SceneProgress";
import SceneMarkers from "Pages/SceneMarkers";
// import SceneViewer3D from "Pages/SceneViewer3D";
// import SceneIfc from "Pages/SceneIfc";
// import SceneViewerPdf from "Pages/SceneViewerPdf";
// import SceneStory from "Pages/SceneStory";
// import SceneViewer from "Pages/SceneViewer";
import SharedScene from "Pages/SharedScene";
// import SharedSceneViewer from "Pages/SharedSceneViewer"; // DEPRECATED ? Entire Pages/SharedSceneViewer ?
// import SceneModel from "Pages/SceneModel";
// import SceneLanding from "Pages/SceneLanding"; // DEPRECATED ? Entire Pages/SceneLanding ? (import SceneLanding from "./SceneLanding"; depuis src/Features/vewer3D/js/Editor.js)
// import Worksites from "Pages/Worksites";
// import Space from "Pages/Space";
import Profile from "Pages/Profile";

// import PageAssistant from "Features/buildbox/components/PageAssistant";
// import StepLogin from "Pages/Steps/StepLogin";
// import StepAddFile from "Pages/Steps/StepAddFile";
// import StepCreateListing from "Pages/Steps/StepCreateListing";
// import StepCreateIssue from "Pages/Steps/StepCreateIssue";
// import StepShare from "Pages/Steps/StepShare";
// import StepConnect from "Pages/Steps/StepConnect";
// import StepChangeSceneImage from "Pages/Steps/StepChangeSceneImage";
// import StepChangeSceneName from "Pages/Steps/StepChangeSceneName";
// import StepEnd from "Pages/Steps/StepEnd";
// import StepSync from "Pages/Steps/StepSync";
// import StepRenameModel from "Pages/Steps/StepRenameModel";
// import StepCreateSceneForReview from "Pages/Steps/StepCreateSceneForReview";

// import SceneSettingsInfo from "Pages/SceneSettings/SceneSettingsInfo";
// import SceneSettingsStorage from "Pages/SceneSettings/SceneSettingsStorage";

// import PageNewColoring from "Features/colorings/components/PageNewColoring";
// import PageNewJobColoring from "Features/coloringJobs/components/PageNewJobColoring";
// import PageColorings from "Features/colorings/components/PageColorings";
// import PageColoring from "Features/colorings/components/PageColoring";
// import PageLibrary from "Features/library/components/PageLibraryTypes";
import PagePackages from "Features/elementPackages/components/PagePackages";
import PageAnnotatedPdf from "Features/annotatedPdf/components/PageAnnotatedPdf";
// import StepDiscoverUi from "Pages/Steps/StepDiscoverUi";
import PageMaterials from "Features/materials/components/PageMaterials";
import PagePricings from "Features/pricing/components/PagePricings";
import PageBimboxOrder from "Features/ebusiness/components/PageBimboxOrder";
import PageViews from "Features/views/components/PageViews";
import PageView from "Features/views/components/PageView";
import PageReports from "Features/reports/components/PageReports";
import PageSurveys from "Features/surveys/components/PageSurveys";
import PageSamples from "Features/samples/components/PageSamples";
import PageScopes from "Features/scopes/components/PageScopes";
import PageArticles from "Features/articles/components/PageArticles";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />}>
          <Route index element={<Scenes />} />
          <Route exact path="/toolbox" element={<PageToolbox />} />
          <Route exact path="/organisations" element={<PageOrganisations />} />
          <Route
            exact
            path="/order/:sharedBimboxId"
            element={<PageBimboxOrder />}
          />
          {/* <Route exact path="/worksites" element={<Worksites />} />
          <Route
            exact
            path="/worksites/:worksiteId/workspace"
            element={<Space />}
          /> */}
          <Route
            exact
            path="/shared/bimbox/:shareLink/viewer"
            //element={<SharedSceneViewer />}
            element={<SceneHome />}
          />
          <Route exact path="/bimboxes/:sceneId" element={<PageHomeAdmin />} />
          {/* <Route exact path="/colorings" element={<PageColorings />} /> */}
          {/* <Route
            exact
            path="/colorings/:coloringId"
            element={<SceneMeasurements />}
          />
          <Route
            exact
            path="/coloringJobs/:coloringJobId/newColoring"
            element={<PageNewJobColoring />}
          />
          <Route exact path="/library" element={<PageLibrary />} />
          <Route exact path="/bimboxes/new" element={<SceneNew />} />
          <Route exact path="/bimboxes/:sceneId" element={<PageHomeAdmin />} />
          <Route
            exact
            path="/bimboxes/:sceneId/admin"
            element={<PageHomeAdmin />}
          /> */}
          {/* <Route
            exact
            path="/bimboxes/:sceneId/datasets"
            element={<PageDatasets />}
          /> */}
          <Route
            exact
            path="/bimboxes/:sceneId/scopes"
            element={<PageScopes />}
          />
          <Route
            path="/bimboxes/:sceneId/modules"
            element={<PageConfigModules />}
          />
          <Route
            path="/bimboxes/:sceneId/onboarding"
            element={<SceneOnboarding />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/inputs"
            element={<PageInputs />}
          />
          {/* <Route
            exact
            path="/bimboxes/:sceneId/build/"
            element={<SceneBuild />}
          >
            <Route exact path="info" element={<SceneSettingsInfo />} />
            <Route exact path="storage" element={<SceneSettingsStorage />} />
          </Route> */}
          {/* <Route path="/bimboxes/:sceneId/data" element={<SceneData />} /> */}
          <Route
            exact
            path="/bimboxes/:sceneId/markers"
            element={<SceneMarkers />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/zones"
            element={<SceneZones />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/sectors"
            element={<SceneSectors />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/versions"
            element={<SceneVersions />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/rooms"
            element={<PageRooms />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/library"
            element={<PageSceneLibrary />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/phases"
            element={<ScenePhases />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/elementTypes"
            element={<SceneElementTypes />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/relations"
            element={<PageRelations />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/packages"
            element={<PagePackages />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/annotatedPdf/:packageClientId"
            element={<PageAnnotatedPdf />}
          />
          {/* <Route
            path="/bimboxes/:sceneId/nomenclature"
            element={<SceneNomenclature />}
          /> */}
          {/* <Route
            path="/bimboxes/:sceneId/quantities"
            element={<SceneQuantities />}
          /> */}
          <Route
            path="/bimboxes/:sceneId/ressources"
            element={<SceneRessources />}
          />
          <Route
            path="/bimboxes/:sceneId/articles"
            element={<PageArticles />}
          />
          <Route
            path="/bimboxes/:sceneId/:modelId?/measurements"
            element={<SceneMeasurements />}
          />
          <Route
            path="/bimboxes/:sceneId/progress"
            element={<SceneProgress />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/bimapps"
            element={<PageBimapps />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/sharedboxes"
            element={<PageSharedboxes />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/views"
            element={<PageViews />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/views/:viewId"
            element={<PageView />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/reports"
            element={<PageReports />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/surveys"
            element={<PageSurveys />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/samples"
            element={<PageSamples />}
          />
          {/* <Route
            exact
            path="/bimboxes/:sceneId/notes"
            element={<SceneNotes />}
          /> */}
          <Route
            exact
            path="/bimboxes/:sceneId/issues"
            element={<SceneIssues />}
          />
          {/* <Route
            exact
            path="/bimboxes/:sceneId/issuesets"
            element={<PageIssuesets />}
          /> */}
          {/* <Route path="/bimboxes/:sceneId/viewerPdf">
            <Route exact index element={<SceneViewerPdf />} />
            <Route path=":modelId" element={<SceneViewerPdf />} />
          </Route>
          <Route
            exact
            path="/bimboxes/:sceneId/viewer3D"
            element={<SceneViewer3D />}
          /> */}
          {/* <Route exact path="/bimboxes/:sceneId/ifc" element={<SceneIfc />} /> */}
          {/* <Route
            exact
            path="/bimboxes/:sceneId/assistant"
            element={<PageAssistant />}
          /> */}
          {/* <Route
            path="/bimboxes/:sceneId/:scenarioId" // we need to shorten the path, otherwise, pb when loading the 2 libs ifc.js & pdftron due to relative path.
            element={<SceneBuildScenario />}
          >
            <Route index element={<StepLogin />} />
            <Route path="discoverUi" element={<StepDiscoverUi />} />
            <Route path="login" element={<StepLogin />} />
            <Route path="addFile" element={<StepAddFile />} />
            <Route path="renameModel" element={<StepRenameModel />} />
            <Route path="createListing" element={<StepCreateListing />} />
            <Route path="createIssue" element={<StepCreateIssue />} />
            <Route path="changeSceneImage" element={<StepChangeSceneImage />} />
            <Route path="changeSceneName" element={<StepChangeSceneName />} />
            <Route path="share" element={<StepShare />} />
            <Route path="connect" element={<StepConnect />} />
            <Route path="end" element={<StepEnd />} />
            <Route path="sync" element={<StepSync />} />
            <Route
              path="syncBoxForReview"
              element={<StepCreateSceneForReview />}
            />
          </Route> */}
          {/* <Route
            exact
            path="/bimbox/:sceneClientId/models/:modelId"
            element={<SceneModel />}
          />
          <Route
            exact
            path="/bimspaces/:sceneId/viewer"
            element={<SceneViewer />}
          />
          <Route
            exact
            path="/bimbox/:sceneId/stories/:storyId"
            element={<SceneStory />}
          /> */}
          <Route
            exact
            path="/shared/bimbox/:shareLinkScene"
            element={<SharedScene />}
          />

          {/* <Route exact path="/apps" element={<Apps />} /> */}
          <Route exact path="/auth" element={<Auth />} />
          <Route exact path="/logout" element={<Logout />} />
          {/* <Route exact path="/user" element={<User />} /> */}
          <Route exact path="/profile" element={<Profile />} />
          {/* <Route exact path="/landing" element={<Landing />} /> */}
          {/* <Route exact path="/services" element={<Services />} /> */}
          {/* <Route exact path="/listings" element={<Listings />} />
          <Route exact path="/listings/create" element={<CreateListing />} />
          <Route
            exact
            path="/listings/:listingId/images"
            element={<Images />}
          />
          <Route
            exact
            path="/listings/:listingId/settings"
            element={<Settings />}
          />
          <Route
            exact
            path="/listings/:listingId/report"
            element={<Report />}
          />
          <Route
            exact
            path="/listings/:listingId/notes/:noteId"
            element={<Note />}
          /> */}
          <Route exact path="/test" element={<Test />} />
          {/* <Route exact path="/draft" element={<Draft />} /> */}
          {/* <Route exact path="/operations" element={<Operations />} />
          <Route
            exact
            path="/operations/:operationId"
            element={<Operation />}
          /> */}
          {/* <Route exact path="/docsets/:docsetId" element={<Docset />} />
          <Route
            exact
            path="/docsets/:docsetId/admin"
            element={<DocsetAdmin />}
          /> */}
          {/* <Route exact path="/mapsets/:listingId" element={<Mapset />} />
          <Route
            exact
            path="/listings/:listingId/members"
            element={<Members />}
          />
          <Route exact path="/notesets/:listingId" element={<Noteset />} />
          <Route exact path="/objectsets/:listingId" element={<Objectset />} />
          <Route exact path="/psetsets/:listingId" element={<Psetset />} />
          <Route exact path="/rowsets/:listingId" element={<Rowset />} />
          <Route
            exact
            path="/notesets/:listingId/notes/:noteId"
            element={<Note />}
          /> */}
          {/* <Route
            exact
            path="/mapviewsets/:listingId"
            element={<Mapviewset />}
          />
          <Route
            exact
            path="/contributionsets/:listingId"
            element={<Contributionset />}
          />
          <Route
            exact
            path="/excelcontributionsets/:listingId/:targetLocalId"
            element={<ExcelContributions />}
          /> */}
          {/* <Route
            exact
            path="/listings/:listingId/debug"
            element={<ListingDebug />}
          />
          <Route
            exact
            path="/listings/:listingId/related-listings"
            element={<RelatedListings />}
          /> */}
          {/* <Route
            exact
            path="/shared/openingchange/"
            element={<SharedOpeningChange />}
          /> */}
          <Route
            exact
            path="/bimboxes/:sceneId/materials"
            element={<PageMaterials />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/pricings"
            element={<PagePricings />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

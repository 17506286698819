import {Box, Paper, Typography, Icon, Button, IconButton} from "@mui/material";
import {Add, ArrowBack as Back} from "@mui/icons-material";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";

export default function HeaderEntityPackages({}) {
  // strings

  const title = "Calques de repérage";

  // data

  const iconMap = useSceneModuleIconMap();

  // helper

  const icon = iconMap["PACKAGES"];

  return (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: 0,
        bgcolor: "unset",
      }}
    >
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Box
            sx={{
              bgcolor: "secondary.main",
              borderRadius: "4px",
              height: 32,
              width: 32,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "common.white",
              mr: 1,
            }}
          >
            {icon}
          </Box>

          <Typography>{title}</Typography>
        </Box>
      </Box>
    </Paper>
  );
}

import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";

import {
  Box,
  Paper,
  Divider,
  IconButton,
  ListItemButton,
  Tooltip,
  ListItemText,
  ListItemIcon,
  List,
  Icon,
} from "@mui/material";
import {lighten} from "@mui/material/styles";
import {
  ArrowBack as Back,
  ArrowBackIos as Close,
  ArrowForwardIos as Open,
} from "@mui/icons-material";

import usePageType from "../usePageType";
import useSceneModuleNameMap from "../useSceneModuleNameMap";
import useSceneModuleIconMap from "../useSceneModuleIconMap";
import useSceneModulePathMap from "../useSceneModulePathMap";
import useSceneModule from "../useSceneModule";

import {setOpenDialogCreatePackage} from "Features/elementPackages/packagesSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import {setNavPanelSceneModulesWidth} from "../navigationSlice";
import {setLeftPanelSection} from "Features/leftPanel/leftPanelSlice";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import useAccessToken from "Features/auth/useAccessToken";
import {fetchSharedboxes} from "Features/sharedboxes/sharedboxesSlice";
import useSharedboxesByScene from "Features/sharedboxes/hooks/useSharedboxesByScene";

import DialogCreatePackage from "Features/elementPackages/components/DialogCreatePackage";
// import ButtonFullScreenInNavPanel from "./ButtonFullScreenInNavPanel";

import CardSceneInMenu from "./CardSceneInMenu";

import ListItemButtonAuthInNavPanel from "Features/auth/components/ListItemButtonAuthInNavPanel";
import ListItemButtonSceneModuleConfig from "./ListItemButtonSceneModuleConfig";

import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import {Editor} from "slate";
import editorSlice from "Features/textEditor/editorSlice";

export default function NavPanelSceneModule({scene, viewer, caplaEditor}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const [searchParams] = useSearchParams();

  // helpers

  const suffixUrl = "";

  const sceneId = scene?.id ? scene.id : scene?.clientId;

  // strings

  const homeS = "Mes bimboxes";
  const reduceS = "Réduire";
  const expandS = "Agrandir";

  // data

  const widthSmall = useSelector((s) => s.navigation.widthSmall);
  const widthLarge = useSelector((s) => s.navigation.widthLarge);

  const pageType = usePageType();
  const openNavigationPanel = useSelector((s) => s.navigation.open);
  const navPanelSceneModuleWidth = useSelector(
    (s) => s.navigation.navPanelSceneModulesWidth
  );
  const nameMap = useSceneModuleNameMap();
  const iconMap = useSceneModuleIconMap();
  const pathMap = useSceneModulePathMap(scene?.id ? scene.id : scene?.clientId);

  const sceneModule = useSceneModule();
  let pdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  let lastPackageId = useSelector((s) => s.packages.lastPackageId);

  const packagesInScene = usePackagesByScene(scene);
  const sharedboxes = useSharedboxesByScene(scene);
  const inputsInScene = useInputsByScene(scene);

  const openDialogCreatePackage = useSelector(
    (s) => s.packages.openDialogCreatePackage
  );

  const showedModules = useSelector((s) => s.navigation.showedModules);

  // helper -package0

  const package0 = packagesInScene[0];

  const selectedPdfPackageIds = packagesInScene
    .filter((p) => p.fromModel.modelId === pdfModelId && p.enabled)
    .map((pack) => pack.id);

  const noPackageForSelectedPdf = selectedPdfPackageIds?.length === 0;

  // helper - openOnboarding

  const openOnboarding = !inputsInScene.length > 0 || !pdfModelId;
  //const openOnboarding = !inputsInScene.length > 0;

  // helpers

  let open = !openNavigationPanel && !viewer && pageType === "SCENE";
  //if (viewer) open = true;

  // helpers

  const isSmallSize = navPanelSceneModuleWidth < 80;

  // helpers - show pricings

  const showPricings = Boolean(sharedboxes.find((s) => s.usesPrices));

  // helpers - modules

  let modules = [
    "HOME",
    "SCOPES",

    "DIVIDER",

    "INPUTS",
    "SECTORS",
    "ZONES",
    "MARKERS",
    "VERSIONS",

    "DIVIDER",

    "ELEMENT_TYPES",
    "RESSOURCES",
    "ARTICLES",

    "DIVIDER",

    "PACKAGES",
    "EDIT_MEASUREMENTS",
    "MEASUREMENTS",

    "DIVIDER",

    "RELATIONS",
    "ROOMS",
    "PHASES",

    "DIVIDER",

    "SURVEYS",
    "PRICINGS",
    "ISSUES",
    "PROGRESS",
    "SAMPLES",

    "DIVIDER",

    "VIEWS",
    "REPORTS",
    "SHAREDBOXES",
    "BIMAPPS",

    //"ONBOARDING",

    // "ASSISTANT",
    //"DIVIDER",
    //"SECTORS",

    //"LIBRARY",
    //"MATERIALS",
  ];
  if (showPricings) modules = [...modules, "PRICINGS"];

  modules = modules
    .filter((m) => showedModules.includes(m) || m === "DIVIDER" || m === "HOME")
    .map((m) => ({
      code: m,
      label: nameMap[m],
      path: pathMap[m],
      icon: iconMap[m],
    }));

  // handlers

  function handleChange(m) {
    caplaEditor.timeStart = performance.now();
    if (m.code === "EDIT_MEASUREMENTS") {
      if (openOnboarding) {
        navigate(m.path);
      } else {
        let targetPackageId;
        if (lastPackageId) {
          targetPackageId = lastPackageId;
        } else if (pdfModelId) {
          targetPackageId = selectedPdfPackageIds[0];
        }
        navigate(`bimboxes/${sceneId}/${targetPackageId}/measurements`);
        dispatch(setSelectedMeasurementsModelId(targetPackageId));
        // caplaEditor?.measDataManager.setSelectedModelById(targetPackageId);
        //dispatch(setSelectedPdfModelId(pdfModelId));
      }
    } else {
      navigate(m.path + suffixUrl);
      if (m.code === "MEASUREMENTS") {
        dispatch(setSelectedMeasurementsModelId(null));
        // caplaEditor?.measDataManager.setSelectedModelById(null);
      }
    }
    dispatch(setLeftPanelSection(m.code));
  }

  function handleBackClick() {
    navigate("/" + suffixUrl);
  }

  // handlers - menuWidth

  function handleSetSize() {
    const newSizeKey = isSmallSize ? "LARGE" : "SMALL";
    dispatch(setNavPanelSceneModulesWidth(newSizeKey));
  }

  // init sharedboxes to see if we have a usesPrices

  useEffect(() => {
    if (scene?.id && accessToken) {
      dispatch(fetchSharedboxes({sceneId: scene.id, accessToken}));
    }
  }, [scene?.id, accessToken]);

  // render

  return (
    <>
      {/* {openDialogCreatePackage && (
        <DialogCreatePackage scene={scene} caplaEditor={caplaEditor} />
      )} */}
      <Paper
        elevation={6}
        square
        sx={{
          display: open ? "flex" : "none",
          flexDirection: "column",
          width: navPanelSceneModuleWidth,
          height: 1,
          minWidth: navPanelSceneModuleWidth,
          bgcolor: "common.caplaBlack",
          position: "relative",
          color: "common.white",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            py: 1,
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            bgcolor: (theme) =>
              !isSmallSize
                ? "warning.main"
                : lighten(theme.palette.common.caplaBlack, 0.2),
            position: isSmallSize ? "relative" : "absolute",
            zIndex: 10,
            right: 0,
            width: isSmallSize ? widthSmall : 32,
            height: isSmallSize ? 120 : 36, // 120 = image Height
          }}
          onClick={handleSetSize}
        >
          <Tooltip
            title={isSmallSize ? expandS : reduceS}
            placement="right"
            //disableFocusListener={!isSmallSize}
            //disableHoverListener={!isSmallSize}
            disableFocusListener
            disableHoverListener
          >
            <IconButton
              onClick={handleSetSize}
              color="inherit"
              sx={{borderRadius: 0, height: 1, width: 1}}
            >
              {isSmallSize ? (
                <Open fontSize="small" color="inherit" />
              ) : (
                <Close fontSize="small" color="inherit" />
              )}
            </IconButton>
          </Tooltip>
        </Box>

        <Box>
          <CardSceneInMenu
            scene={scene}
            showImage={!isSmallSize}
            hideTitle={isSmallSize}
          />
        </Box>
        <Box
          sx={{
            width: 1,
            mb: 1,
            borderTop: (theme) => `1px solid ${theme.palette.common.white}`,
          }}
        >
          <ListItemButton
            sx={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: isSmallSize ? "center" : "start",
            }}
            onClick={handleBackClick}
          >
            <Tooltip
              title={homeS}
              placement="right"
              disableFocusListener={!isSmallSize}
              disableHoverListener={!isSmallSize}
            >
              <Icon
                sx={{
                  color: "inherit",
                  fontSize: 20,
                  py: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: isSmallSize ? 0 : 3,
                }}
              >
                <Back fontSize="small" color="inherit" />
              </Icon>
            </Tooltip>

            {!isSmallSize && (
              <ListItemText
                primary={homeS}
                primaryTypographyProps={{
                  color: "common.white",
                  fontSize: "body2.fontSize",
                }}
              />
            )}
          </ListItemButton>
        </Box>

        <Box
          sx={{overflow: isSmallSize ? "hide" : "auto", flex: 1, minHeight: 0}}
        >
          <List dense sx={{}}>
            {modules
              .filter((module) => module.label || module.code === "DIVIDER")
              .map((module, index) => {
                const selected =
                  module.code === sceneModule ||
                  (module.code === "EDIT_MEASUREMENTS" &&
                    sceneModule === "ONBOARDING");
                let disabled = false;
                if (module.code === "EDIT_MEASUREMENTS" && !pdfModelId) {
                  //disabled = true;
                }

                if (
                  module.code === "DIVIDER" &&
                  index > 0 &&
                  modules[index - 1].code !== "DIVIDER"
                ) {
                  return (
                    <Divider
                      key={index}
                      sx={{borderColor: "lightGrey", m: 1}}
                    />
                  );
                } else if (module.code !== "DIVIDER") {
                  return (
                    <ListItemButton
                      disabled={disabled}
                      key={module.label}
                      onClick={() => handleChange(module)}
                      color="common.white"
                      sx={{
                        minHeight: 24,
                        color: "white",
                        py: 0.75,
                        //width: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: isSmallSize ? "center" : "start",
                        "&.Mui-selected": {bgcolor: "secondary.main"},
                      }}
                      selected={selected}
                    >
                      <Tooltip
                        title={module.label}
                        placement="right"
                        disableFocusListener={!isSmallSize}
                        disableHoverListener={!isSmallSize}
                      >
                        <Icon
                          sx={{
                            color: "inherit",
                            fontSize: 20,
                            py: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: isSmallSize ? 0 : 3,
                          }}
                        >
                          {module.icon}
                        </Icon>
                      </Tooltip>

                      {!isSmallSize && (
                        <ListItemText
                          primary={module.label}
                          primaryTypographyProps={{color: "common.white"}}
                        />
                      )}
                    </ListItemButton>
                  );
                }
              })}
          </List>
        </Box>
        <Box>
          {/* <ButtonFullScreenInNavPanel isSmallSize={isSmallSize} /> */}
          <Box sx={{mb: 2}}>
            <ListItemButtonSceneModuleConfig scene={scene} />
          </Box>
          <ListItemButtonAuthInNavPanel />
        </Box>
      </Paper>
    </>
  );
}

import {client} from "API/capla360";
import {deleteStoredModel} from "Database/services";

import {createStoredFile} from "Database/files/services";
import {createStoredRessource} from "Database/ressources/services";
import {
  createStoredModel,
  fetchStoredModels,
  fetchStoredModel,
  updateStoredModel,
  updateStoredModels,
} from "Database/models/services";

import {nanoid} from "@reduxjs/toolkit";

//import {getIfcSubsets} from "Features/viewer3D/utils/ifcjsUtils";
import {uploadFileService} from "Features/files/services";
import {fetchSceneListingsService} from "Features/listings/services";

import {storedModelToRemoteModel} from "./utils/miscUtils";
import {urlToFile} from "Features/files/utils";
import {remoteModeltoClientModel} from "Features/scenes/utils";

// ----------------
// ---* Models *---
// ----------------

/*
 *  model : the full model data (contains url, urlHightReso, urlLowReso)
 *  we create the ressource, link it to the model and store files, ressource and model in local DB.
 */

async function getNewModelAndStoredModelFromModelAndFiles({
  model,
  file,
  fileHR,
  fileLR,
  fileGltf,
  fileJson,
  fromRemote,
  autoloading,
  remoteDataOnly,
}) {
  try {
    const sceneClientId = model.sceneClientId;

    // store files

    let storedFile, storedFileLR, storedFileHR, storedFileGltf, storedFileJson;

    if (file)
      storedFile = await createStoredFile({
        file,
        resolution: "ORIGIN",
        sceneClientId,
      });
    if (fileLR)
      storedFileLR = await createStoredFile({
        file: fileLR,
        resolution: "LR",
        sceneClientId,
      });
    if (fileHR)
      storedFileHR = await createStoredFile({
        file: fileHR,
        resolution: "HR",
        sceneClientId,
      });
    if (fileGltf)
      storedFileGltf = await createStoredFile({
        file: fileGltf,
        resolution: "GLTF",
        sceneClientId,
      });
    if (fileJson)
      storedFileJson = await createStoredFile({
        file: fileJson,
        resolution: "JSON",
        sceneClientId,
      });

    // model

    const newModel = {
      ...model,
    };

    if (file) newModel.fileClientId = storedFile?.clientId;
    if (fileLR) newModel.fileHRClientId = storedFileLR?.clientId;
    if (fileHR) newModel.fileLRClientId = storedFileHR?.clientId;
    if (fileGltf) newModel.fileGltfClientId = storedFileGltf?.clientId;
    if (fileJson) newModel.fileJsonClientId = storedFileJson?.clientId;

    if (file) newModel.url = URL.createObjectURL(file);
    if (fileLR) newModel.urlLowReso = URL.createObjectURL(fileLR);
    if (fileHR) newModel.urlHightReso = URL.createObjectURL(fileHR);
    if (fileGltf) newModel.urlGltf = URL.createObjectGltf(fileGltf);
    if (fileJson) newModel.urlJson = URL.createObjectJson(fileJson);

    if (model.type !== "PDF") {
      if (remoteDataOnly) newModel.url = model.fileRemoteUrl;
      if (remoteDataOnly) newModel.urlLowReso = model.lowResoRemoteUrl;
      if (remoteDataOnly) newModel.urlHighReso = model.hightResoRemoteUrl;
      if (remoteDataOnly) newModel.urlGltf = model.fileGltfRemoteUrl;
      if (remoteDataOnly) newModel.urlJson = model.fileJsonRemoteUrl;
    } else if (remoteDataOnly) newModel.url = model.fileRemoteUrl;

    newModel.autoloading = autoloading;
    if (fromRemote) {
      newModel.nextSync = {action: false};
    } else {
      newModel.version = null;
      newModel.nextSync = {action: true, file: Boolean(file), data: true};
    }

    const storedModel = {...newModel};
    delete storedModel.justCreated; // jusCreated is kept in state, but not in the db=> false when new loading.

    return {newModel, storedModel};
  } catch (e) {
    console.log("error creating model", e);
  }
}

export async function createModelService({
  model,
  fromScopeId,
  file,
  fileHR,
  fileLR,
  fileGltf,
  fileJson,
  fromRemote = false,
  autoloading = true,
  remoteDataOnly,
}) {
  const {storedModel, newModel} =
    await getNewModelAndStoredModelFromModelAndFiles({
      model,
      file,
      fileHR,
      fileLR,
      fileGltf,
      fileJson,
      fromRemote,
      autoloading,
      remoteDataOnly,
    });
  createStoredModel({storedModel});
  return {model: newModel, fromScopeId};
}

export async function createModelsService({
  models,
  fromRemote,
  remoteDataOnly,
}) {
  const newModels = [];
  const storedModels = [];

  for (let model of models) {
    const {newModel, storedModel} =
      await getNewModelAndStoredModelFromModelAndFiles({
        model,
        fromRemote,
        remoteDataOnly,
      });
    if (!newModels.find((m) => m.id === newModel.id)) {
      // remote duplicates ...
      newModels.push(newModel);
      storedModels.push(storedModel);
    }
  }

  storedModels.forEach((storedModel) => createStoredModel({storedModel}));
  return newModels;
}

export async function fetchStoredModelService(modelId) {
  const result = await fetchStoredModel(modelId);
  if (result) {
    const {model} = result;
    delete model.isDetailed;
    delete model.justCreated;
    return {model};
  }
}

export async function fetchStoredModelsService(sceneClientId, withoutFiles) {
  const storedModels = await fetchStoredModels(sceneClientId, withoutFiles);
  const models = storedModels.map(({model}) => {
    delete model.isDetailed;
    delete model.justCreated;
    return model;
  });
  return {models, sceneClientId};
}

export async function fetchRemoteModelService({
  remoteId,
  accessToken,
  sceneClientId,
}) {
  const response = await client.get(`models/${remoteId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const rm = response.data; // remote model
  const model = remoteModeltoClientModel(rm, sceneClientId, true);
  return model;
}

export async function fetchRemoteModelsService({
  accessToken,
  modelIds,
  scopeId,
  sceneClientId,
  sceneId,
}) {
  const params = {};
  if (modelIds?.length > 0) {
    params.models = modelIds;
  }
  if (scopeId) {
    params.scope = scopeId;
  }
  const response = await client.get(`scenes/${sceneId}/models/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params,
  });
  const rms = response.data; // remote model
  const models = rms.map((rm) =>
    remoteModeltoClientModel(rm, sceneClientId, true)
  );
  return models;
}

export async function fetchRemoteModelOverviewService({
  remoteId,
  accessToken,
  sceneClientId,
}) {
  const response = await client.get(`models/${remoteId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      variant: "overview",
    },
  });
  const rm = response.data; // remote model
  const model = remoteModeltoClientModel(rm, sceneClientId);
  return model;
}

/*
 * Load 3d model into the scene.
 * Used once the stored modes have been loaded from the db.
 */
export async function loadModelService({
  model,
  ifcStoreys,
  ifcTypes,
  ifcSubsets,
}) {
  return {
    model,
    ifcStoreys,
    ifcTypes,
    ifcSubsets,
  };
}

export async function loadModelsService({editor, models, sceneClientId}) {
  const results = [];
  for (const model of models) {
    const result = await loadModelService({editor, model});
    results.push(result);
  }
  return {results, sceneClientId};
}
export async function deleteModelService({model}) {
  if (model.type !== "DXF") await deleteStoredModel(model.id);
  return model;
}

export async function updateModelService({
  updatedModel,
  file,
  fileGltf,
  fileJson,
  fromRemote = false,
  sync = true,
  storeFiles = false, // use to prevent local storage on mobile
  updateModelInStore = false, // use false if no need to update the stored model (when toggle model visibility for instance)
}) {
  // we use diff update because we do not know what's coming from editor model...

  console.log("debug updatemodel", storeFiles, updateModelInStore);

  // const result =
  //   updateModelInStore && (await fetchStoredModel(updatedModel.id));
  // console.log("result12", result);

  let model = {...updatedModel};

  // if (result) model = {...result.model, ...updatedModel}; // updatedModel is the one to take into account.

  const sceneClientId = model.sceneClientId;

  if (file) {
    if (storeFiles) {
      const storedFile = await createStoredFile({
        file,
        resolution: "ORIGIN",
        sceneClientId,
      });
      model.fileClientId = storedFile.clientId;
    }
    model.url = URL.createObjectURL(file);
  }

  if (fileGltf) {
    if (storeFiles) {
      const storedFileGltf = await createStoredFile({
        file,
        resolution: "GLTF",
        sceneClientId,
      });
      model.fileGltfClientId = storedFileGltf.clientId;
    }

    model.urlGltf = URL.createObjectURL(fileGltf);
  }

  if (fileJson) {
    if (storeFiles) {
      const storedFileJson = await createStoredFile({
        file,
        resolution: "JSON",
        sceneClientId,
      });
      model.fileJsonClientId = storedFileJson.clientId;
    }
    model.urlJson = URL.createObjectURL(fileJson);
  }

  let nextSync = {...updatedModel.nextSync};
  if (fromRemote) {
    nextSync = {action: false};
  } else {
    if (sync) {
      nextSync.action = true;
      nextSync.data = true;
      if (file) nextSync.file = true;
      if (fileJson) nextSync.fileJson = true;
      if (fileGltf) nextSync.fileGltf = true;
    }
  }

  model.nextSync = nextSync;

  //if (result && updateModelInStore) await updateStoredModel(model);
  if (updateModelInStore) await updateStoredModel(model);

  return model;
}

export async function updateModelsService({models, options}) {
  updateStoredModels(models);
  return {models, options};
}

export async function addGltfUrlService({model, fileGltf}) {
  const sceneClientId = model.sceneClientId;
  const f = await createStoredFile({
    file: fileGltf,
    resolution: "GLTF",
    sceneClientId,
  });
  const fileGltfClientId = f.clientId;
  const newModel = {...model, fileGltfClientId, fileGltfSize: fileGltf.size};

  await updateStoredModel(newModel);
  return newModel;
}

export async function addJsonUrlService({model, fileJson}) {
  const sceneClientId = model.sceneClientId;
  const f = await createStoredFile({
    file: fileJson,
    resolution: "JSON",
    sceneClientId,
  });
  const fileJsonClientId = f.clientId;
  const newModel = {...model, fileJsonClientId, fileJsonSize: fileJson.size};

  await updateStoredModel(newModel);
  return newModel;
}

export async function addResizedUrlsService({model, fileHR, fileLR}) {
  const sceneClientId = model.sceneClientId;
  const f1 = await createStoredFile({
    file: fileLR,
    resolution: "LR",
    sceneClientId,
  });
  const fileLRClientId = f1.clientId;

  const f2 = await createStoredFile({
    file: fileHR,
    resolution: "HR",
    sceneClientId,
  });
  const fileHRClientId = f2.clientId;

  const newModel = {...model, fileHRClientId, fileLRClientId};

  await updateStoredModel(newModel);

  return newModel;
}

export async function createRemoteModelService(
  {accessToken, modelId, sceneId, fromScopeId},
  {getState}
) {
  const state = getState();

  // data

  const models = state.viewer3D.models;
  const model = models.find((m) => m.id === modelId);

  const file = model.url && (await urlToFile(model.url, model.id));
  const fileLR =
    model.urlLowReso && (await urlToFile(model.urlLowReso, model.id));
  const fileHR =
    model.urlHighReso && (await urlToFile(model.urlHightReso, model.id));
  const fileGltf = model.urlGltf && (await urlToFile(model.urlGltf, model.id));
  const fileJson = model.urlJson && (await urlToFile(model.urlJson, model.id));

  // const {file, fileLR, fileHR, fileGltf, fileJson} =
  //   await fetchStoredModel(modelId);

  // sync files
  // RR
  let fileRemoteUrl;
  if (file) {
    fileRemoteUrl = await uploadFileService({
      accessToken,
      file,
      sceneId,
      container: "scene-files",
      blobName: `${model.id}-${file.size}`,
    });
  }

  // HR
  let fileHightResoRemoteUrl;
  if (fileHR) {
    fileHightResoRemoteUrl = await uploadFileService({
      accessToken,
      file: fileHR,
      sceneId,
      container: "scene-files",
    });
  }
  // LR
  let fileLowResoRemoteUrl;
  if (fileLR) {
    fileLowResoRemoteUrl = await uploadFileService({
      accessToken,
      file: fileLR,
      sceneId,
      container: "scene-files",
    });
  }

  // Gltf
  let fileGltfRemoteUrl;
  if (fileGltf) {
    fileGltfRemoteUrl = await uploadFileService({
      accessToken,
      file: fileGltf,
      sceneId,
      container: "scene-files",
      blobName: `${model.id}-gltf`,
    });
  }

  // Json
  let fileJsonRemoteUrl;
  if (fileJson) {
    fileJsonRemoteUrl = await uploadFileService({
      accessToken,
      file: fileJson,
      sceneId,
      container: "scene-files",
      blobName: `${model.id}-json`,
    });
  }

  const newModel = {...model};
  if (fileRemoteUrl) newModel.fileRemoteUrl = fileRemoteUrl;
  if (fileLowResoRemoteUrl)
    newModel.fileLowResoRemoteUrl = fileLowResoRemoteUrl;
  if (fileHightResoRemoteUrl)
    newModel.fileHightResoRemoteUrl = fileHightResoRemoteUrl;
  if (fileGltfRemoteUrl) newModel.fileGltfRemoteUrl = fileGltfRemoteUrl;
  if (fileJsonRemoteUrl) newModel.fileJsonRemoteUrl = fileJsonRemoteUrl;

  const remoteModel = storedModelToRemoteModel(newModel, sceneId);

  // sync remote model
  const params = {};
  if (fromScopeId) params.fromScope = fromScopeId;
  const response = await client.post(`scenes/${sceneId}/models/`, remoteModel, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params,
  });
  const rm = response.data; // remote model

  console.log("NEW REMOTE MODEL CREATED", rm);

  // updates
  newModel.version = rm.version;
  newModel.nextSync = {action: false};
  newModel.remoteId = rm.remoteId;

  updateStoredModel(newModel);
  return {model: newModel, remoteModel: rm};
}

export async function updateRemoteModelMetadataService({
  accessToken,
  model,
  sceneId,
}) {
  const remoteModel = storedModelToRemoteModel(model, sceneId);
  const response = await client.put(`models/${model.remoteId}`, remoteModel, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function updateRemoteModelService(
  {
    accessToken,
    modelId,
    sceneId,
    forceFile = false,
    forceFileGltf = false,
    forceFileJson = false,
    dataOnly = false,
  },
  {getState}
) {
  const state = getState();

  // data

  const models = state.viewer3D.models;
  const model = models.find((m) => m.id === modelId);

  let options;
  if (model.type === "IMAGE") options = {type: "image/png"};
  if (model.type === "PDF") options = {type: "application/pdf"};

  const blobName = nanoid() + "-" + model.name;

  const file = model.url && (await urlToFile(model.url, blobName, options));
  const fileLR =
    model.urlLowReso &&
    (await urlToFile(model.urlLowReso, blobName + "_LR", options));
  const fileHR =
    model.urlHighReso &&
    (await urlToFile(model.urlHightReso, blobName + "_HR", options));
  const fileGltf =
    model.urlGltf &&
    (await urlToFile(model.urlGltf, blobName + "_gltf", options));
  const fileJson =
    model.urlJson &&
    (await urlToFile(model.urlJson, blobName + "_json", options));

  console.log("UPDATING REMOTE MODEL (START)", model, "fileSize", file?.size);

  // const {model, file, fileLR, fileHR, fileGltf, fileJson} =
  //   await fetchStoredModel(modelId);

  const newModel = {...model};

  // sync files
  // RR
  let fileRemoteUrl;
  if (
    !dataOnly &&
    file &&
    (!model.fileRemoteUrl || forceFile || model.nextSync.file)
  ) {
    fileRemoteUrl = await uploadFileService({
      accessToken,
      file,
      sceneId,
      container: "scene-files",
      blobContentType: options?.type,
      //blobName: `${model.id}-${file.size}`,
    });
  }

  // HR
  let fileHightResoRemoteUrl;
  if (!dataOnly && fileHR) {
    fileHightResoRemoteUrl = await uploadFileService({
      accessToken,
      file: fileHR,
      sceneId,
      container: "scene-files",
    });
  }
  // LR
  let fileLowResoRemoteUrl;
  if (!dataOnly && fileLR) {
    fileLowResoRemoteUrl = await uploadFileService({
      accessToken,
      file: fileLR,
      sceneId,
      container: "scene-files",
    });
  }
  // Gltf
  let fileGltfRemoteUrl;
  if (!dataOnly && fileGltf && (forceFileGltf || !model.fileGltfRemoteUrl)) {
    fileGltfRemoteUrl = await uploadFileService({
      accessToken,
      file: fileGltf,
      sceneId,
      container: "scene-files",
    });
  }
  // Json
  let fileJsonRemoteUrl;
  if (!dataOnly && fileJson && (forceFileJson || !model.fileJsonRemoteUrl)) {
    fileJsonRemoteUrl = await uploadFileService({
      accessToken,
      file: fileJson,
      sceneId,
      container: "scene-files",
    });
  }

  if (fileRemoteUrl) newModel.fileRemoteUrl = fileRemoteUrl;
  if (fileLowResoRemoteUrl)
    newModel.fileLowResoRemoteUrl = fileLowResoRemoteUrl;
  if (fileHightResoRemoteUrl)
    newModel.fileHightResoRemoteUrl = fileHightResoRemoteUrl;
  if (fileGltfRemoteUrl) newModel.fileGltfRemoteUrl = fileGltfRemoteUrl;
  if (fileJsonRemoteUrl) newModel.fileJsonRemoteUrl = fileJsonRemoteUrl;

  const remoteModel = storedModelToRemoteModel(newModel, sceneId);

  const response = await client.put(`models/${model.remoteId}`, remoteModel, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const rm = response.data; // updated remote model

  console.log("MODEL UPDATED 12", "input", remoteModel, "response", rm);

  // updates
  newModel.version = rm.version;
  newModel.nextSync = {action: false};

  updateStoredModel(newModel);
  return {model: newModel, remoteModel: rm};
}

export async function updateFullRemoteModelService({
  accessToken,
  remoteModel, // computed using the miscUtils storedModelToRemoteModel
  file,
  fileLR,
  fileHR,
  fileGltf, // TO BE DONE
  fileJson, // TO BE DONE
}) {
  console.log("updateFullRemoteModelService");
  // data
  const sceneId = remoteModel.sceneId;

  // sync files
  // RR
  const fileRemoteUrl = await uploadFileService({
    accessToken,
    file,
    sceneId,
    container: "scene-files",
  });

  // HR
  let fileHightResoRemoteUrl;
  if (fileHR) {
    fileHightResoRemoteUrl = await uploadFileService({
      accessToken,
      file: fileHR,
      sceneId,
      container: "scene-files",
    });
  }
  // LR
  let fileLowResoRemoteUrl;
  if (fileLR) {
    fileLowResoRemoteUrl = await uploadFileService({
      accessToken,
      file: fileLR,
      sceneId,
      container: "scene-files",
    });
  }
  // sync remote model
  const model = {...remoteModel, scene: sceneId, fileRemoteUrl};
  if (fileLowResoRemoteUrl) model.fileLowResoRemoteUrl = fileLowResoRemoteUrl;
  if (fileHightResoRemoteUrl)
    model.fileHightResoRemoteUrl = fileHightResoRemoteUrl;
  const response = await client.put(`models/${model.remoteId}`, model, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const rm = response.data; // updated remote model
  const updates = {
    id: rm.clientId,
    fileRemoteUrl: rm.fileRemoteUrl,
    fileLowResoRemoteUrl: rm.fileLowResoRemoteUrl,
    fileHightResoRemoteUrl: rm.fileHightResoRemoteUrl,
  };
  //await updateStoredModel(updates);
  return rm;
}

export async function deleteRemoteModelService({accessToken, model}) {
  const response = await client.delete(`models/${model.remoteId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  console.log("delete remote model", response.data);
}

// Worksites

export async function fetchRemoteWorksiteModelsService({
  accessToken,
  worksiteId,
}) {
  try {
    const response = await client.get(`worksites/${worksiteId}/models/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const models = response.data;
    //const remoteScene = response.data;
    //const stateRemoteScene = remoteSceneToState(remoteScene);
    return models;
  } catch (e) {
    console.log(e);
  }
}

// related listings

export async function fetchSceneListingsServiceProxy({accessToken, sceneId}) {
  const {listings} = await fetchSceneListingsService({
    accessToken,
    sceneId,
  });
  return {listings, sceneId};
}

import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import SectionArticlesInLeftPanel from "Features/articles/components/SectionArticlesInLeftPanel";
import SectionElementTypesInLeftPanel from "Features/elementTypes/components/SectionElementTypesInLeftPanel";

export default function SectionMeasurementsInLeftPanelV2({scene, caplaEditor}) {
  const tab = useSelector((s) => s.leftPanel.tabInEditMeasurements);

  return (
    <Box
      sx={{
        flexGrow: 1,
        //border: "2px solid red",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        //justifyItems: "stretch",
        minHeight: 0, // make it works !!!
      }}
    >
      {tab === "ELEMENT_TYPES" && (
        <SectionElementTypesInLeftPanel
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {tab === "ARTICLES" && (
        <SectionArticlesInLeftPanel scene={scene} caplaEditor={caplaEditor} />
      )}
      {/* <Box sx={{display: "flex"}}>
        <ButtonDialogDownloadExcel scene={scene} />
      </Box> */}
    </Box>
  );
}

import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

// import modelsSample from "./data/modelsSample";
import markersSample from "./data/markersSample";
import {getAction} from "./utils/modeUtils";
import {
  fetchStoredModelsService,
  fetchStoredModelService,
  fetchRemoteWorksiteModelsService,
  deleteModelService,
  updateModelService,
  updateModelsService,
  addResizedUrlsService,
  addGltfUrlService,
  addJsonUrlService,
  createModelService,
  createModelsService,
  createRemoteModelService,
  updateRemoteModelService,
  loadModelService,
  fetchSceneListingsServiceProxy,
  updateFullRemoteModelService,
  fetchRemoteModelService,
  fetchRemoteModelsService,
  fetchRemoteModelOverviewService,
} from "./services";
import getPackageMetadata from "Features/elementPackages/utils/getPackageMetadata";

// import ListingName from "Features/listing/components/ListingName";

// model

export const fetchStoredModels = createAsyncThunk(
  "viewer3D/fetchStoredModels",
  fetchStoredModelsService
);
export const fetchStoredModel = createAsyncThunk(
  "viewer3D/fetchStoredModel",
  fetchStoredModelService
);

export const fetchRemoteWorksiteModels = createAsyncThunk(
  "viewer3D/fetchRemoteWorksiteModels",
  fetchRemoteWorksiteModelsService
);

export const createModel = createAsyncThunk(
  "viewer3D/createModel",
  createModelService
);

export const createModels = createAsyncThunk(
  "viewer3D/createModels",
  createModelsService
);

export const loadModel = createAsyncThunk(
  "viewer3D/loadModel",
  loadModelService
);

export const deleteModel = createAsyncThunk(
  "viewer3D/deleteModel",
  deleteModelService
);

export const updateModel = createAsyncThunk(
  "viewer3D/updateModel",
  updateModelService
);

export const updateModels = createAsyncThunk(
  "viewer3D/updateModels",
  updateModelsService
);

export const addResizedUrls = createAsyncThunk(
  "viewer3D/addResizedUrls",
  addResizedUrlsService
);

export const addGltfUrl = createAsyncThunk(
  "viewer3D/addGltfUrl",
  addGltfUrlService
);

export const addJsonUrl = createAsyncThunk(
  "viewer3D/addJsonUrl",
  addJsonUrlService
);

export const createRemoteModel = createAsyncThunk(
  "viewer3D/createRemoteModel",
  createRemoteModelService
);

export const updateRemoteModel = createAsyncThunk(
  "viewer3D/updateRemoteModel",
  updateRemoteModelService
);

export const updateFullRemoteModel = createAsyncThunk(
  "viewer3D/updateFullRemoteModel",
  updateFullRemoteModelService
);
export const fetchRemoteModel = createAsyncThunk(
  "viewer3D/fetchRemoteModel",
  fetchRemoteModelService
);
export const fetchRemoteModels = createAsyncThunk(
  "viewer3D/fetchRemoteModels",
  fetchRemoteModelsService
);
export const fetchRemoteModelOverview = createAsyncThunk(
  "viewer3D/fetchRemoteModelOverview",
  fetchRemoteModelOverviewService
);

// notes and other listings

export const fetchSceneListings = createAsyncThunk(
  "viewer3D/fetchSceneListings",
  fetchSceneListingsServiceProxy
);

export const viewer3DSlice = createSlice({
  name: "viewer3D",
  initialState: {
    beta: false, // if true, beta features will be displayed.
    isLoaded: false, // when loaded, editor object exists.
    canUpdateModels: true, // when, false, can't update stored model. Used to prevent updates with shared remote models.
    is3dEnabled: false, // if true, the models are loaded in the scene.
    modelsLoaderOptions: {}, // used by loadModels Async2. version used to trigger state update in viewer.
    showScreenModelsLoaderHelper: false,

    isFirstLoading: true, // used to redirect to "/" if request parameters in the initial url.
    landingPage: true, // if true, display the home page.
    worksitesPage: false, // if true, display worksites page (map)
    bimboxPage: true, // if true, display the bimbox page (and not the space...)
    spacePage: false,
    scenesDialog: false, // if true, display the scenes dialog.
    firstRendered: false, // true when scene first rendered (for filters, initcamera,....)
    disableRender: false, // used to disable rendering for perf.

    grid: false, // editor : editor.grid
    showEdge: false,
    showVoid: false, // true, <- error with drawVoidObjects lateral
    picking: false, // picking mode is active.
    boxSelection: false, // boxSelection tool

    viewerWidth: 0.35, // viewer width (viewer box defined in the App component.) //0.45
    fixedViewersBoxWidth: 0.65, // pdf, map,.. width. Defined in the App component. //0.45
    isViewerVisible: false, // used to get width ( viewerWidth x 0/1)
    isViewerFullScreen: false,
    rendererSize: {height: 0, width: 0}, // size of the canvas
    rendererOffset: {top: 0, left: 0}, // use to compute popover position from e.clientX. Similat to pdfOFfset.
    explorerWidth: 300,
    dataSectionWidth: 300,
    mainTool: "", // "MODELS","SHARE",...
    mainSection: null, // Screen in front of the 3D view. "OBJECTSET", "SCREENSHOTVIEWER","PDFVIEWER", "MAP"
    loaderScreen: false, // Screen used to display the models to load.
    screenshot: {url: null, storyId: null}, // Url from the screenshot
    modelsColor: "CAPLA", // "INITIAL","TRANSPARENT"
    positioningObject: {editor: false, editor2: false},
    orbiting: false, // when user is orbiting the camera.
    panning: false,

    loadLoadingBoxOnly: false, // if true, stop loading after the box.
    webWorker: true, // use webworker to load IFC files.
    rawIfcModel: false, // load raw ifc model without any computation (no creation subset)
    snappingMode: false,
    storeFiles: true, // when loading remote model, store files in the browser. cf editor.loader
    gltfOnly: false, // if true, we only load the gltf file, not the .json file. Useful for mobile.
    loadEdges: false, // compute edges at load time.
    createJson: true, // compute json with gltf
    autoplacement: false, // compute initial autoplacement
    loadIfcStructure: true, // compute initial ifc structure
    pointerMode: false, // plot a dot on the intersection.
    pointerPosition: {}, // x,y,z of the pointer in 3D space.
    substractVoids: false, // substract voids from 3D geom.
    globalTransparency: true, // apply transparency globaly

    configuring: false, // main editor in configuration mode. if true => config center.
    configuringEntityID: undefined, // entity in the configuration center,
    configCenter: false, // the camera is focus on the config center,

    multiviews: false, // display 2 renderings elements, one for 2D, one for 3D. Useful for PDF !
    multiviewsSliderPositionX: 0, // position in px of the slider position. set in the view3D component.
    cam2Height: 0, // altimetry of the cam2 (orbit, right camera)
    cam2Polar: 0, // vertical orientation of cam2 (orbit camera in multiviews)
    eyesHeight: 1.6, // eyes height in meters, from the floor. By default, cam1 height is set +20cm above eyes.
    cutCam1: false, // cut above and below cam1. specified in editor.multiviews.cutCam1 as well
    cutAtHeight: false, // cut 1m above height (cam2Height - eyesHeight)
    optimIsEnabled: false, // used to remove edge when navigating. See editorScene.
    alignedToNorth: false, // used to rotate cam1 with cam.
    keepMainPart: false, // when unselected, the image main part is stayed as the visible object.
    imageTranslationRangeMax: 10, // image translate over -max , +max.

    // camera
    initZoomOutWithEntities: false, // true when zoomOut with entities. (cf sceneEditor.zoomOut)

    walkMode: false, // walkMode = editor.controls.lockControls
    imageOverlayMode: false,
    imageOverlayProps: {url: null, x: 20, y: 20, with: 200, height: 100}, // set to visualize issue in the pdf editor
    storiesReaderMode: false, // display stories reader
    showMeasurementsInViewer: false, // displayed when taking measurements.

    isOrthoObject: {
      editor: false,
      editor2: true,
      mainScene: false,
      configCenter: true,
    },

    clickContext: "DEFAULT", // used to trigger actions based on the click context
    mode: "DEFAULT", //process mode
    step: "DEFAULT",
    subStep: "DEFAULT",
    action: "DEFAULT",
    processMoveOnePoint: {}, // {origin:{x,y,z},entityID,entityType} of the origin point used in the MoveOnePoint process
    processMeasure: {}, // {point1,point2,distance,angle}

    showDataPreview: true, // in files & data mode (mode="LIST"), open preview.
    viewMode: "3D", // "MAP", "MULTIVIEWS". selected mode to visualize models.
    show3D: false, // in multiview mode, display or not the 3D immersive view.
    showToolAddElement: true, // to manage interference with Google Street View.
    tempObjects: [], // temporary objects added to the scene / map.
    tempAnnotations: [], // {id,type,path,position,quantities,geometry}

    //models: modelsSample, // models to import in the viewer.
    models: [], // models loaded in the 3D scenes. model :{type, url, urlHightReso, urlLowReso,...}
    modelsFilters: {types: [], keywords: []},
    datasetTypesInScope: {models: ["PDF"], listings: [], zoneModels: false}, // used in Files & data to filter datasets.
    filesDownloadLimit: {size: 10000000, enabled: true}, // used to limit batch download from files & data page.

    storedModelsLoaded: {}, // {sceneClientId : true/false}. The models are loaded from local db. If true, then they are added to the scene.
    sharedRemoteSceneModelsLoaded: false,

    mobileTool: "MODELS", // tool used in mobile views.

    modelsStatus: {}, // used to track models loading in the 3D scene. {sceneClientId:{modelId:"idle"}} - idle,loading,loaded
    //scenesPreloaded: {}, // {sceneClientId:true/false}. Preload = models of the scene in state. Loaded = scene in 3D view.
    scenesStatus: {}, // {sceneClientId:"idle"/"preloaded"/"loaded"}. Used to trigger models loading or not. Preloaded : models in state.
    modelsProgress: {}, // as modelStatus with progress value. Set by ifc onload.

    scenesSyncStatus: [], // [{sceneClientId,isUploading}], used in autosync feature.
    modelsSyncStatus: [], // [{modelId,status,progress,isUploading}], status = "idle", "loading", "downloading"
    optimStatus: [], // [{modelId,gltfStatus,jsonStatus,jsonProgress}]
    fetchedFromStoreModels: [],
    detailedModels: [], //[modelId], list of the models that were fully loaded (ifcModelEntity for ifc type)
    modelsWithInitialTexture: [],
    initialLoadedModels: [], // [modelId], updated by the loader.
    newModels: [], // [modelId] models created in the session. used to trigger onboarding screen (eg: zone creation for pdf)

    // v state and not in scenes because fetched when all the models added in state.
    fetchedRemoteScenes: [], //[scene.clientId] list of remote scenes fetched. (opening a remote scene). Once the models are loaded, we need to asyncLoadV2 the models=> we use this list as a dependency.

    // pdf

    pdfViewerStatus: "idle", // "loading","loaded" => pdf document loading state. trigger by documentLoaded event (pdfEditor) or load fucntion (pdfEditor)
    pdfCurrentPage: 1, // get pdf editor current page
    pdftronOffset: {}, // {x,y} = offset of the iframe window to compute mouse position.
    pdfMarkers: [], // [{id,x,y,pageNumber,modelId}] markers located in the doc.
    pdfSnapToLine: false, // use to setup snapping mode to "POINT_ON_LINE"
    pdfDrawDirection: "DEFAULT", // "HORIZONTAL","VERTICAL", used to compute direction/ draw lines.

    // capla

    hiddenCaplaElements: [], // [{modelId,codeName}] of elements.
    selectedCaplaElementType: null, // {modelId,code}

    // map

    azmEditorIsLoaded: false, // true when azmEditor is created and the map is ready.
    gmapEditorIsLoaded: false,
    mapClickedProps: {}, // props sent when the user click on the map.
    mapProvider: "AZURE", // GOOGLE

    // demos
    demos: {}, // {demo1:{status:"loaded"}}

    // processAnimate
    processAnimate: {
      step: undefined,
      entity: undefined,
      transforms: [], // [{p:[x,y,z], r:[x,y,z],delay:2}]
    },

    // notes

    sceneListings: {status: "idle", data: [], sceneId: null}, // array of listings related to this box.
    sceneListingId: undefined, // id of the selected listings.
    selectedNoteId: undefined, // id of the selected note.

    // markers

    markers: markersSample, // markers used to localise models.

    // ifc

    ifcSubsets: [], // one ifc subset = {storeyExpressID, type, ids}

    ifcStoreys: [], // used to set storey visibility
    ifcTypes: [], // used to store types visibility
    ifcTrees: [], // used to display models tree

    sites: [], // ifc sites to center models

    selection: undefined, // selected entity. one entity for each object added to the scene. {type,objectUUID,data}

    selectedIfcElement: null, // used from clicked object.
    showSelectionCard: false, // used to hide / show selection card. Camera change => false. New selection => true
    configuredModel: {model: undefined, showMasks: false}, // the model being configured.: {model,showMasks}
    selectedModelId: undefined, // used to get filters from selection
    selectedPdfModelId: undefined, // used to select the pdf to be rendered,
    clickedObject: {model: {}, element: {}}, // used to identify the clicked object. In parallel of selection/picking.
    editedObject: {model: {}, element: {}},

    multipleSelectionMode: false, // use to activate / inactivate multiple selection.
    multipleSelection: [], // [{type,modelId,ifcModelID,expressIDs:[]}]
    hiddenElements: [], // [{expressID, modelId,ifcModelID}]
    objectsSelection: [], // [{objectId,objectsetId}]

    markerPosition: [0, 0, 0], // position of the new marker being created
    rightSection: false,
    detailSection: false,
    toolData: null, //
    toolViewer: null, // tool from the viewer groupTool.
    toolGroup: "VIEWER", // group of tools for different usage scenarios : "VIEWER/REVIEW"
    explorerSection: false, // section with the list of the models.

    editTool: "", // "POINT", "LINE", ...tool used to edit a new model element.

    hiddenStoreys: [],
    hiddenTypes: [], // [{ifcModelID, ifcTypeKey}]
  },
  extraReducers: {
    "overviewer.clearSceneData": (state) => {
      state.models = [];
    },
    // fetch stored models for one scene =>
    // - all the models are in the store.
    // - it is a preload process => update scene status.
    [fetchStoredModel.fulfilled]: (state, action) => {
      const {model} = action.payload;
      const isFetched = state.fetchedFromStoreModels.includes(model.id);
      if (!isFetched) {
        const models = state.models.filter((m) => m.id !== model.id);
        models.push(model);
        state.models = models;
        state.fetchedFromStoreModels.push(model.id);
      }
    },
    [fetchStoredModels.fulfilled]: (state, action) => {
      const {models: addModels, sceneClientId} = action.payload;
      const newModels = [];

      addModels.forEach((model) => {
        const existingModel = state.models.find(
          (m) => m.id === model.id && m.type === model.type
        );
        const duplicatedModel = newModels.find((m) => m.id === model.id);
        if (!existingModel && !duplicatedModel) newModels.push(model);
      });
      state.models.push(...newModels);
    },
    [loadModel.fulfilled]: (state, action) => {
      const model = action.payload.model;
      const newStoreys = action.payload.ifcStoreys;
      const newTypes = action.payload.ifcTypes;
      const newSubsets = action.payload.ifcSubsets;

      const modelsIds = state.models.map((model) => model.id);
      const modelIsNew = !modelsIds.includes(model.id);
      if (modelIsNew) state.models.push(model);
      if (model.type === "IFC") {
        state.ifcStoreys = [...state.ifcStoreys, ...newStoreys];
        state.ifcSubsets = [...state.ifcSubsets, ...newSubsets];
        state.ifcTypes = [...state.ifcTypes, ...newTypes];
      }
    },
    // CREATE MODEL : add to the local DB + add to the store.
    [createModel.fulfilled]: (state, action) => {
      const {model} = action.payload;
      const newModels = state.models.filter((m) => m.id !== model.id);
      newModels.push(model);
      state.models = newModels;

      // the state will be changed by the loader once the model is loaded in the 3D scene
    },
    [createModels.fulfilled]: (state, action) => {
      const models = action.payload;
      const newIds = models.map((m) => m.id);
      let newModels = state.models.filter((m) => !newIds.includes(m.id));
      newModels.push(...models);
      state.models = newModels;
    },
    [createRemoteModel.fulfilled]: (state, action) => {
      const {model, remoteModel} = action.payload;
      state.models = state.models.map((m) => {
        if (m.id === model.id) {
          return model;
        } else {
          return m;
        }
      });
    },
    [updateFullRemoteModel.fulfilled]: (state, action) => {
      const remoteModel = action.payload;
      state.models = state.models.map((model) => {
        if (model.id === remoteModel.clientId) {
          return {
            ...model,
            fileRemoteUrl: remoteModel.fileRemoteUrl,
            remoteUpdatedAt: remoteModel.updatedAt,
          };
        } else {
          return model;
        }
      });
    },
    [fetchRemoteModel.fulfilled]: (state, action) => {
      const remoteModel = action.payload;

      console.log("[ADD FETCHED REMOTE MODEL TO STATE]", remoteModel);

      let newModel = {...remoteModel};
      const oldModel = state.models.find(
        (m) => m.remoteId === remoteModel.remoteId
      );
      const newModels = state.models.filter((m) => m.id !== remoteModel.id);

      if (oldModel) newModel = {...oldModel, ...newModel};

      state.models = [...newModels, newModel];
    },
    [fetchRemoteModels.fulfilled]: (state, action) => {
      const remoteModels = action.payload;

      console.log("[ADD FETCHED REMOTE MODELS TO STATE]", remoteModels);

      const modelsMap = state.models.reduce((acc, model) => {
        acc[model.id] = model;
        return acc;
      }, {});

      remoteModels.forEach((remoteModel) => {
        const oldModel = modelsMap[remoteModel.id];
        const newModel = oldModel ? {...oldModel, ...remoteModel} : remoteModel;
        modelsMap[remoteModel.id] = newModel;
      });

      state.models = Object.values(modelsMap);
    },
    [fetchRemoteModelOverview.fulfilled]: (state, action) => {
      const remoteModel = action.payload;
      console.log("remoteModel", remoteModel);
    },
    [updateRemoteModel.fulfilled]: (state, action) => {
      const {model} = action.payload;
      state.models = state.models.map((m) => {
        if (m.id === model.id) {
          return model;
        } else {
          return m;
        }
      });
    },
    [updateModel.fulfilled]: (state, action) => {
      //if (state.canUpdateModels) {
      const updatedModel = action.payload;
      console.log("DEBUG UPDATE MODEL", updatedModel);
      const newModels = state.models.map((model) => {
        if (model.id === updatedModel.id) {
          return {...model, ...updatedModel};
        } else {
          return model;
        }
      });
      state.models = newModels;
      //}
    },
    [updateModels.fulfilled]: (state, action) => {
      const {models: newModels, options} = action.payload;
      if (state.canUpdateModels || options?.forceUpdate) {
        console.log("[STATE] upadteModels", newModels);
        const newIds = newModels.map((m) => m.id);
        state.models = state.models.map((model) => {
          if (newIds.includes(model.id)) {
            return {...model, ...newModels.find((m) => m.id === model.id)};
          } else {
            return model;
          }
        });
      }
    },
    [addResizedUrls.fulfilled]: (state, action) => {
      const updatedModel = action.payload;
      state.models = state.models.map((model) => {
        if (model.id === updatedModel.id) {
          return updatedModel;
        } else {
          return model;
        }
      });
    },
    [addGltfUrl.fulfilled]: (state, action) => {
      const updatedModel = action.payload;
      state.models = state.models.map((model) => {
        if (model.id === updatedModel.id) {
          return updatedModel;
        } else {
          return model;
        }
      });
    },
    [addJsonUrl.fulfilled]: (state, action) => {
      const updatedModel = action.payload;
      state.models = state.models.map((model) => {
        if (model.id === updatedModel.id) {
          return updatedModel;
        } else {
          return model;
        }
      });
    },
    [deleteModel.fulfilled]: (state, action) => {
      const deletedModel = action.payload;
      console.log("deletedModel", deletedModel);
      const newModels = state.models.filter(
        (m) => m.id !== deletedModel.id || m.type !== deletedModel.type
      );
      state.models = newModels;
    },
    "scenes/deleteRemoteScene/fulfilled": (state, action) => {
      const scene = action.payload;
      state.models = state.models.filter((m) => m.sceneClientId !== scene.id);
    },
    "scenes/deleteRemoteModel/fulfilled": (state, action) => {
      if (!action.payload) return;
      const {modelId} = action.payload;
      state.models = state.models.map((model) => {
        if (model.id === modelId) {
          return {
            ...model,
            version: null,
            nextSync: {action: true, file: true, data: true},
          };
        } else {
          return model;
        }
      });
    },
    [fetchRemoteWorksiteModels.fulfilled]: (state, action) => {
      const models = action.payload;
      console.log("fetch worksite models", models);
    },
    [fetchSceneListings.fulfilled]: (state, action) => {
      state.sceneListings = {
        status: "loaded",
        data: action.payload.listings,
        sceneId: action.payload.sceneId,
      };
    },
    [fetchSceneListings.pending]: (state, action) => {
      state.sceneListings = {status: "loading", data: []};
    },
    // "scenes/createScene/fulfilled": (state, action) => {
    //   const {scene} = action.payload;
    //   // risk : conflict with fetching remote scene
    //   // state.scenesStatus[scene.clientId] = "loaded";
    // },
    "listings/updateListing/fulfilled": (state, action) => {
      const updatedList = action.payload;
      const newListings = state.sceneListings.data.map((listing) => {
        if (listing.id === updatedList.id) {
          return {...listing, ...updatedList};
        } else {
          return listing;
        }
      });
      state.sceneListings = {...state.sceneListings, data: newListings};
    },
    "scenes/fetchSharedRemoteScene/fulfilled": (state, action) => {
      const {scene, models, notes} = action.payload;
      //state.sharedRemoteSceneModelsLoaded = true;
      //console.log("shared remote models", models);
      const existingIds = state.models.map((m) => m.id);
      models.forEach((model) => {
        if (!existingIds.includes(model.id)) {
          state.models.push(model);
        }
      });
      state.scenesStatus[scene.clientId] = "preloaded";
      //state.scenesPreloaded[scene.clientId] = true; // REDUNDANT WITH PREVIOUS STATE
      state.sceneListings = {
        status: "loaded",
        data: notes.map((n) => n.listing),
        sceneId: action.payload.sceneId,
      };
    },
    "colorings/fetchColoringScene/fulfilled": (state, action) => {
      // copy fetch remote scene. check if need to use usedDonwload
      if (action.payload) {
        const {scene, models} = action.payload;
        console.log("FCS", scene, models);
        if (!scene) return;
        const remoteModels = models;
        console.log("remoteModels42", remoteModels);
        const modelsIds = state.models.map((m) => m.id);
        const newRemoteModels = remoteModels.filter(
          (m) => !modelsIds.includes(m.id)
        );
        const updatedRemoteModels = remoteModels.filter((m) =>
          modelsIds.includes(m.id)
        );
        const updatedRemoteModelsIds = updatedRemoteModels.map((m) => m.id);
        const updatedModels = state.models.map((m) => {
          if (updatedRemoteModelsIds.includes(m.id)) {
            return {...m, ...updatedRemoteModels.find((rm) => rm.id === m.id)};
          } else {
            return m;
          }
        });
        const stateModels = [...updatedModels, ...newRemoteModels];
        state.models = stateModels;

        // update scenesStatus to trigger measurementsInitialScope
        state.scenesStatus[scene?.clientId] = "preloaded";
      }
    },

    "scenes/fetchRemoteScene/fulfilled": (state, action) => {
      // /!\ replaced by useDownloadRemoteScene...
      const fetchResult = action.payload;
      const scene = fetchResult?.remoteScene;
      const updateSceneStateOnly = fetchResult?.updateSceneStateOnly;
      if (!scene || updateSceneStateOnly) return;
      const remoteModels = scene.models;
      console.log("remoteModels43", remoteModels);
      const modelsIds = state.models.map((m) => m.id);
      const newRemoteModels = remoteModels.filter(
        (m) => !modelsIds.includes(m.id)
      );
      const updatedRemoteModels = remoteModels.filter((m) =>
        modelsIds.includes(m.id)
      );
      const updatedRemoteModelsIds = updatedRemoteModels.map((m) => m.id);
      const updatedModels = state.models.map((m) => {
        if (updatedRemoteModelsIds.includes(m.id)) {
          return {...m, ...updatedRemoteModels.find((rm) => rm.id === m.id)};
        } else {
          return m;
        }
      });
      const stateModels = [...updatedModels, ...newRemoteModels];
      console.log("stateModels", stateModels);
      state.models = stateModels;
      // end
      const exists = state.fetchedRemoteScenes.includes(scene.clientId);
      if (!exists) {
        state.fetchedRemoteScenes.push(scene.clientId);
        state.scenesStatus[scene?.clientId] = "preloaded";
      }
    },
    "scenes/fetchRemoteSceneLight/fulfilled": (state, action) => {
      const scene = action.payload;
      if (!scene) return;
      const remoteModels = scene.models;
      console.log("remoteModels444", remoteModels);
      const modelsIds = state.models.map((m) => m.id);
      const newRemoteModels = remoteModels.filter(
        (m) => !modelsIds.includes(m.id)
      );
      const updatedRemoteModels = remoteModels.filter((m) =>
        modelsIds.includes(m.id)
      );
      const updatedRemoteModelsIds = updatedRemoteModels.map((m) => m.id);
      const updatedModels = state.models.map((m) => {
        if (updatedRemoteModelsIds.includes(m.id)) {
          return {...m, ...updatedRemoteModels.find((rm) => rm.id === m.id)};
        } else {
          return m;
        }
      });
      const stateModels = [...updatedModels, ...newRemoteModels];
      console.log("stateModels", stateModels);
      state.models = stateModels;
      // end
    },
  },
  reducers: {
    //
    // MODELS
    //
    updateModelNotAsync: (state, action) => {
      // we should consider measurementsModel as well as pdfModels
      const {updatedModel, updates, sync, sceneData} = action.payload;

      let metaData = {};
      if (sceneData) {
        metaData = getPackageMetadata(
          updates?.measurementsData.measurements,
          sceneData
        );
      }
      let nextSync = {...updatedModel?.nextSync};
      if (sync) {
        nextSync.action = true;
        nextSync.data = true;
      }
      const modelId = updatedModel?.id ? updatedModel.id : updates?.id;
      const newModels = state.models.map((model) => {
        if (model.id === modelId) {
          if (updatedModel) {
            return {...updatedModel, nextSync};
          } else {
            console.log("updateModel with updates v12", updates, {...model});
            return {...model, ...updates, ...metaData, nextSync};
          }
        } else {
          return model;
        }
      });
      state.models = newModels;
    },
    setCanUpdateModels: (state, action) => {
      state.canUpdateModels = action.payload;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    setModelsLoaderOptions: (state, action) => {
      state.modelsLoaderOptions = action.payload;
    },
    setShowScreenModelsLoaderHelper: (state, action) => {
      state.showScreenModelsLoaderHelper = action.payload;
    },
    setIs3dEnabled: (state, action) => {
      state.is3dEnabled = action.payload;
    },
    setScenesDialog: (state, action) => {
      state.scenesDialog = action.payload;
    },
    setIsFirstLoading: (state, action) => {
      state.isFirstLoading = action.payload;
    },
    setLandingPage: (state, action) => {
      state.landingPage = action.payload;
    },
    setWorksitesPage: (state, action) => {
      state.worksitesPage = action.payload;
    },
    setBimboxPage: (state, action) => {
      state.bimboxPage = action.payload;
    },
    setSpacePage: (state, action) => {
      state.spacePage = action.payload;
    },
    setGrid: (state, action) => {
      state.grid = action.payload;
    },
    setShowEdge: (state, action) => {
      state.showEdge = action.payload;
    },
    setShowVoid: (state, action) => {
      state.showVoid = action.payload;
    },
    setPicking: (state, action) => {
      state.picking = action.payload;
    },
    setBoxSelection: (state, action) => {
      state.boxSelection = action.payload;
    },
    setBeta: (state, action) => {
      state.beta = action.payload;
    },
    setViewerWidth: (state, action) => {
      state.viewerWidth = action.payload;
    },
    setFixedViewersBoxWidth: (state, action) => {
      state.fixedViewersBoxWidth = action.payload;
    },
    setIsViewerVisible: (state, action) => {
      state.isViewerVisible = action.payload;
    },
    setIsViewerFullScreen: (state, action) => {
      state.isViewerFullScreen = action.payload;
    },
    setRendererSize: (state, action) => {
      state.rendererSize = action.payload;
    },
    setRendererOffset: (state, action) => {
      state.rendererOffset = action.payload;
    },
    setExplorerWidth: (state, action) => {
      state.explorerWidth = action.payload;
    },
    setDataSectionWidth: (state, action) => {
      state.dataSectionWidth = action.payload;
    },
    initSceneState: (state) => {
      state.sceneListings = {status: "idle", data: [], sceneId: null};
    },
    setMultiviews: (state, action) => {
      state.multiviews = action.payload;
    },
    setMultiviewsSliderPositionX: (state, action) => {
      state.multiviewsSliderPositionX = action.payload;
    },
    setCam2Height: (state, action) => {
      state.cam2Height = action.payload;
    },
    setCam2Polar: (state, action) => {
      state.cam2Polar = action.payload;
    },
    setCutCam1: (state, action) => {
      state.cutCam1 = action.payload;
    },
    setCutAtHeight: (state, action) => {
      state.cutAtHeight = action.payload;
    },
    setOptimIsEnabled: (state, action) => {
      state.optimIsEnabled = action.payload;
    },
    setAlignedToNorth: (state, action) => {
      state.alignedToNorth = action.payload;
    },
    setKeepMainPart: (state, action) => {
      state.keepMainPart = action.payload;
    },
    setImageTranslationRangeMax: (state, action) => {
      state.imageTranslationRangeMax = action.payload;
    },
    setWalkMode: (state, action) => {
      state.walkMode = action.payload;
    },
    setImageOverlayMode: (state, action) => {
      state.imageOverlayMode = action.payload;
    },
    setImageOverlayProps: (state, action) => {
      state.imageOverlayProps = action.payload;
    },
    setShowMeasurementsInViewer: (state, action) => {
      state.showMeasurementsInViewer = action.payload;
    },
    setStoriesReaderMode: (state, action) => {
      state.storiesReaderMode = action.payload;
    },
    setMainTool: (state, action) => {
      state.mainTool = action.payload;
    },
    setMainSection: (state, action) => {
      state.mainSection = action.payload;
    },
    setLoaderScreen: (state, action) => {
      state.loaderScreen = action.payload;
    },
    setScreenshot: (state, action) => {
      state.screenshot = action.payload;
    },
    setMobileTool: (state, action) => {
      state.mobileTool = action.payload;
    },
    setWebWorker: (state, action) => {
      state.webWorker = action.payload;
    },
    setLoadLoadingBoxOnly: (state, action) => {
      state.loadLoadingBoxOnly = action.payload;
    },
    setRawIfcModel: (state, action) => {
      state.rawIfcModel = action.payload;
    },
    setStoreFiles: (state, action) => {
      state.storeFiles = action.payload;
    },
    setGltfOnly: (state, action) => {
      state.gltfOnly = action.payload;
    },
    setLoadEdges: (state, action) => {
      state.loadEdges = action.payload;
    },
    setCreateJson: (state, action) => {
      state.createJson = action.payload;
    },
    setAutoplacement: (state, action) => {
      state.autoplacement = action.payload;
    },
    setLoadIfcStructure: (state, action) => {
      state.loadIfcStructure = action.payload;
    },
    setSnappingMode: (state, action) => {
      state.snappingMode = action.payload;
    },
    setPointerMode: (state, action) => {
      state.pointerMode = action.payload;
    },
    setPointerPosition: (state, action) => {
      state.pointerPosition = action.payload;
    },
    setSubstractVoids: (state, action) => {
      state.substractVoids = action.payload;
    },
    setSelectedModelId: (state, action) => {
      state.selectedModelId = action.payload;
    },
    setSelectedPdfModelId: (state, action) => {
      state.selectedPdfModelId = action.payload;
    },
    setClickedObject: (state, action) => {
      state.clickedObject = action.payload;
    },
    setEditedObject: (state, action) => {
      state.editedObject = action.payload;
    },
    setModelsFilters: (state, action) => {
      const newFilters = action.payload;
      const newModelsFilters = {...state.modelsFilters, ...newFilters};
      state.modelsFilters = newModelsFilters;
    },

    setDatasetTypesInScope: (state, action) => {
      state.datasetTypesInScope = action.payload;
    },
    setFilesDownloadLimit: (state, action) => {
      state.filesDownloadLimit = action.payload;
    },
    toggleDatasetTypeInScope: (state, action) => {
      const {type, datasetType} = action.payload;
      const oldScope = state.datasetTypesInScope;
      let newScope;
      if (datasetType === "LISTING") {
        const inScope = oldScope.listings.includes(type);
        if (inScope) {
          newScope = {
            ...oldScope,
            listings: oldScope.listings.filter((l) => l !== type),
          };
        } else {
          newScope = {...oldScope, listings: [...oldScope.listings, type]};
        }
      } else if (datasetType === "MODEL") {
        const inScope = oldScope.models.includes(type);
        if (inScope) {
          newScope = {
            ...oldScope,
            models: oldScope.models.filter((l) => l !== type),
          };
        } else {
          newScope = {...oldScope, models: [...oldScope.models, type]};
        }
      } else if (datasetType === "ZONE_MODEL") {
        newScope = {...oldScope, zoneModels: !oldScope.zoneModels};
      }
      state.datasetTypesInScope = newScope;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setModelsColor: (state, action) => {
      state.modelsColor = action.payload;
    },
    setPositioningObject: (state, action) => {
      const po = state.positioningObject;
      const newPo = action.payload;
      state.positioningObject = {...po, ...newPo};
    },
    setOrbiting: (state, action) => {
      const orbiting = action.payload;
      state.orbiting = orbiting;
    },
    setPanning: (state, action) => {
      const panning = action.payload;
      state.panning = panning;
    },
    updateIsOrthoObject: (state, action) => {
      const ioo = state.isOrthoObject;
      const newIoo = action.payload;
      state.isOrthoObject = {...ioo, ...newIoo};
    },
    setClickContext: (state, action) => {
      state.clickContext = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
      state.action = getAction(state.mode, state.step);
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setSubStep: (state, action) => {
      state.subStep = action.payload;
    },
    setProcessMoveOnePoint: (state, action) => {
      state.processMoveOnePoint = action.payload;
    },
    setProcessMeasure: (state, action) => {
      state.processMeasure = action.payload;
    },
    updateProcessMeasure: (state, action) => {
      const updates = action.payload;
      state.processMeasure = {...state.processMeasure, ...updates};
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    setShowDataPreview: (state, action) => {
      state.showDataPreview = action.payload;
    },
    setShow3D: (state, action) => {
      state.show3D = action.payload;
    },
    setShowToolAddElement: (state, action) => {
      state.showToolAddElement = action.payload;
    },

    setTempObjects: (state, action) => {
      state.tempObjects = action.payload;
    },
    setTempAnnotations: (state, action) => {
      state.tempAnnotations = action.payload;
    },
    addTempAnnotation: (state, action) => {
      const annotation = action.payload;
      const clone = state.tempAnnotations.find((a) => a.id === annotation.id);
      if (!clone) state.tempAnnotations.push(action.payload);
    },
    updateTempAnnotation: (state, action) => {
      const annotation = action.payload;
      let tempAnnotations = state.tempAnnotations.filter(
        (a) => a.id !== annotation.id
      );
      state.tempAnnotations = [...tempAnnotations, annotation];
    },
    removeTempAnnotation: (state, action) => {
      const annotation = action.payload;
      state.tempAnnotations = state.tempAnnotations.filter(
        (a) => a.id !== annotation.id
      );
    },
    setConfiguring: (state, action) => {
      state.configuring = action.payload;
    },
    setConfigCenter: (state, action) => {
      state.configCenter = action.payload;
    },
    setRightSection: (state, action) => {
      state.rightSection = action.payload;
    },
    setDetailSection: (state, action) => {
      state.detailSection = action.payload;
    },
    setToolData: (state, action) => {
      state.toolData = action.payload;
    },
    setToolViewer: (state, action) => {
      state.toolViewer = action.payload;
    },
    setToolGroup: (state, action) => {
      state.toolGroup = action.payload;
    },
    setEditTool: (state, action) => {
      state.editTool = action.payload;
    },
    setExplorerSection: (state, action) => {
      state.explorerSection = action.payload;
    },
    setMarkerPosition: (state, action) => {
      state.markerPosition = action.payload;
    },
    loadDemoModels: (state, action) => {
      const {models: newModels, scene} = action.payload;
      state.models.push(...newModels);
      const modelsStatus = newModels.reduce((acc, current) => {
        acc[current.id] = "idle";
        return acc;
      }, {});
      state.modelsStatus[scene.clientId] = modelsStatus;
      state.scenesStatus[scene.clientId] = "preloaded";
      state.demos[scene.clientId] = {status: "loaded"};
    },
    // setScenePreloaded: (state, action) => {
    //   const {sceneClientId, preloaded} = action.payload;
    //   state.scenesPreloaded[sceneClientId] = preloaded;
    // },
    addDetailedModel: (state, action) => {
      const oldDetailedModels = state.detailedModels;
      const newModel = action.payload;
      if (!oldDetailedModels.includes(newModel))
        state.detailedModels.push(newModel);
    },
    addModelWithInitialTexture: (state, action) => {
      const id = action.payload;
      state.modelsWithInitialTexture = [...state.modelsWithInitialTexture, id];
    },
    removeModelWithInitialTexture: (state, action) => {
      const modelId = action.payload;
      state.modelsWithInitialTexture = state.modelsWithInitialTexture(
        (id) => id !== modelId
      );
    },
    addInitialLoadedModel: (state, action) => {
      const oldModels = state.initialLoadedModels;
      const newModelId = action.payload;
      if (!oldModels.includes(newModelId))
        state.initialLoadedModels.push(newModelId);
    },
    addNewModel: (state, action) => {
      const oldNewModels = state.newModels;
      const newModel = action.payload;
      if (!oldNewModels.includes(newModel)) state.newModels.push(newModel);
    },
    removeNewModel: (state, action) => {
      const modelId = action.payload;
      state.newModels = state.newModels.filter((m) => m.id !== modelId);
    },

    addFetchedRemoteScene: (state, action) => {
      const sceneClientId = action.payload;
      const exists = state.fetchedRemoteScenes.includes(sceneClientId);
      if (!exists) state.fetchedRemoteScenes.push(sceneClientId);
    },
    updateScenesStatus: (state, action) => {
      const {sceneClientId, status} = action.payload;
      state.scenesStatus[sceneClientId] = status;
      if (status === "idle") {
        // init models status
        state.modelsStatus[sceneClientId] = {};
      }
    },
    updateModelsStatus: (state, action) => {
      const {model, status} = action.payload;
      const sceneClientId = model.sceneClientId;
      const modelId = model.id;
      if (!state.modelsStatus[sceneClientId]) {
        state.modelsStatus[sceneClientId] = {[modelId]: status};
      } else {
        state.modelsStatus[sceneClientId][modelId] = status;
      }
    },
    updateModelsProgress: (state, action) => {
      const {model, progress} = action.payload;
      const sceneClientId = model.sceneClientId;
      const modelId = model.id;
      const sceneProgress = state.modelsProgress[sceneClientId];
      if (!sceneProgress) {
        state.modelsProgress[sceneClientId] = {[modelId]: progress};
      } else {
        state.modelsProgress[sceneClientId][modelId] = progress;
      }
    },
    updateModelsSyncStatus: (state, action) => {
      const newModelStatus = action.payload; // {modelId,progress,status,downloadingProgress,isUploading}
      const allStatus = [...state.modelsSyncStatus];
      const currentStatus = allStatus.find(
        (s) => s.modelId === newModelStatus.modelId
      );
      if (currentStatus) {
        const newStatus = state.modelsSyncStatus.map((s) => {
          if (s.modelId === newModelStatus.modelId) {
            return newModelStatus;
          } else {
            return s;
          }
        });
        state.modelsSyncStatus = newStatus;
      } else {
        state.modelsSyncStatus.push({...newModelStatus});
      }
    },
    updateModelsVisibility: (state, action) => {
      const modelsVisibility = action.payload; // {modelId:!hidden}
      const newModels = state.models.map((model) => {
        const visible = modelsVisibility[model.id];
        return {...model, hidden: !visible};
      });

      state.models = newModels;
    },
    updateScenesSyncStatus: (state, action) => {
      const newSceneStatus = action.payload; // {sceneClientId,isUploading}
      const allStatus = [...state.scenesSyncStatus];
      const currentStatus = allStatus.find(
        (s) => s.sceneClientId === newSceneStatus.sceneClientId
      );
      if (currentStatus) {
        const newStatus = state.scenesSyncStatus.map((s) => {
          if (s.sceneClientId === newSceneStatus.sceneClientId) {
            return newSceneStatus;
          } else {
            return s;
          }
        });
        state.scenesSyncStatus = newStatus;
      } else {
        state.scenesSyncStatus.push({...newSceneStatus});
      }
    },
    updateOptimStatus: (state, action) => {
      const status = action.payload;
      const allStatus = [...state.optimStatus];
      const currentStatus = allStatus.find((s) => s.modelId === status.modelId);
      if (currentStatus) {
        const newStatus = state.optimStatus.map((s) => {
          if (s.modelId === status.modelId) {
            return status;
          } else {
            return s;
          }
        });
        state.optimStatus = newStatus;
      } else {
        state.optimStatus.push({...status});
      }
    },
    updateIfcStructures: (state, action) => {
      const newStoreys = action.payload.ifcStoreys;
      const newTypes = action.payload.ifcTypes;
      const newSubsets = action.payload.ifcSubsets;
      const tree = action.payload.tree;
      const modelId = action.payload.modelId;
      const ifcModelID = action.payload.ifcModelID;

      state.ifcStoreys = [...state.ifcStoreys, ...newStoreys];
      state.ifcSubsets = [...state.ifcSubsets, ...newSubsets];
      state.ifcTypes = [...state.ifcTypes, ...newTypes];
      state.ifcTrees = [...state.ifcTrees, {modelId, tree, ifcModelID}];
    },
    addModel: (state, action) => {
      const newModel = action.payload;
      const modelsIds = state.models.map((model) => model.id);
      const modelIsNew = !modelsIds.includes(newModel.id);
      if (modelIsNew) state.models.push(newModel);
    },

    addSite: (state, action) => {
      state.sites.push(action.payload);
    },
    addIfcSubsets: (state, action) => {
      const newSubsets = action.payload;
      state.ifcSubsets = [...state.ifcSubsets, ...newSubsets];
    },
    addIfcStoreys: (state, action) => {
      const newStoreys = action.payload;
      state.ifcStoreys = [...state.ifcStoreys, ...newStoreys];
    },
    toggleAllStoreysVisibility: (state, action) => {
      const {ifcModelID, visible} = action.payload;
      const newStoreys = state.ifcStoreys.map((storey) => {
        if (storey.ifcModelID === ifcModelID) {
          const newStorey = {...storey, visible};
          return newStorey;
        } else {
          return storey;
        }
      });
      state.ifcStoreys = newStoreys;
    },
    // one storey  = visible, all the other are hidden
    switchOneStoreyVisibility: (state, action) => {
      const {ifcModelID, expressID} = action.payload;
      const newStoreys = state.ifcStoreys.map((storey) => {
        if (
          storey.ifcModelID === ifcModelID &&
          storey.expressID === expressID
        ) {
          const newStorey = {...storey, visible: true};
          return newStorey;
        } else if (
          storey.ifcModelID === ifcModelID &&
          storey.expressID !== expressID
        ) {
          return {...storey, visible: false};
        } else {
          return storey;
        }
      });
      state.ifcStoreys = newStoreys;
    },
    toggleIfcStoreyVisibility: (state, action) => {
      const {expressID, ifcModelID, visible} = action.payload;
      const newStorey = state.ifcStoreys.find(
        (s) => s.expressID === expressID && s.ifcModelID === ifcModelID
      );
      newStorey.visible = !newStorey.visible;
      const newStoreys = state.ifcStoreys.map((storey) => {
        if (
          storey.expressID === expressID &&
          storey.ifcModelID === ifcModelID
        ) {
          return newStorey;
        } else {
          return storey;
        }
      });
      state.ifcStoreys = newStoreys;
    },
    updateIfcStoreys: (state, action) => {
      const newIfcStoreys = action.payload;
      let currentIfcStoreys = [...state.ifcStoreys];
      newIfcStoreys.forEach((newIfcStorey) => {
        currentIfcStoreys = currentIfcStoreys.map((s) => {
          if (
            s.modelId === newIfcStorey.modelId &&
            s.expressID === newIfcStorey.expressID
          ) {
            return newIfcStorey;
          } else {
            return s;
          }
        });
      });
      state.ifcStoreys = currentIfcStoreys;
    },
    addIfcTypes: (state, action) => {
      const newTypes = action.payload;
      state.ifcTypes = [...state.ifcTypes, ...newTypes];
    },
    toggleAllTypesVisibility: (state, action) => {
      const {ifcModelID, visible} = action.payload;
      const newTypes = state.ifcTypes.map((type) => {
        if (type.ifcModelID === ifcModelID) {
          const newType = {...type, visible};
          return newType;
        } else {
          return type;
        }
      });
      state.ifcTypes = newTypes;
    },
    // one type  = visible, all the other are hidden
    switchOneTypeVisibility: (state, action) => {
      const {ifcModelID, type} = action.payload;
      const newTypes = state.ifcTypes.map((ifcT) => {
        if (ifcT.ifcModelID === ifcModelID && ifcT.type === type) {
          const newType = {...ifcT, visible: true};
          return newType;
        } else if (ifcT.ifcModelID === ifcModelID && ifcT.type !== type) {
          return {...ifcT, visible: false};
        } else {
          return ifcT;
        }
      });
      state.ifcTypes = newTypes;
    },
    updateIfcTypes: (state, action) => {
      const newIfcTypes = action.payload;
      let currentIfcTypes = [...state.ifcTypes];
      newIfcTypes.forEach((newIfcType) => {
        currentIfcTypes = currentIfcTypes.map((s) => {
          if (s.modelId === newIfcType.modelId && s.type === newIfcType.type) {
            return newIfcType;
          } else {
            return s;
          }
        });
      });
      state.ifcTypes = currentIfcTypes;
    },
    toggleIfcTypeVisibility: (state, action) => {
      const {type, ifcModelID} = action.payload;
      const newIfcType = state.ifcTypes.find(
        (t) => t.type === type && t.ifcModelID === ifcModelID
      );
      newIfcType.visible = !newIfcType.visible;
      const newIfcTypes = state.ifcTypes.map((ifcType) => {
        if (ifcType.type === type && ifcType.ifcModelID === ifcModelID) {
          return newIfcType;
        } else {
          return ifcType;
        }
      });
      state.ifcTypes = newIfcTypes;
    },
    addMarker: (state, action) => {
      state.markers.push(action.payload);
    },
    deleteMarker: (state, action) => {
      const id = action.payload;
      state.markers = state.markers.filter((marker) => marker.id !== id);
    },
    updateMarker: (state, action) => {
      const updatedMarker = action.payload;
      let newMarkers = state.markers.filter(
        (marker) => marker.id !== updatedMarker.id
      );
      newMarkers = [...newMarkers, updatedMarker];
      state.markers = newMarkers;
    },
    setConfiguredModel: (state, action) => {
      state.configuredModel = action.payload;
    },
    updateConfiguredModel: (state, action) => {
      const updatedModel = action.payload;
      state.configuredModel = updatedModel;
      const updatedModels = state.models.map((model) => {
        if (model.id != updatedModel.id) {
          return model;
        } else {
          return updatedModel;
        }
      });
      state.models = updatedModels;
    },
    toggleModelVisibility: (state, action) => {
      const modelID = action.payload;
      const newModels = state.models.map((model) => {
        if (model.id === modelID) {
          model.hidden = !model.hidden;
        }
        return model;
      });
      state.models = newModels;
    },
    setSelection: (state, action) => {
      state.selection = action.payload;
    },
    setSelectedIfcElement: (state, action) => {
      state.selectedIfcElement = action.payload;
    },
    setMultipleSelection: (state, action) => {
      state.multipleSelection = action.payload;
    },
    setObjectsSelection: (state, action) => {
      state.objectsSelection = action.payload;
    },
    setHiddenElements: (state, action) => {
      state.hiddenElements = action.payload;
    },
    clearMultipleSelection: (state, action) => {
      // set expressIDs = [] => clear selection with createSubset()
      const oldMs = state.multipleSelection;
      const newMs = oldMs.map((s) => ({...s, expressIDs: []}));
      state.multipleSelection = newMs;
    },
    updateMultipleSelection: (state, action) => {
      const s = action.payload;
      const oldMs = state.multipleSelection;
      let newModel = true;
      const newMs = oldMs.map((selection) => {
        if (selection.modelId === s.modelId) {
          newModel = false;
          const oldExpressIDs = selection.expressIDs;
          const updatedExpressIDs = s.expressIDs;
          let newExpressIDs = [...oldExpressIDs];
          updatedExpressIDs.forEach((id) => {
            if (newExpressIDs.includes(id)) {
              newExpressIDs = newExpressIDs.filter((i) => i !== id);
            } else {
              newExpressIDs.push(id);
            }
          });
          const newSelection = {...selection, expressIDs: newExpressIDs};
          return newSelection;
        } else {
          return selection;
        }
      });
      if (newModel) newMs.push(s);
      state.multipleSelection = newMs;
    },
    setShowSelectionCard: (state, action) => {
      state.showSelectionCard = action.payload;
    },
    updateSelection: (state, action) => {
      const updates = action.payload;
      const updatedModel = updates.model;
      const oldSelection = state.selection;

      const updatedSelection = {...oldSelection, ...updates};
      state.selection = updatedSelection;

      if (updatedModel) {
        const updatedModels = state.models.map((model) => {
          if (model.id != updatedModel.id) {
            return model;
          } else {
            return updatedModel;
          }
        });
        state.models = updatedModels;
      }
    },
    displayStorey: (state, action) => {
      const payload = action.payload;
      state.hiddenStoreys = state.hiddenStoreys.filter(
        ({ifcModelID, storeyExpressID}) => {
          return (
            ifcModelID != payload.ifcModelID ||
            storeyExpressID != payload.storeyExpressID
          );
        }
      );
    },
    hideStorey: (state, action) => {
      const {ifcModelID, storeyExpressID} = action.payload;
      state.hiddenStoreys.push({ifcModelID, storeyExpressID});
    },
    addNotesListing: (state, action) => {
      state.sceneListings.data.push(action.payload);
    },
    setSceneListingId: (state, action) => {
      state.sceneListingId = action.payload;
    },
    setSelectedNoteId: (state, action) => {
      state.selectedNoteId = action.payload;
    },
    setProcessAnimate: (state, action) => {
      state.processAnimate = action.payload;
    },
    setAzmEditorIsLoaded: (state, action) => {
      state.azmEditorIsLoaded = action.payload;
    },
    setMapClickedProps: (state, action) => {
      state.mapClickedProps = action.payload;
    },
    setMapProvider: (state, action) => {
      state.mapProvider = action.payload;
    },
    setPdfViewerStatus: (state, action) => {
      state.pdfViewerStatus = action.payload;
    },
    setPdfCurrentPage: (state, action) => {
      state.pdfCurrentPage = action.payload;
    },
    setPdftronOffset: (state, action) => {
      state.pdftronOffset = action.payload;
    },
    setPdfMarkers: (state, action) => {
      state.pdfMarkers = action.payload;
    },
    addPdfMarker: (state, action) => {
      const marker = action.payload;
      const {markerId, modelId, pageNumber} = marker;
      let newMarkers = [...state.pdfMarkers];
      // we don't "REPLACE" => a change of length won't be detected. We keep the number instead.
      // newMarkers = newMarkers.filter(
      //   (m) =>
      //     m.markerId !== markerId ||
      //     m.modelId !== modelId ||
      //     m.pageNumber !== pageNumber
      // );
      const num = newMarkers.length;
      newMarkers.push({...marker, num});
      state.pdfMarkers = newMarkers;
    },
    deletePdfMarker: (state, action) => {
      const {id, modelId, pageNumber} = action.payload;
      const newMarkers = state.pdfMarkers.filter(
        (m) =>
          m.id !== id && m.modelId !== modelId && m.pageNumber !== pageNumber
      );
      state.pdfMarkers = newMarkers;
    },
    setPdfSnapToLine: (state, action) => {
      const snapToLine = action.payload;
      state.snapToLine = snapToLine;
    },
    setPdfDrawDirection: (state, action) => {
      const pdfDrawDirection = action.payload;
      state.pdfDrawDirection = pdfDrawDirection;
    },
    hideCaplaElements: (state, action) => {
      const elements = action.payload;
      let hiddenElements = [...state.hiddenCaplaElements];
      elements.forEach((element) => {
        const exist = hiddenElements.find(
          (e) =>
            e.caplaModelId === element.caplaModelId &&
            e.codeName === element.codeName
        );
        if (!exist) hiddenElements = [...hiddenElements, element];
      });
      state.hiddenCaplaElements = hiddenElements;
    },
    showCaplaElements: (state, action) => {
      const elements = action.payload;
      const elementsHashes = elements.map((e) => e.caplaModelId + e.codeName);
      state.hiddenCaplaElements = state.hiddenCaplaElements.filter(
        (e) => !elementsHashes.includes(e.caplaModelId + e.codeName)
      );
    },
    setSelectedCaplaElementType: (state, action) => {
      const {modelId, code} = action.payload;
      state.selectedCaplaElementType = {modelId, code};
    },
    setInitZoomOutWithEntities: (state, action) => {
      state.initZoomOutWithEntities = action.payload;
    },
    setFirstRendered: (state, action) => {
      state.firstRendered = action.payload;
    },
    setDisableRender: (state, action) => {
      state.disableRender = action.payload;
    },
    setGlobalTransparency: (state, action) => {
      state.globalTransparency = action.payload;
    },
  },
});

/*
 * SELECTORS
 */

export const selectContext = (sceneClientId) => (state) => {
  const models = state.viewer3D.models.filter(
    (m) => m.sceneClientId === sceneClientId
  );
  const modelsIds = models.map((m) => m.id);
  const ifcTypes = state.viewer3D.ifcTypes.filter((s) =>
    modelsIds.includes(s.modelId)
  );
  const ifcStoreys = state.viewer3D.ifcStoreys.filter((s) =>
    modelsIds.includes(s.modelId)
  );
  const hiddenModelsIds = models
    .filter((m) => m.hidden === true)
    .map((m) => m.id);
  return {
    ifcTypes,
    ifcStoreys,
    hiddenModelsIds,
  };
};

export const {
  updateModelNotAsync,
  setIs3dEnabled,
  setModelsLoaderOptions,
  setShowScreenModelsLoaderHelper,
  setCanUpdateModels,
  setIsLoaded,
  setScenesDialog,
  setIsFirstLoading,
  setLandingPage,
  setBimboxPage,
  setSpacePage,
  setWorksitesPage,
  setBeta,
  setViewerWidth,
  setFixedViewersBoxWidth,
  setIsViewerVisible,
  setIsViewerFullScreen,
  setRendererSize,
  setRendererOffset,
  setExplorerWidth,
  setDataSectionWidth,
  initSceneState,
  setMultiviews,
  setMultiviewsSliderPositionX,
  setCam2Height,
  setCam2Polar,
  setCutCam1,
  setCutAtHeight,
  setOptimIsEnabled,
  setAlignedToNorth,
  setKeepMainPart,
  setImageTranslationRangeMax,
  setWalkMode,
  setImageOverlayMode,
  setImageOverlayProps,
  setShowMeasurementsInViewer,
  setWebWorker,
  setSelectedModelId,
  setSelectedPdfModelId,
  setClickedObject,
  setEditedObject,
  updateScenesStatus,
  updateModelsStatus,
  updateModelsProgress,
  updateModelsSyncStatus,
  updateScenesSyncStatus,
  updateOptimStatus,
  setScenePreloaded,
  setMainTool,
  setMainSection,
  setLoaderScreen,
  setScreenshot,
  setMobileTool,
  setName,
  setConfiguring,
  setConfigCenter,
  setMode,
  setViewMode,
  setShowDataPreview,
  setShow3D,
  setShowToolAddElement,
  setTempObjects,
  setTempAnnotations,
  addTempAnnotation,
  removeTempAnnotation,
  updateTempAnnotation,
  setStep,
  setSubStep,
  setProcessMoveOnePoint,
  setProcessMeasure,
  updateProcessMeasure,
  loadDemoModels,
  addModel,
  selectModel,
  addMarker,
  deleteMarker,
  updateMarker,
  setSelection,
  setMultipleSelection,
  setObjectsSelection,
  updateMultipleSelection,
  clearMultipleSelection,
  setHiddenElements,
  setShowSelectionCard,
  updateSelection,
  displayStorey,
  hideStorey,
  toggleModelVisibility,
  setRightSection,
  setDetailSection,
  setToolData,
  setToolViewer,
  setToolGroup,
  setGrid,
  setShowEdge,
  setShowVoid,
  setPicking,
  setBoxSelection,
  setEditTool,
  setExplorerSection,
  updateSelectedModel,
  setSavedPosition,
  setPositioningObject,
  setOrbiting,
  setPanning,
  setConfiguredModel,
  updateConfiguredModel,
  updateIsOrthoObject,
  setMarkerPosition,
  toggleIfcTypeVisibility,
  toggleAllTypesVisibility,
  switchOneTypeVisibility,
  addIfcStoreys,
  updateIfcStoreys,
  updateIfcTypes,
  toggleIfcStoreyVisibility,
  toggleAllStoreysVisibility,
  switchOneStoreyVisibility,
  addIfcSubsets,
  addIfcTypes,
  addSite,
  setModelsFilters,
  setModelsColor,
  addNotesListing,
  setSceneListingId,
  setSelectedNoteId,
  setProcessAnimate,
  updateIfcStructures,
  setLoadLoadingBoxOnly,
  setRawIfcModel,
  setGltfOnly,
  setStoreFiles,
  setLoadEdges,
  setCreateJson,
  setLoadIfcStructure,
  setAutoplacement,
  setSnappingMode,
  setPointerMode,
  setSubstractVoids,
  setStoriesReaderMode,
  addDetailedModel,
  addModelWithInitialTexture,
  removeModelWithInitialTexture,
  addInitialLoadedModel,
  addNewModel,
  removeNewModel,
  setAzmEditorIsLoaded,
  setMapClickedProps,
  setMapProvider,
  setPdfViewerStatus,
  setPdftronOffset,
  setPdfCurrentPage,
  addPdfMarker,
  deletePdfMarker,
  setPdfMarkers,
  setPdfDrawDirection,
  hideCaplaElements,
  showCaplaElements,
  setSelectedCaplaElementType,
  setPdfSnapToLine,
  addFetchedRemoteScene,
  toggleDatasetTypeInScope,
  setDatasetTypesInScope,
  setFilesDownloadLimit,
  setSelectedIfcElement,
  setInitZoomOutWithEntities,
  setFirstRendered,
  setDisableRender,
  updateModelsVisibility,
  setPointerPosition,
  setGlobalTransparency,
} = viewer3DSlice.actions;

export default viewer3DSlice.reducer;

import {useSelector} from "react-redux";

import {Box, Paper, LinearProgress} from "@mui/material";

import ButtonLeftTopBar from "./ButtonLeftTopBar";
import TitleTopBar from "./TitleTopBar";
import ButtonRightTopBar from "./ButtonRightTopBar";
// import BoxAssistantNavBar from "./BoxAssistantNavBar";
//import BreadcrumbsScene from "./BreadcrumbsScene";

import usePageType from "../usePageType";
import useSceneModule from "../useSceneModule";

// import ButtonLeaveScenario from "Features/buildbox/components/ButtonLeaveScenario";
// import ButtonCancelBuildScenario from "Features/buildbox/components/ButtonCancelBuildScenario";
// import ButtonEditMeasurements from "Features/measurements/components/ButtonEditMeasurements";
import useIsFullscreen from "Features/ui/useIsFullscreen";
// import ButtonToggleEnable3d from "Features/viewer3D/components/ButtonToggleEnable3d";
// import ChipLastPackageInTopBar from "Features/annotatedPdf/components/ChipLastPackageInTopBar";
import SwitchOptimizedPdfFile from "./SwitchOptimizedPdfFile";
import TogglePdf3d from "./TogglePdf3d";
import ButtonOpenViewers from "./ButtonOpenViewers";
//import SelectorVersionForMeasurements from "Features/versions/components/SelectorVersionForMeasurements";

//import SelectorScopeInTopBar from "Features/scopes/components/SelectorScopeInTopBar";

import SectionPackagesInTopBar from "Features/overviewer/components/SectionPackagesInTopBar";
import SectionMainScopeInTopBar from "Features/overviewer/components/SectionMainScopeInTopBar";
//import SelectorPdfModelInAppTopBar from "Features/pdf/components/SelectorPdfModelInAppTopBar";
//import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

//import ButtonShrinkAppTopBar from "./ButtonShrinkAppTopBar";
//import SectionCallToActionInTopBar from "Features/overviewer/components/SectionCallToActionInTopBar";
import LayerPackagesInViewers from "./LayerPackagesInViewers";

export default function AppTopBarDesktop({caplaEditor, scene, viewer}) {
  // data

  //const fullscreen = useIsFullscreen();
  const pageType = usePageType();
  const sceneModule = useSceneModule();
  const isSyncing = useSelector((s) => s.sync.isSyncing);
  // const lastPackageId = useSelector((s) => s.packages.lastPackageId);
  const os = useSelector((s) => s.ui.openSections);
  const shrinkAppTopBar = useSelector((s) => s.navigation.shrinkAppTopBar);
  //const selectedPdfModel = useSelectedPdfModel();

  // helper

  let showAppTopBar = pageType !== "SCENE" && pageType !== "AUTH";

  // helper

  const colorMap = {
    HOME: {
      background: (theme) =>
        `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`,
      bgcolor: "common.white",
      color: "common.white",
    },
    SCENE: {
      background: "unset",
      bgcolor: "common.white",
      color: "text.secondary",
    },
    PROFILE: {
      background: "unset",
      bgcolor: "secondary.main",
      color: "common.white",
    },
    DEFAULT: {
      background: "unset",
      bgcolor: "common.white",
      color: "common.black",
    },
  };

  const {background, bgcolor, color} = colorMap[pageType]
    ? colorMap[pageType]
    : colorMap["DEFAULT"];

  return (
    <>
      {/* <LayerPackagesInViewers viewer={viewer} scene={scene} /> */}

      <Paper
        elevation={9}
        sx={{
          position: "absolute",
          right: "8px",
          left: "8px",
          top: "8px",
          zIndex: "40",

          background,
          bgcolor,
          color,
          display: showAppTopBar ? "flex" : "none",
          alignItems: "center",
          justifyContent: "space-between",
          p: 0,
          pl: 1,
          zIndex: 90,
          overflow: "hidden",
          borderWidth: 0,
          height: (theme) => theme.spacing(5),
        }}
      >
        {isSyncing && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              color: "common.white",
              background: (theme) =>
                `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`,
            }}
          >
            <LinearProgress
              color="inherit"
              sx={{height: "2px", ".MuiLinearProgress-bar": {height: "2px"}}}
            />
          </Box>
        )}
        <Box sx={{display: "flex", alignItems: "stretch"}}>
          {!viewer && pageType !== "HOME" && os.outlet && (
            <ButtonLeftTopBar caplaEditor={caplaEditor} />
          )}
          {pageType === "SCENE" && !viewer && (
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                minHeight: 0,
              }}
            >
              <SectionMainScopeInTopBar
                scene={scene}
                caplaEditor={caplaEditor}
                viewer={false}
                shrink={shrinkAppTopBar}
              />
              {!shrinkAppTopBar && (
                <Box sx={{ml: 1.5, display: "flex", alignItems: "stretch"}}>
                  <SectionPackagesInTopBar scene={scene} />
                </Box>
              )}
            </Box>
          )}
          {/* {!shrinkAppTopBar && pageType === "SCENE" && !viewer && (
            <SwitchOptimizedPdfFile />
          )} */}
          <TitleTopBar scene={scene} viewer={viewer} />
        </Box>

        <Box sx={{display: "flex", alignItems: "center"}}>
          {!viewer && <ButtonRightTopBar scene={scene} />}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              ml: 2,
            }}
          >
            {!viewer &&
              pageType !== "HOME" &&
              !["HOME", "LIBRARY", "PRICINGS", "RELATIONS", "MODULES"].includes(
                sceneModule
              ) &&
              (os.outlet ? (
                <ButtonOpenViewers />
              ) : (
                <TogglePdf3d caplaEditor={caplaEditor} />
              ))}
          </Box>
        </Box>
      </Paper>
    </>
  );
}

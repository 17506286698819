import {nanoid} from "@reduxjs/toolkit";
import remoteToStateArticlesGroups from "Features/articles/utils/remoteToStateArticlesGroups";
import {downloadFileService} from "Features/files/services";
import getStateScopeFromRemoteItem from "Features/scopes/utils/getStateScopeFromRemoteItem";

export function remoteModeltoClientModel(
  rm,
  sceneClientId,
  useRemoteUrlAsUrl = false // to get an url from shared remote scene, without having to download the models.
) {
  const transformation = rm.transformation ? JSON.parse(rm.transformation) : {};

  const center = transformation.center
    ? transformation.center
    : transformation.position;
  const width = transformation.width ? transformation.width : 1;
  const height = transformation.height ? transformation.height : 1;
  const depth = transformation.depth ? transformation.depth : 1;

  const resolution = rm.fileLowResoRemoteUrl ? "LR" : "RR";

  return {
    id: rm.clientId,
    remoteId: rm.remoteId,
    version: rm.version,
    type: rm.type,
    name: rm.name,
    description: rm.description,
    color: rm.color,
    previewUrl: rm.previewUrl,
    num: rm.num,
    opacity: rm.opacity,
    fileSize: rm.fileSize,
    fileName: rm.fileName,
    fileGltfSize: rm.fileGltfSize,
    fileJsonSize: rm.fileJsonSize,
    fileRemoteUrl: rm.fileRemoteUrl,
    fileGltfRemoteUrl: rm.fileGltfRemoteUrl,
    fileJsonRemoteUrl: rm.fileJsonRemoteUrl,
    fileLowResoSize: rm.fileLowResoSize,
    fileLowResoRemoteUrl: rm.fileLowResoRemoteUrl,
    fileHightResoRemoteUrl: rm.fileHightResoRemoteUrl,
    sceneClientId,
    url: useRemoteUrlAsUrl && rm.fileRemoteUrl,
    urlGltf: undefined,
    urlJson: undefined,
    urlLowReso: undefined,
    urlHightReso: undefined,
    position: transformation.position,
    rotation: transformation.rotation,
    mask: transformation.mask,
    parts: transformation.parts,
    hidden: false,
    center,
    width,
    height,
    depth,
    autoloading: false,
    caplaColors: false,
    resolution,
    keywords: rm.keywords,
    screenshot: rm.screenshot,
    shared: rm.shared,
    elements: rm.elements,
    measurementsData: rm.measurementsData,
    enabled: rm.enabled,
    excludeFromQties: rm.excludeFromQties,
    archived: rm.archived,
    measCount: rm.measCount,
    elementTypesGroupIds: rm.elementTypesGroupIds,
    ressourcesGroupIds: rm.ressourcesGroupIds,
    zones: rm.zones,
    elementsAnnotations: rm.elementsAnnotations,
    pdfScale: rm.pdfScale,
    fromPdf: rm.fromPdf,
    fromModel: rm.fromModel,
    ifcData: rm.ifcData,
    fromColoring: rm.fromColoring,
    d3: rm.d3,
    restrictedTypes: rm.restrictedTypes,
    revisionIds: rm.revisionIds,
    isHelper: rm.isHelper,
    helperIds: rm.helperIds,
    sectorId: rm.sectorId,
  };
}

/*
 * remote scene & models => scene & models in redux state.
 * - create clientId
 * - default values of properties
 * - parse json values
 */
export function sharedRemoteSceneToState(sharedRemoteScene) {
  const scene = {...sharedRemoteScene};
  let scope = sharedRemoteScene.scope;
  const models = [...(sharedRemoteScene.models ?? [])];
  const sceneNotes = [...(sharedRemoteScene.sceneNotes ?? [])];
  const issues = [...(sharedRemoteScene.sceneIssues ?? [])];
  const listings = [...(sharedRemoteScene.sceneListings ?? [])];
  const elementTypesGroups = [...(sharedRemoteScene.elementTypesGroups ?? [])];
  let articlesGroups = [...(sharedRemoteScene.articlesGroups ?? [])];
  //const ressourcesGroups = [...sharedRemoteScene.ressourcesGroups];

  const sceneNotesListings = [...sharedRemoteScene.sceneListings].filter(
    (l) => l.listingType === "noteset"
  );

  // scene
  delete scene.models;
  scene.data = JSON.parse(sharedRemoteScene.data);

  // scope
  scope = getStateScopeFromRemoteItem(scope);

  // articlesGroups
  articlesGroups = remoteToStateArticlesGroups(articlesGroups);

  // models
  let newModels = models.map((model) => {
    return remoteModeltoClientModel(model, scene.clientId, true); // true => url = remoteFileUrl
  });
  // remove duplicates
  //const newModelsIds = [...new Set(newModels.map((m) => m.id))];
  const duplicatesMap = {};
  const newModelsWithoutDuplicates = [];
  newModels.forEach((model, key) => {
    const isDuplicated = duplicatesMap[model.id];
    if (!isDuplicated) {
      newModelsWithoutDuplicates.push(model);
      duplicatesMap[model.id] = "ok";
    } else {
      newModelsWithoutDuplicates.push({
        ...model,
        id: model.id + model.remoteId,
        name: model.name + " (duplicate)",
      });
    }
  });
  //newModels = newModelsIds.map((id) => newModels.find((m) => m.id === id));

  // notes
  const notes = sceneNotes.map((sn) => ({
    listing: sceneNotesListings.find((l) => l.id === sn[0].listing),
    //items: sn.map((sn) => ({...sn, content: JSON.parse(sn.content)})),
    items: sn,
  }));

  return {
    scene,
    scope,
    models: newModelsWithoutDuplicates,
    notes,
    issues,
    listings,
    elementTypesGroups,
    articlesGroups,
  };
}

/*
 * Used when downloading a remote model to a local storage
 */

export function remoteSceneToState(remoteScene) {
  const scene = {...remoteScene};
  const models = [...remoteScene.models];

  // scene
  delete scene.models;
  delete scene.userMemberships;
  delete scene.members;

  if (remoteScene.data) scene.data = JSON.parse(remoteScene.data);

  // models
  let newModels = models.map((model) => {
    return remoteModeltoClientModel(model, scene.clientId);
  });

  const duplicatesMap = {};
  const newModelsWithoutDuplicates = [];
  newModels.forEach((model, key) => {
    const isDuplicated = duplicatesMap[model.id];
    if (!isDuplicated) {
      newModelsWithoutDuplicates.push(model);
      duplicatesMap[model.id] = "ok";
    } else {
      newModelsWithoutDuplicates.push({
        ...model,
        id: model.id + model.remoteId,
        name: "😱😱 " + model.name + " (duplicate)",
      });
    }
  });

  // we remove duplicates if any...(due to errors in sync)
  // let newModelsIds = newModels.map((m) => m.id);
  // newModelsIds = [...new Set(newModelsIds)];
  // const _newModels = newModelsIds.map((id) =>
  //   newModels.find((m) => m.id === id)
  // );

  scene.models = newModelsWithoutDuplicates;

  return scene;
}

export async function getSceneUpdatesFromRemote({scene, remoteScene}) {
  let file;
  let updates;

  // file

  if (remoteScene.imageUrl && scene.imageUrl !== remoteScene.imageUrl) {
    file = await downloadFileService({
      url: remoteScene.imageUrl,
      fileName: `bb-${nanoid()}.png`,
    });
  }

  // updates

  if (remoteScene.version > scene.version) updates = remoteScene;

  return {file, updates};
}

import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {fetchElementTypesGroups} from "Features/elementTypes/elementTypesSlice";
import {useParams} from "react-router-dom";
import useAccessToken from "Features/auth/useAccessToken";
import useIsStagingEnv from "./useIsStagingEnv";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function useInitFetchElementTypesGroups() {
  const isStagingEnv = useIsStagingEnv();
  const {sceneId} = useParams();
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const scope = useSelectedScope();
  const groupsIds = scope?.data?.typesGroupIds;

  console.log("debugafa scopeA", scope);

  useEffect(() => {
    if (accessToken && sceneId && isStagingEnv && groupsIds?.length > 0) {
      dispatch(fetchElementTypesGroups({sceneId, accessToken, groupsIds}));
    }
  }, [sceneId, accessToken, scope?.id]);
}

import {unwrapResult} from "@reduxjs/toolkit";
// import {Object3D, MathUtils} from "three";
// import {unwrap} from "idb";

// import store from "App/store";

// import ImageModel from "./ImageModel";
// import IfcModel from "./IfcModel";
//import IfcAsyncModel from "./IfcAsyncModel";
import ImageAsyncModel from "./ImageAsyncModel";
import MeasurementsModel from "./MeasurementsModel";
//import DxfModel from "./DxfModel";
import ObjModel from "./ObjModel";
import PdfModel from "./PdfModel";
import GLTFModel from "./GLTFModel";
import GLBModel from "./GLBModel";
import CaplaModel from "./CaplaModel";
import MarkersModel from "./MarkersModel";
import ZipModel from "./ZipModel";

import {getModelTypeFromFile} from "../utils/miscUtils";

import {
  createModel,
  updateModel,
  updateModels,
  // loadModel,
  // setConfiguring,
  // setConfigCenter,
  updateModelsStatus,
  updateModelsSyncStatus,
  updateScenesStatus,
  updateIfcStructures,
  updateModelsProgress,
  addResizedUrls,
  addDetailedModel,
  updateOptimStatus,
  addGltfUrl,
  addJsonUrl,
  setViewMode,
  // setMultiviews,
  setSelectedPdfModelId,
  setSelectedModelId,
  addNewModel,
  addInitialLoadedModel,
  addModelWithInitialTexture,
  // updateModelsVisibility,
} from "Features/viewer3D/viewer3DSlice";
import {
  setMeasurementsModelsInScope,
  // updateModelMeasurements,
} from "Features/measurements/measurementsSlice";
import {
  setSceneInitialLoadingStatus,
  updateScene,
} from "Features/scenes/scenesSlice";
import {downloadFileService} from "Features/files/services";
import {addToDialogLoadBimDataScope} from "Features/pdf/pdfSlice";
// import ProcedureFileModel from "Features/procedures/js/ProcedureFileModel";
import {updateStatusMapWith} from "Features/overviewer/overviewerSlice";

class Loader {
  constructor({
    caplaEditor,
    dispatch,
    scene,
    boxOnly = false,
    rawIfcModel = false,
    multiScenes = false, // used in space.
    storeFiles = false,
    gltfOnly = false,
    loadEdges = false,
    loadIfcStructure = true,
    autoplacement = false,
  }) {
    this.caplaEditor = caplaEditor;
    this.dispatch = dispatch;
    this.sceneClientId = scene?.clientId;
    this.scene = scene;
    this.boxOnly = boxOnly;
    this.rawIfcModel = rawIfcModel;
    this.multiScenes = multiScenes;
    this.storeFiles = storeFiles;
    this.gltfOnly = gltfOnly;
    this.loadIfcStructure = loadIfcStructure;
    this.loadEdges = loadEdges;
    this.autoplacement = autoplacement;
  }

  setRawIfcModel(value) {
    this.rawIfcModel = value;
  }
  setGltfOnly(value) {
    this.gltfOnly = value;
  }
  setStoreFiles(value) {
    this.storeFiles = value;
  }
  setLoadEdges(value) {
    this.loadEdges = value;
  }
  setAutoplacement(value) {
    this.autoplacement = value;
  }
  setLoadIfcStructure(value) {
    this.loadIfcStructure = value;
  }
  /*
   * Handlers
   */

  // used to track model loading progress
  handleProgress({model, progress}) {
    this.dispatch(updateModelsProgress({model, progress}));
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "loading", progress})
    );
  }

  // entity is loaded //
  handleLoad(props) {
    // for ifc model : props = {ifcStructure,updatedModel,ifcModelID}
    console.log("model loaded! ", props);
    if (props.ifcStructure) {
      this.dispatch(updateIfcStructures(props.ifcStructure));
      if (props.creation) {
        const heights = props.ifcStructure.ifcStoreys.map((s) => ({
          value: s.height,
          name: s.name,
        }));
        const validatedHeights = heights.filter(
          (h) => typeof h.value === "number"
        );
        this.addSceneHeights(validatedHeights);
      }
    }

    if (props.updatedModel) {
      this.dispatch(
        updateModel({updatedModel: props.updatedModel, sync: true})
      );
    }
  }

  addSceneHeights(hs) {
    const newScene = {...this.scene};
    let heights = [...hs];
    if (this.scene.data?.heights) {
      heights = [this.scene.data.heights, ...hs];
    }
    newScene.data = {...this.scene.data, heights};
    this.dispatch(updateScene({scene: newScene}));
  }

  /*
   * Loaders
   */

  // Load detail model in a scene. Check if the model already exists in the scene.
  // detail model = ifcModelEntity, imageModelEntity,...

  async loadDetailModel(model) {
    const entity = this.caplaEditor?.editor3d?.getEntity(model.id);
    switch (model.type) {
      case "PDF":
        // we do nothing...
        this.dispatch(addDetailedModel(model.id));
        break;
      case "MEASUREMENTS":
        entity.loadMeasurements();
        this.dispatch(addDetailedModel(model.id));
        break;
      case "IFC":
        // if we find a ifc_model_entity
        // => we enable the async_ifc_model
        // else => we load the ifc_model_entity + the subsets.
        const existingEntity = this.caplaEditor?.editor3d?.objectEntities.find(
          (e) => e?.model?.id === "model.id" && e?.type === "IFC_MODEL_ENTITY"
        );
        if (existingEntity) {
          entity.enable();
        } else {
          // start loading
          await this.loadIfcModelEntity(model, {
            autoplacement: false,
            gltfOnly: this.gltfOnly,
          });
        }
        this.dispatch(addDetailedModel(model.id));
        break;
      case "IMAGE":
        if (!entity) break;
        console.log("load detail image model", model);
        // we add the image
        //entity.loadImageEntity();
        //entity.loadImageBackgroundEntity();
        //entity.loadImagePartEntities();
        //entity.loadImageMaskEntities();
        //entity.loadMainImagePartEntity();
        // hide background
        //entity.hideBackground();
        entity.hideMainPart();
        // update detail
        this.dispatch(addDetailedModel(model.id));
        break;
    }
  }

  // load existing model entities which are already part of the caplaEditor?.
  // return true if ok / false if no existing entity.
  loadExistingEntity(model) {
    const editorEntity = this.caplaEditor?.editor3d?.getEntity(model.id);
    if (editorEntity) {
      this.dispatch(updateModelsStatus({model, status: "loading"}));
      if (editorEntity.enable) {
        editorEntity.enable();
      }
      // - display or hide
      if (model.hidden) editorEntity.hide();
      this.dispatch(updateModelsStatus({model, status: "loaded"}));
      return true;
    } else {
      return false;
    }
  }

  // create CAPLA model

  async createCaplaModel({name}) {
    const model = CaplaModel.computeInitialModel({
      name,
      sceneClientId: this.sceneClientId,
    });
    await this.dispatch(createModel({model}));
    const entity = new CaplaModel({
      model,
      editor3d: this.caplaEditor?.editor3d,
    });
    this.caplaEditor?.editor3d?.entities.push(entity);
    return model;
  }

  // create Measurements Model

  async createMeasurementsModel({
    name,
    fromModel,
    copyFrom,
    measurements,
    restrictedTypes,
    elementTypesGroupIds,
  }) {
    // from : pdfModel,copy :origin model
    const model = MeasurementsModel.computeInitialModel({
      name,
      sceneClientId: this.sceneClientId,
      fromModel,
      copyFrom,
      measurements,
      restrictedTypes,
      elementTypesGroupIds,
    });
    await this.dispatch(createModel({model}));
    const entity = new MeasurementsModel({
      model,
      editor3d: this.caplaEditor?.editor3d,
    });
    this.caplaEditor?.editor3d?.entities.push(entity);

    if (copyFrom || measurements) entity.loadMeasurements();
    console.log("[Loader] createMeasurementsModel", model);
    // this.loadAsyncModelsV2([model], false, false)
    return model;
  }

  async loadFiles(files) {
    for (let file of files) {
      const type = getModelTypeFromFile(file);
      await this.loadFile({file, type, openOnceLoaded: false});
    }
  }
  // load a model from a file.
  // the model entity is added to the scene.
  // in the options, we specify default name, ... in case the model is created from another model.
  async loadFile({file, type, options, openOnceLoaded = true}) {
    let model;
    if (type === "IFC") await this.loadIfcFile(file, openOnceLoaded);
    if (type === "IMAGE")
      model = await this.loadImageFile(file, openOnceLoaded, options);
    if (type === "PDF")
      model = await this.loadPdfFile(file, openOnceLoaded, options);
    if (type === "GLTF") await this.loadGLTFFile(file, openOnceLoaded);
    if (type === "ZIP") await this.loadZipFile(file, openOnceLoaded);
    if (type === "OBJ") await this.loadObjFile(file, openOnceLoaded);
    if (type === "GLB") await this.loadGlbFile(file, openOnceLoaded);

    return model;
  }

  async loadGLTFFile(file) {
    const gltfModel = new GLTFModel({
      editor3d: this.caplaEditor?.editor3d,
    });
    const url = URL.createObjectURL(file);
    await gltfModel.asyncCreateObject(url);
    gltfModel.loadIfcModelEntity();
  }

  // async loadProcedureFile(file) {
  //   const procedureModel = new ProcedureFileModel({
  //     editor3d: this.caplaEditor?.editor3d,
  //   });
  //   const url = URL.createObjectURL(file);
  //   const result = await procedureModel.getProcedure(url, file?.name);
  //   return result;
  // }

  loadObjFile(file) {
    const model = ObjModel.computeInitialModel({
      file,
      sceneClientId: this.sceneClientId,
    });
    const objEntity = new ObjModel({
      editor3d: this.caplaEditor?.editor3d,
      model,
    });
    objEntity.loadObj();
    //this.dispatch(createModel({model, file}));
  }

  async loadGlbFile(file) {
    const model = GLBModel.computeInitialModel({
      file,
      sceneClientId: this.sceneClientId,
    });
    const glbEntity = new GLBModel({
      editor3d: this.caplaEditor?.editor3d,
      model,
    });
    await glbEntity.asyncCreateObject();
    glbEntity.loadObject();
    this.dispatch(createModel({model, file}));
  }

  async loadZipFile(file) {
    const model = await ZipModel.computeInitialModel({
      file,
      sceneClientId: this.sceneClientId,
    });

    await this.dispatch(createModel({model, file}));
  }

  async loadPdfFile(file, openOnceLoaded, options) {
    console.log("loadPdfFile", file);
    const {model} = await PdfModel.computeInitialModel({
      file,
      sceneClientId: this.sceneClientId,
      name: options?.name,
      type: options?.type,
    });
    const result = await this.dispatch(
      createModel({model, file, fromScopeId: options?.fromScopeId})
    );

    this.dispatch(addNewModel(model.id));
    this.dispatch(addToDialogLoadBimDataScope(model.id));

    if (openOnceLoaded) {
      const m = unwrapResult(result);
      this.dispatch(setSelectedPdfModelId(m.model.id));
      this.dispatch(setSelectedModelId(m.model.id));
      this.dispatch(setViewMode("PDF"));
      this.caplaEditor.editor3d?.setViewMode("PDF");
      //this.dispatch(setMultiviews(true)); // to handle split view
    }

    const modelO = unwrapResult(result);
    return modelO.model;
  }

  // async loadIfcFile(file, openOnceLoaded) {
  //   const model = IfcAsyncModel.computeInitialModel({
  //     file,
  //     sceneClientId: this.sceneClientId,
  //   });
  //   const result = await this.dispatch(createModel({model, file})); // send the model to the state manager + store model in dB.
  //   const {model: newModel} = unwrapResult(result);
  //   await this.loadIfcModel(newModel, {
  //     autoplacement: this.autoplacement,
  //     updateLB: true,
  //     creation: true, // set to identify loading from a file import.
  //   }); // initial transformation to position the model in its site ref.

  //   // zoom out.
  //   this.caplaEditor.editor3d?.sceneEditor.zoomOut();

  //   // remove grid
  //   //this.caplaEditor.editor3d?.sceneEditor.hideGrid();

  //   // launch optim
  //   //this.optimizeIfcModel(newModel); // !! should pass the loadIfcModel result as parameter : pb with 3D placement otherwise.
  // }

  async loadImageFile(file, openOnceLoaded = true, options) {
    const {model, fileLR, fileHR} = await ImageAsyncModel.computeInitialModel({
      file,
      options,
      sceneClientId: this.sceneClientId,
    });
    console.log("loadImageFile307 options:", options);
    if (options?.fromModel?.zoneId) {
      this.caplaEditor?.editorPdf?.annotationsManager.linkImageModelToAnnotation(
        options.fromModel.zoneId,
        model.id
      );
    }
    await this.dispatch(createModel({model, file, fileLR, fileHR}));
    this.loadImageModel(model);
    return model;
  }

  /*
   * MAIN LOADER
   */

  // Load the models belonging to one scene, one by one. First display all the loading box.
  // mobile : if mobile, don't load the models in the 3D scene (need manual step)

  async loadInitialModel(model, options) {
    try {
      // STEP 1 : if the model is already loaded, we display it.
      let entityLoaded = false;
      entityLoaded = this.loadExistingEntity(model);
      // STEP 2 : load the entity if it doesn't exist.
      if (!entityLoaded) {
        // DEBUG : WE ONLY DISPLAY THE LOADING BOX WHEN OPENING THE BIMBOX.
        //if (model.autoloading && !mobile && !viewer) modelsToLoad.push(model);
        if (model.type === "IFC") await this.initialLoadingIfcModel(model);
        if (model.type === "IMAGE")
          await this.initialLoadingImageModel(model, options);
        if (model.type === "CAPLA") await this.loadCaplaModel(model);
        if (model.type === "MEASUREMENTS") {
          this.loadMeasurementsModel(model, options);
        }
        if (model.type === "MARKERS") this.loadMarkersModel(model);
      }
      this.dispatch(addInitialLoadedModel(model.id));
    } catch (e) {
      console.log("error", e);
    }
  }

  async loadSceneModels(
    sceneClientId,
    models,
    mobile,
    viewer,
    selectedMeasurementIds
  ) {
    let options = {};
    options.hideImageModels = true;
    if (!viewer) options.loadTexture = true;
    options.selectedMeasurementIds = selectedMeasurementIds;

    // const modelsToLoad = []; // models that need to be loaded because they don't exist in the scene.

    // const ifcModels = models.filter((m) => m.type === "IFC");
    // const otherModels = models.filter((m) => m.type !== "IFC");
    const imageModels = models.filter((m) => m.type === "IMAGE");

    console.log("imageModelsLL", imageModels, models);

    // START : we hide image models

    const hiddenImageModels = imageModels.map((m) => ({...m, hidden: true}));
    this.dispatch(
      updateModels({models: hiddenImageModels, options: {forceUpdate: true}})
    );

    // START

    this.dispatch(updateScenesStatus({sceneClientId, status: "loading"}));

    console.log("LOAD SCENE MODELS 098 - START");

    //PART 1 : load loading box or display the model.

    // if (ifcModels.length > 0) {
    //   for (let model of ifcModels) {
    //     await this.loadInitialModel(model);
    //   }
    // }

    for (let model of models) {
      // if (model.type !== "IMAGE" && model.type !== "MEASUREMENTS")
      //   this.loadInitialModel(model, options);
      if (model.type !== "IMAGE") this.loadInitialModel(model, options);
    }

    this.dispatch(
      setSceneInitialLoadingStatus({sceneClientId, status: "loaded"})
    );

    // // PART 2 : load the model
    // for (const model of modelsToLoad) {
    //   if (model.type === "IFC")
    //     await this.loadIfcModelEntity(model, {
    //       autoplacement: false,
    //       updateLB: false,
    //       mobile,
    //     });
    //   if (model.type === "IMAGE") await this.loadImageModel(model);
    // }

    // PART 3 : EDGE CASES
    if (!viewer) {
      const measurementsModelIds = models
        .filter((m) => m.type === "MEASUREMENTS")
        .map((m) => m.id);
      this.dispatch(setMeasurementsModelsInScope(measurementsModelIds));
    }

    // END

    this.dispatch(updateScenesStatus({sceneClientId, status: "loaded"}));

    console.log("LOAD SCENE MODELS 098 - END");
  }

  async loadAsyncModelsV2(models, mobile, viewer, selectedMeasurementIds) {
    if (models.length === 0) {
      this.dispatch(
        setSceneInitialLoadingStatus({
          sceneClientId: this.sceneClientId,
          status: "loaded",
        })
      );
      this.dispatch(
        updateScenesStatus({
          sceneClientId: this.sceneClientId,
          status: "loaded",
        })
      );
      return;
    }
    console.log("loading scene models 432", models);
    const scenesClientIds = [...new Set(models.map((m) => m.sceneClientId))];

    for (let sceneClientId of scenesClientIds) {
      let sceneModels = models.filter(
        (m) =>
          m.sceneClientId === sceneClientId &&
          !m.justCreated &&
          m.enabled &&
          ["MEASUREMENTS", "IFC", "IMAGE", "MARKERS"].includes(m.type) // justCreated = true when the model is created from a new file.
      );
      if (viewer) sceneModels = sceneModels.filter((m) => m.type !== "MARKERS");

      await this.loadSceneModels(
        sceneClientId,
        sceneModels,
        mobile,
        viewer,
        selectedMeasurementIds
      );
    }

    // update camera

    // => comment because of a bug when initializing the scene...
    //this.caplaEditor.editor3d?.sceneEditor.zoomOut();
  }

  // create the objects & display the loading box
  // async initialLoadingIfcModel(model) {
  //   // start loading
  //   this.caplaEditor.editor3d?.startLoading();
  //   this.dispatch(updateModelsStatus({model, status: "loading"}));
  //   this.dispatch(
  //     updateModelsSyncStatus({
  //       modelId: model.id,
  //       status: "loading",
  //       progress: 0,
  //     })
  //   );
  //   // we create the entity since it doesn't exist, and we add it to the editor3d entities.
  //   const entity = new IfcAsyncModel({
  //     model,
  //     ifcLoader: this.caplaEditor.editor3d?.ifcLoader,
  //     onModelChange: this.caplaEditor.editor3d?.onModelChange,
  //     animator: this.caplaEditor.editor3d?.animator,
  //     onProgress: (progress) => this.handleProgress({model, progress}),
  //     onLoad: (props) => this.handleLoad(props),
  //     scene: this.caplaEditor.editor3d?.scene,
  //     objectEntities: this.caplaEditor?.editor3d?.objectEntities,
  //     editor3d: this.editor3d,
  //   });
  //   this.caplaEditor?.editor3d?.entities.push(entity);

  //   // we load the loadingbox or the gltf model entity.

  //   await entity.loadLoadingBox();

  //   // we load the loadingbox only

  //   // if (model.urlGltf) {
  //   //   await entity.loadGltfIfcModelEntity();
  //   // } else {
  //   //   await entity.loadLoadingBox();
  //   // }

  //   // we hide the entity if necessary
  //   if (model.hidden) entity.hide();

  //   // Stop loading
  //   this.dispatch(updateModelsStatus({model, status: "loaded"}));
  //   this.dispatch(
  //     updateModelsSyncStatus({
  //       modelId: model.id,
  //       status: "loaded",
  //     })
  //   );
  //   this.caplaEditor.editor3d?.stopLoading();
  // }

  // loader when we have the model object, like demo models.
  // load = display in 3D scene.
  // options = {autoplacement, updateLB}

  // async loadIfcModel(model, options) {
  //   try {
  //     // options
  //     const autoplacement = options?.autoplacement; // autoplacement : position to site (0,0)
  //     const updateLB = options?.updateLB; // update Loading Box size once the model is loaded.
  //     const autoloading = model.autoloading; // autoload the ifc model entity. Otherwise, load only the loading box.
  //     const mobile = options?.mobile; // if false, models are loaded even with autoloading = false.
  //     const creation = options?.creation; // if true, model is loaded for the 1st time => used to update scene heights.
  //     // start loading
  //     this.caplaEditor.editor3d?.startLoading();
  //     this.dispatch(updateModelsStatus({model, status: "loading"}));
  //     this.dispatch(
  //       updateModelsSyncStatus({
  //         modelId: model.id,
  //         status: "loading",
  //         progress: 0,
  //       })
  //     );

  //     // we create the entity since it doesn't exist, and we add it to the editor3d entities.
  //     const entity = new IfcAsyncModel({
  //       model,
  //       ifcLoader: this.caplaEditor.editor3d?.ifcLoader,
  //       onModelChange: this.caplaEditor.editor3d?.onModelChange,
  //       animator: this.caplaEditor.editor3d?.animator,
  //       onProgress: (progress) => this.handleProgress({model, progress}),
  //       onLoad: (props) => this.handleLoad(props),
  //       scene: this.caplaEditor.editor3d?.scene,
  //       objectEntities: this.caplaEditor?.editor3d?.objectEntities,
  //       editor3d: this.editor3d,
  //       creation,
  //     });
  //     this.caplaEditor?.editor3d?.entities.push(entity);

  //     // we add the loadingBox.
  //     //entity.loadLoadingBox();
  //     // - load ifc geom
  //     if ((!this.boxOnly && autoloading) || !mobile) {
  //       await entity.loadIfcModelEntity({
  //         autoplacement,
  //         rawIfcModel: this.rawIfcModel,
  //         loadIfcStructure: this.loadIfcStructure,
  //         loadEdges: this.loadEdges,
  //       });
  //       this.dispatch(addDetailedModel(model.id));
  //       // load subsets
  //       if (!this.rawIfcModel) entity.loadIfcSubsets();
  //       if (!model.caplaColors) entity.ifcSubsets?.disable();
  //       if (updateLB) entity.fitLoadingBoxSimple();
  //       entity.hideLoadingBox();
  //       this.dispatch(
  //         updateModel({
  //           updatedModel: {...entity.model, displayLoadingBox: false},
  //         })
  //       );
  //     }
  //     // - display or hide
  //     if (model.hidden) entity.hide();

  //     // - stop loading
  //     this.dispatch(updateModelsStatus({model, status: "loaded"}));
  //     this.dispatch(
  //       updateModelsSyncStatus({
  //         modelId: model.id,
  //         status: "loaded",
  //         progress: 1,
  //       })
  //     );
  //     this.caplaEditor.editor3d?.stopLoading();

  //     // camera focus
  //     // if (entity.ifcModelEntity?.object.object) {
  //     //   this.caplaEditor.editor3d?.cameras.fitCameraTo({
  //     //     controls: this.caplaEditor.editor3d?.controls.activeControls,
  //     //     objects: [entity.ifcModelEntity.object],
  //     //   });
  //     // }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // called when the models are loaded from indexed db.

  // async loadIfcModelEntity(model, options) {
  //   // model entity
  //   const entity = this.caplaEditor?.editor3d?.getEntity(model.id);

  //   if (!entity) return;

  //   const loadGltf = Boolean(model.urlGltf);

  //   // options
  //   const autoplacement = options.autoplacement;
  //   // start
  //   this.caplaEditor.editor3d?.startLoading();

  //   this.dispatch(updateModelsStatus({model, status: "loading"}));
  //   this.dispatch(
  //     updateModelsSyncStatus({
  //       modelId: model.id,
  //       status: "loading",
  //     })
  //   );
  //   // load
  //   if (loadGltf) {
  //     await entity.loadGltfIfcModelEntity({
  //       gltfOnly: options.gltfOnly,
  //       loadEdges: this.loadEdges,
  //     });
  //   } else {
  //     await entity.loadIfcModelEntity({
  //       autoplacement,
  //       rawIfcModel: this.rawIfcModel,
  //       loadEdges: this.loadEdges,
  //     });
  //     // load subsets
  //     if (!this.rawIfcModel) entity.loadIfcSubsets();
  //     if (!model.caplaColors) entity.ifcSubsets?.disable();
  //     // display subsets or ifcModel
  //     if (model.caplaColors) entity.switchColorsTo("CAPLA");
  //   }

  //   // hide loadingbox
  //   entity.hideLoadingBox();

  //   // update state
  //   const updatedModel = {...entity.model, displayLoadingBox: false};
  //   console.log("LOAD DETAIL MODEL", updatedModel);
  //   this.dispatch(updateModel({updatedModel, sync: false})); // sync false to avoid update when loading model from remote.
  //   // we hide the entity if necessary
  //   if (model.hidden) entity.hide();
  //   // stop
  //   this.dispatch(updateModelsStatus({model, status: "loaded"}));
  //   this.dispatch(
  //     updateModelsSyncStatus({
  //       modelId: model.id,
  //       status: "loaded",
  //     })
  //   );
  //   this.dispatch(addDetailedModel(model.id));
  //   this.caplaEditor.editor3d?.stopLoading();
  //   // camera focus
  //   // this.caplaEditor.editor3d?.cameras.fitCameraTo({
  //   //   controls: this.caplaEditor.editor3d?.controls.activeControls,
  //   //   objects: [entity.ifcModelEntity.object],
  //   // });
  // }

  async optimizeIfcModel(model, createJson) {
    // handlers
    const handleProgress = (p) => {
      console.log("progress json creator", p);
      this.dispatch(
        updateOptimStatus({
          modelId: model.id,
          jsonStatus: "loading",
          jsonProgress: p,
        })
      );
    };
    // gltf
    this.dispatch(
      updateOptimStatus({modelId: model.id, gltfStatus: "loading"})
    );
    const fileGltf =
      await this.caplaEditor.editor3d?.gltfExporter.createGltfFile(model);
    const result = await this.dispatch(addGltfUrl({model, fileGltf}));
    const _model = unwrapResult(result);
    // json
    if (createJson) {
      this.dispatch(
        updateOptimStatus({modelId: model.id, jsonStatus: "loading"})
      );
      const fileJson =
        await this.caplaEditor.editor3d?.ifcExport.createJsonFile({
          model: _model,
          onProgress: handleProgress,
        });
      await this.dispatch(addJsonUrl({model: _model, fileJson}));
    }

    //end
    this.dispatch(
      updateOptimStatus({
        modelId: model.id,
        gltfStatus: "loaded",
        jsonStatus: "loaded",
      })
    );
  }

  //
  async initialLoadingImageModel(model, options) {
    console.log("initialImageLoading", model, options);
    const loadTexture = options?.loadTexture;
    try {
      // start
      this.dispatch(
        updateModelsSyncStatus({modelId: model.id, status: "loading"})
      );
      // we create the entity since it doesn't exist, and we add it to the editor3d entities.
      const entity = new ImageAsyncModel({
        model,
        onModelChange: this.caplaEditor.editor3d?.onModelChange,
        animator: this.caplaEditor.editor3d?.animator,
        scene: this.caplaEditor.editor3d?.scene,
        objectEntities: this.caplaEditor?.editor3d?.objectEntities,
        editor3d: this.caplaEditor?.editor3d,
      });
      this.caplaEditor?.editor3d?.entities.push(entity);
      // load image
      console.log("initialImageLoading - 1");
      // do not load vertical image directly nor horizontal
      if (true) {
        if (loadTexture) {
          await entity.loadTexturesAsync();
          this.dispatch(addModelWithInitialTexture(model.id));
        }
        console.log("initialImageLoading - 2");
        entity.loadImageEntity();
        entity.loadMainImagePartEntity(); // image to be cut, displayed when selected.
      }

      // v we do not show the image v
      // if (!model.hidden) entity.show();
      // // hide vertical image (rule to avoid to many images loaded)
      // if (Math.abs(model.rotation.x + Math.PI / 2) >= 0.01) {
      //   entity.hide();
      //   this.dispatch(
      //     updateModel({updatedModel: {...model, hidden: true}, sync: false})
      //   );
      // }

      // enabled ?
      if (!model.enabled) {
        if (entity?.hide) entity.hide();
        if (entity?.disable) entity.disable();
      }

      // show / hide
      if (options.hideImageModels) entity.hide();
      // stop
      this.dispatch(
        updateModelsSyncStatus({modelId: model.id, status: "loaded"})
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  async loadImageModelInitialTexture(modelId) {
    const entity = this.caplaEditor?.editor3d?.getEntity(modelId);
    if (!entity) return;
    entity.loadInitialTexture();
    this.dispatch(addModelWithInitialTexture(modelId));
    entity.show();
    // this.dispatch(
    //   updateModel({updatedModel: {id: modelId, hidden: false}, sync: false})
    // );
  }

  // loader when we have the model object, like demo models.
  async loadImageModel(model) {
    console.log("loading image model", model);
    this.dispatch(updateModelsStatus({model, status: "loading"}));
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "loading"})
    );
    // we create the entity since it doesn't exist, and we add it to the editor3d entities.
    const entity = new ImageAsyncModel({
      model,
      onModelChange: this.caplaEditor.editor3d?.onModelChange,
      animator: this.caplaEditor.editor3d?.animator,
      scene: this.caplaEditor.editor3d?.scene,
      objectEntities: this.caplaEditor?.editor3d?.objectEntities,
      editor3d: this.caplaEditor?.editor3d,
    });
    this.caplaEditor?.editor3d?.entities.push(entity);
    // we add the image
    if (!model.d3) {
      await entity.loadTexturesAsync();
      this.dispatch(addModelWithInitialTexture(model.id)); // added to toggle visibility from shortcut
      entity.loadImageEntity();
      //entity.loadImageBackgroundEntity();
      entity.loadMainImagePartEntity();
      //entity.loadImagePartEntities();
      //entity.loadImageMaskEntities();

      // hide background
      //entity.hideBackground();
      entity.hideMainPart();
      // init display
      entity.initMainSceneDisplay();
      // hide element is visible = off
      if (model.hidden) entity.hide();
    }

    // end
    this.dispatch(addDetailedModel(model.id));
    this.dispatch(updateModelsStatus({model, status: "loaded"}));
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "loaded"})
    );
    this.caplaEditor.editor3d?.stopLoading();
    //
    this.dispatch(
      updateStatusMapWith({statusKey: "IN_3D_DETAILED", newIds: [model.id]})
    );
  }

  // add low reso and hight reso files

  async loadResizedImages(model) {
    const entity = this.caplaEditor?.editor3d?.getEntity(model.id);
    const {fileHR, fileLR} = await entity.createResizedImages();
    const urlLowReso = URL.createObjectURL(fileLR);
    const urlHightReso = URL.createObjectURL(fileHR);
    const newModel = {...model, urlLowReso, urlHightReso};
    entity.setModel(newModel);

    this.dispatch(addResizedUrls({model: newModel, fileHR, fileLR}));
  }

  loadCaplaModel(model) {
    const entity = new CaplaModel({
      model,
      editor3d: this.caplaEditor?.editor3d,
    });
    this.caplaEditor?.editor3d?.entities.push(entity);
    entity.loadElements();
    this.dispatch(addDetailedModel(model.id));
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "loaded"})
    );
    console.log("CAPLA model loaded 314", model);
  }

  loadMeasurementsModel(model) {
    // , options) {
    console.log(
      "debugAFA2406 Loading meshes",
      model.name,
      model.measurementsData?.measurements?.length,
      "measurements",
      model.measurementsData?.measurements?.[0]
    );
    // 3D
    const entity = new MeasurementsModel({
      model,
      editor3d: this.caplaEditor?.editor3d,
    });
    this.caplaEditor?.editor3d?.entities.push(entity);
    entity.loadMeasurements();
    // entity.drawVoids();
    this.dispatch(addDetailedModel(model.id));
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "loaded"})
    );
    this.dispatch(
      updateStatusMapWith({statusKey: "IN_3D_DETAILED", newIds: [model.id]})
    );
    // // enabled ?
    // if (!model.enabled) {
    //   if (entity?.hide) entity.hide();
    //   if (entity?.disable) entity.disable();
    // }
    // // else {
    //   //   if (entity?.show && !model.hidden) entity.show();
    //   //   if (entity?.enable) entity.enable();
    //   // }

    // const selected = new Set(options.selectedMeasurementIds);
    // if (selected.size > 0) {
    //   entity.measurements.forEach((m) => {
    //     if (selected.has(m.entityID)) {
    //       this.caplaEditor.editor3d.sceneEditor.selectElementEntity({
    //         modelId: m.modelId,
    //         entityID: m.entityID,
    //         keepSelection: true,
    //         fromPdf: true,
    //       });
    //     }
    //   })
    // }
  }

  loadMarkersModel(model) {
    const entity = new MarkersModel({
      model,
      editor3d: this.caplaEditor.editor3d,
    });
    this.caplaEditor?.editor3d?.entities.push(entity);
    entity.loadMarkers();
    //if (entity?.hide) entity.hide();
    this.dispatch(addDetailedModel(model.id));
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "loaded"})
    );
    this.dispatch(
      updateStatusMapWith({statusKey: "IN_3D_DETAILED", newIds: [model.id]})
    );
  }

  /*
   * LOAD FILE & UPDATE MODEL
   * used to avoid massive loading when opening the box.
   */

  async loadModelFiles(model) {
    let file, fileJson, fileGltf;

    let start = performance.now();

    const handleFileDownloadProgress = (progress, modelId) => {
      const newProgress = {
        modelId,
        downloadingProgress: progress,
        status: "downloading",
      };
      this.dispatch(updateModelsSyncStatus(newProgress));
    };

    if (model.fileGltfRemoteUrl && !model.urlGltf) {
      this.dispatch(
        updateModelsSyncStatus({modelId: model.id, status: "downloading"})
      );
      fileGltf = await downloadFileService({
        url: model.fileGltfRemoteUrl,
        fileName: `${model.name}.gltf`,
        onProgress: (progress) =>
          handleFileDownloadProgress(progress, model.id),
      });
      if (!this.gltfOnly && model.fileJsonRemoteUrl && !model.urlJson) {
        fileJson = await downloadFileService({
          url: model.fileGltfRemoteJson,
          fileName: `${model.name}.json`,
          onProgress: (progress) =>
            handleFileDownloadProgress(progress, model.id),
        });
      }
    } else if (model.fileRemoteUrl && !model.url) {
      //if (!model.fileClientId) {
      this.dispatch(
        updateModelsSyncStatus({modelId: model.id, status: "downloading"})
      );
      file = await downloadFileService({
        url: model.fileRemoteUrl,
        fileName: model.name,
        onProgress: (progress) =>
          handleFileDownloadProgress(progress, model.id),
      });
    }
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "downloaded"})
    );
    console.log("downloaded files before storage :", file, fileGltf, fileJson);

    console.log("Downloading the file took", performance.now() - start, "ms");

    console.log("update this model with the previous file :", model);

    start = performance.now();

    // UPDATE MODEL STATE

    const result = await this.dispatch(
      updateModel({
        updatedModel: model,
        file,
        fileGltf,
        fileJson,
        fromRemote: true,
        storeFiles: this.storeFiles,
      })
    );

    console.log("Updating the model took", performance.now() - start, "ms");

    const updatedModel = unwrapResult(result);
    const entity = this.caplaEditor?.editor3d?.getEntity(model.id);
    if (entity?.resetModelFromState) entity.resetModelFromState(updatedModel);

    console.log("ResetModel the model took", performance.now() - start, "ms");

    // console.trace();

    return updatedModel;
  }

  async loadModelFilesAndLoadDetail(model) {
    this.dispatch(updateModelsStatus({model, status: "loading"}));

    // download file if it doesn't exist
    // update 28/07/2022 : load files if url = null (=> for remote files, we do not download anything)
    let newModel = {...model};
    if (!newModel.url) newModel = await this.loadModelFiles(model);
    console.log("newModel512", newModel);

    // load file if it doesn't exist
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "loading"})
    );
    await this.loadDetailModel(newModel);

    // end
    this.dispatch(updateModelsStatus({model: newModel, status: "loaded"}));
    this.dispatch(
      updateModelsSyncStatus({modelId: model.id, status: "loaded"})
    );
  }

  async updateImageModel({updatedModel, file}) {
    const entity = this.caplaEditor?.editor3d?.getEntity(updatedModel.id);
    console.log("UPDATE IMAGE MODEL 41, entity", entity);
    entity?.updateImageModel({...updatedModel, file});
    // update state model is done by the entity onModelChange. // !NOT ANYMORE...
    const model = {...updatedModel};
    if (file) model.fileSize = file.size;
    await this.dispatch(updateModel({updatedModel: model, file}));
  }

  // PDF & CAPLA models

  loadCaplaModelsFromPdf(models) {
    console.log("LOAD CAPLA MODELS", models);
    if (models) {
      models.forEach((model) => {
        const validModel = {...model, sceneClientId: this.sceneClientId};
        const entity = new CaplaModel({
          model: validModel,
          editor3d: this.caplaEditor.editor3d,
        });
        this.caplaEditor?.editor3d?.entities.push(entity);
        this.dispatch(createModel({model: validModel}));
      });
    }
  }
}
export default Loader;

import {useEffect} from "react";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {useSearchParams} from "react-router-dom";

export default function useAutoSetScopeParam() {
  // effect - set searchParams
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedScope = useSelectedScope();

  const scopeParam = searchParams.get("scope");

  useEffect(() => {
    if (selectedScope?.id) {
      setSearchParams({...(searchParams ?? {}), scope: selectedScope?.id});
    } else if (scopeParam === "undefined") {
      const newSearchParams = {...searchParams};
      delete newSearchParams.scope;
      setSearchParams(newSearchParams);
    }
  }, [selectedScope?.id, scopeParam]);
}

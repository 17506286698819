import {useState, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {setSelectedSceneModule} from "../onboardingSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";

import {Box, Typography} from "@mui/material";

import SectionImportElementTypeGroupFromExcel from "Features/boxhome/components/SectionImportElementTypeGroupFromExcel";
import ButtonAddDefaultElementTypes from "Features/boxhome/components/ButtonAddDefaultElementTypes";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import DialogCreatePackage from "Features/elementPackages/components/DialogCreatePackage";
import {setSelectedElementTypesGroupProxyInEditMeasurements} from "Features/elementTypes/elementTypesSlice";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import SectionEditMeasurementsInLeftPanelV2 from "Features/leftPanel/components/SectionEditMeasurementsInLeftPanelV2";
import CardCreateElementTypeGroupWithName from "Features/elementTypes/components/CardCreateElementTypeGroupWithName";
import CardCreateElementTypeGroupGeneric from "Features/elementTypes/components/CardCreateElementTypeGroupGeneric";

export default function SectionOnboardingInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const sceneId = scene?.id ? scene.id : scene?.clientId;
  const isStagingEnv = useIsStagingEnv();
  const navigate = useNavigate();

  // string

  const elementTypesS = "Liste des éléments / ouvrages à repérer";

  // data

  const selectedSceneModule = useSelector(
    (s) => s.onboarding.selectedSceneModule
  );
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  const elementTypes = useElementTypesBySceneProxy(scene);

  const groupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );

  const openCreatePackage = useSelector(
    (s) => s.packages.openDialogCreatePackage
  );

  // state

  const [tempTypes, setTempTypes] = useState(null);

  // helpers

  let mode = "DEFAULT";
  if (!selectedPdfModelId) {
    mode = "NO_INPUT";
  } else if (elementTypes?.length === 0 && !groupProxy?.id) {
    // used when creating the group for the first time.
    mode = "IMPORT";
  }

  // handlers

  function handleSelectedSceneModuleChange(sceneModule) {
    console.log("sceneModule", sceneModule);
    dispatch(setSelectedSceneModule(sceneModule));
  }

  function handleTempTypesChange(types) {
    setTempTypes(types);
  }

  function handleGroupProxyCreated(groupProxy) {
    dispatch(setSelectedElementTypesGroupProxyInEditMeasurements(groupProxy));
  }

  function handlePackageCreated(newPackage) {
    const modelId = newPackage?.id;
    dispatch(setSelectedMeasurementsModelId(modelId));
    let url = `/bimboxes/${sceneId}/${modelId}/measurements`;
    navigate(url);
  }

  return (
    <Box
      sx={{
        width: 1,
        flexGrow: 1,
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyItems: "stretch",
        flexDirection: "column",
      }}
    >
      {openCreatePackage && (
        <DialogCreatePackage
          scene={scene}
          caplaEditor={caplaEditor}
          initialRestrictedGroupIds={[groupProxy?.id]}
          onPackageCreated={handlePackageCreated}
        />
      )}

      {mode === "NO_INPUT" && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            width: 1,
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "stretch",
          }}
        >
          <Typography color="grey.400">{elementTypesS}</Typography>
        </Box>
      )}
      {mode === "IMPORT" && (
        <Box sx={{p: 1, width: 1, flexGrow: 1, bgcolor: "common.white"}}>
          <Box
            sx={{
              display: "flex",
              width: 1,
              alignItems: "center",
              justifyContent: "center",
              py: 2,
            }}
          >
            <Typography>{elementTypesS}</Typography>
          </Box>
          <Box sx={{p: 1, mb: 1}}>
            <CardCreateElementTypeGroupWithName scene={scene} />
          </Box>
          <Box sx={{p: 1, mb: 1}}>
            <CardCreateElementTypeGroupGeneric
              scene={scene}
              onGroupProxyCreated={handleGroupProxyCreated}
            />
          </Box>
          {/* <ButtonAddDefaultElementTypes
            scene={scene}
            onAddDefault={handleAddDefault}
          /> */}
          <Box sx={{p: 1}}>
            <Box sx={{bgcolor: "background.default", borderRadius: "4px"}}>
              <SectionImportElementTypeGroupFromExcel
                scene={scene}
                onNewTypes={handleTempTypesChange}
              />
            </Box>
          </Box>
        </Box>
      )}
      {mode === "DEFAULT" && (
        <Box sx={{width: 1}}>
          <SectionEditMeasurementsInLeftPanelV2
            scene={scene}
            caplaEditor={caplaEditor}
          />
        </Box>
      )}
    </Box>
  );
}

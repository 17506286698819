import React from "react";

import {Box, Typography, IconButton} from "@mui/material";
import {
  CheckBox as Checked,
  CheckBoxOutlineBlank as UnChecked,
  ArrowDropDown as Down,
  ArrowDropUp as Up,
} from "@mui/icons-material";

import getQtyWithDec from "../utils/getQtyWithDec";
import getStyleFromItem from "../utils/getStyleFromItem";

export default function ListItemArticle({
  article,
  qty,
  showCheck,
  selected,
  showChildren,
  onShowChildrenChange,
  enableShowChildren,
}) {
  // helper

  let articleLabel = article.name;
  if (article.num) articleLabel = `${article.num} • ${articleLabel}`;

  // helper

  const qtyWithDec = getQtyWithDec(qty, article?.dec);
  let articleQty = article.hardcodedQty ?? qtyWithDec;
  if (!articleQty && article.refQty) articleQty = article.refQty;

  // helper - qtyToCheck

  const qtyToCheck =
    (articleQty < 0 || articleQty > 0) &&
    article.refQty &&
    Math.abs(articleQty - article.refQty) / articleQty > 0.1;

  const qtyToCheckMinor =
    (articleQty < 0 || articleQty > 0) &&
    article.refQty &&
    Math.abs(articleQty - article.refQty) / articleQty > 0.05;

  let qtyLabel = "";
  if (typeof articleQty === "number") {
    qtyLabel = `${articleQty} ${article.unit}`;
  } else if (article.unit) {
    qtyLabel = article.unit;
  }

  const noQty = typeof articleQty !== "number";

  const qtyLabelInRed = (article.unit && noQty) || qtyToCheck;
  const qtyLabelInOrange = qtyToCheckMinor;
  const qtyLabelInItalic = article.hardcodedQty;
  const qtyLabelInLightGrey = Boolean(
    article.refQty && !qty && !article.hardcodedQty
  );
  const qtyLabelInBold = qtyToCheck;

  const showMainPdfLinkIndicator = article?.mainPdfLink;

  // helper style

  const {bg, color, bold, alignToRight, underline, italic} =
    getStyleFromItem(article);

  // helper

  const hasPois = article?.pois?.length > 0;

  // handler

  function handleExpandClick(e) {
    e.stopPropagation();
    e.preventDefault();
    if (onShowChildrenChange) onShowChildrenChange(!showChildren);
  }
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        bgcolor: bg ?? "unset",
        color: color ?? "unset",
        ...(selected && {
          borderLeft: (theme) => `1px solid ${theme.palette.primary.main}`,
        }),
        ...(hasPois && {
          borderRight: (theme) => `2px solid ${theme.palette.primary.main}`,
        }),
      }}
    >
      {showCheck && (
        <Box
          sx={{
            color: selected ? "primary.main" : "grey.500",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: 1,
          }}
        >
          {selected ? (
            <Checked fontSize="small" />
          ) : (
            <UnChecked fontSize="small" />
          )}
        </Box>
      )}
      <Box
        sx={{
          flex: 1,
          minWidth: 0,
          display: "flex",
          justifyContent: alignToRight ? "flex-end" : "unset",
          alignItems: "center",
          pr: 1,
        }}
      >
        <Typography
          noWrap
          sx={{
            fontSize: 12,
            ...(underline && {textDecoration: "underline"}),
            ...(bold && {fontWeight: "bold"}),
            ...(italic && {fontStyle: "italic"}),
            ...(article.isTemplate && {color: "primary.main"}),
            ...(article.fromTemplate && {color: "primary.light"}),
          }}
        >
          {`${articleLabel}${showMainPdfLinkIndicator ? "." : ""}`}
        </Typography>
      </Box>
      <Box>
        {(!selected || !enableShowChildren) && !article.isNomenclature && (
          <Typography
            //color="text.secondary"
            sx={{
              fontSize: 12,
              color: "text.secondary",
              ...(qtyLabelInOrange && {color: "warning.main"}),
              ...(qtyLabelInRed && {color: "error.main"}),
              ...(qtyLabelInLightGrey && {color: "grey.400"}),
              ...(qtyLabelInItalic && {fontStyle: "italic"}),
              ...(qtyLabelInBold && {fontWeight: "bold"}),
            }}
          >
            {qtyLabel}
          </Typography>
        )}
        {selected && enableShowChildren && (
          <Box
            sx={{
              color: "primary.main",
              display: "flex",
              alignItems: "center",
              maxHeight: "20px",
            }}
          >
            <IconButton color="inherit" onClick={handleExpandClick}>
              {showChildren ? <Up /> : <Down />}
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}

import {
  Mesh,
  Vector3,
  Box3,
  BoxGeometry,
  MeshBasicMaterial,
  Color,
  // TOUCH,
  MeshLambertMaterial,
  FrontSide,
  DoubleSide,
  Raycaster,
} from "three";
import theme from "Styles/theme";

import {SelectionBox} from "three/examples/jsm/interactive/SelectionBox.js";
import SelectionHelper from "./SelectionHelper";

import {
  setGrid,
  setOptimIsEnabled,
  setShowEdge,
  setInitZoomOutWithEntities,
  // setCutCam1,
  updateModel,
  updateModelsVisibility,
} from "Features/viewer3D/viewer3DSlice";

import MarkerTemp from "./MarkerTemp";
// import Vector from "./Vector";
import {
  setSelectedMeasurementIds,
  updateSelectedMeasurementIds,
  setFiltersAppliedToMeasurementsIn3DAt,
  setFilters,
} from "Features/measurements/measurementsSlice";
// import {updateModels} from "Features/viewer3D/viewer3DSlice";

import store from "App/store";

const material = new MeshBasicMaterial({
  color: new Color(theme.palette.primary.main),
});

export default class SceneEditor {
  edges; // true if showed
  voids;
  globalTransparency;
  state; // use to save temp state.
  optimized; // use to know if we should trigger optim.
  optimIsEnabled;

  pointerMode; // true : the pointer mode is on.

  selectedMarker; // {markerId,markersModelId} => used to selected / unselect markers from markersModels
  selectedIfcElement; // {modelId,expressID}

  viewBySector; // sectorId, used to arrange view by sector : zones + camera. cf setViewBySector

  constructor({editor}) {
    this.editor = editor;

    this.raycaster = new Raycaster();
    this.raycaster.layers.set(1);

    this.tempObject = this.createTempObject();
    this.optimized = false;

    this.markerTemp = new MarkerTemp({
      editor,
      dashed: true,
      color: theme.palette.primary.flash,
      closable: true,
    });
    this.markerTemp1 = new MarkerTemp({
      editor,
      color: theme.palette.primary.flash,
    });
    this.markerTemp2 = new MarkerTemp({
      editor,
      color: theme.palette.common.greenFlash,
    });
    this.markers = [this.markerTemp, this.markerTemp1, this.markerTemp2];

    this.hideMarkers();

    this.keepMainPart = false;

    this.selectedMarker = null;
    this.selectedIfcElement = {};

    this.pickedElement = {}; // {modelId,entityID}
    this.selectedElement = {};
    this.selectedElements = [];

    // selection box
    this.initSelectionBox = this.initSelectionBox.bind(this);

    // element types materials

    this.elementTypesMaterials = {};
    this.globalTransparency = true;

    this.edges = true;
    this.voids = true;
  }

  // init selectionBox

  updateSelectedElementsWithEntities(entities) {
    let newSelection = [...this.selectedElements];
    entities.forEach(({modelId, entityID}) => {
      const exists = this.selectedElements.find(
        (e) => e.modelId === modelId && e.entityID === entityID
      );
      if (exists) {
        newSelection = newSelection.filter(
          (e) => e.modelId !== modelId || e.entityID !== entityID
        );
      } else {
        newSelection.push({entityID, modelId});
      }
    });
    this.selectedElements = newSelection;
  }

  setSelectionBoxIsEnabled(bool) {
    this.selectionBoxIsEnabled = bool;
  }

  initSelectionBox() {
    if (this.selectionBox) return;

    const camera = this.editor.cameras.camera;
    const camera1 = this.editor.cameras.camera1;
    const scene = this.editor.scene;

    this.selectionBox3D = new SelectionBox(camera, scene);
    this.selectionBox2D = new SelectionBox(camera1, scene);
    this.selectionBoxHelper = new SelectionHelper(this.editor);
  }

  setSelectionBox(mode) {
    if (mode === "2D") {
      this.selectionBoxContainer = this.editor.multiviews.el1;
      this.selectionBox = this.selectionBox2D;
    } else {
      this.selectionBoxContainer = this.editor.canvas.parentElement;
      this.selectionBox = this.selectionBox3D;
    }
  }

  handleSelectionBoxStart = (event) => {
    if (!event.ctrlKey) {
      // for (const item of this.selectionBox.collection) {
      //   const entity = this.editor.getObjectEntityByObjectId(item.id);
      //   console.log("UNSELECT12");
      //   entity?.unselect();
      // }
      if (this.editor.caplaEditor.openSections.fixedViewersBox) {
        const annotationManager =
          this.editor.caplaEditor.editorPdf?.webViewer.Core.annotationManager;
        annotationManager.deselectAllAnnotations();
      }
      this.unselect();
    } else {
      this.selectionBox.collection = [];
    }

    const rect = this.selectionBoxContainer.getBoundingClientRect();
    this.selectionBox.startPoint.set(
      ((event.clientX - rect.left) / rect.width) * 2 - 1,
      -((event.clientY - rect.top) / rect.height) * 2 + 1,
      0.5
    );
  };

  handleSelectionBoxMove = (event) => {
    if (this.selectionBoxHelper.isDown) {
      const rect = this.selectionBoxContainer.getBoundingClientRect();

      for (let i = 0; i < this.selectionBox.collection.length; i++) {
        const item = this.selectionBox.collection[i];
        const entity = this.editor.getObjectEntityByObjectId(item.id);
        // const entityIsSelected = this.selectedElements.find(
        //   (e) =>
        //     e.modelId === entity?.modelId && e.entityID === entity?.entityID
        // );
        entity?.unselect();
      }

      this.selectionBox.endPoint.set(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1,
        0.5
      );
      const allSelected = this.selectionBox.select();
      for (let i = 0; i < allSelected.length; i++) {
        const entity = this.editor.getObjectEntityByObjectId(allSelected[i].id);
        const entityIsAlreadySelected = this.selectedElements.find(
          (m) =>
            m.entityID === entity?.entityID && m.modelId === entity?.modelId
        );
        if (!entityIsAlreadySelected) entity?.select();
      }
    }
  };

  handleSelectionBoxEnd = (event) => {
    const rect = this.selectionBoxContainer.getBoundingClientRect();
    this.selectionBox.endPoint.set(
      ((event.clientX - rect.left) / rect.width) * 2 - 1,
      -((event.clientY - rect.top) / rect.height) * 2 + 1,
      0.5
    );
    const allSelected = this.selectionBox.select();
    const selectedMeasurements = [];
    for (let i = 0; i < allSelected.length; i++) {
      const entity = this.editor.getObjectEntityByObjectId(allSelected[i].id);
      if (entity && entity.type === "MEASUREMENT_ELEMENT" && !entity.hidden)
        selectedMeasurements.push(entity);
    }
    const selectedIds = selectedMeasurements.map((m) => m.entityID);
    if (!event.ctrlKey) {
      if (this.editor.caplaEditor.openSections.fixedViewersBox) {
        const selected = new Set(selectedIds);
        const annotationManager =
          this.editor.caplaEditor.editorPdf?.webViewer.Core.annotationManager;
        const annots = annotationManager
          ?.getAnnotationsList()
          .filter((a) => selected.has(a.Id));
        annotationManager.selectAnnotations(annots);
      }
      this.selectedElements = selectedMeasurements.map(
        ({entityID, modelId}) => ({
          entityID,
          modelId,
        })
      );
      this.editor.dispatch(setSelectedMeasurementIds(selectedIds));
    } else {
      this.editor.dispatch(updateSelectedMeasurementIds(selectedIds));
      this.updateSelectedElementsWithEntities(selectedMeasurements);
    }
  };

  enableSelectionBox = (mode) => {
    console.log("enableSelectionBox");
    this.setSelectionBoxIsEnabled(true);
    this.initSelectionBox();

    this.selectionBoxHelper.enable(mode);
    this.setSelectionBox(mode);

    this.selectionBoxContainer.addEventListener(
      "pointerdown",
      this.handleSelectionBoxStart
    );
    this.selectionBoxContainer.addEventListener(
      "pointermove",
      this.handleSelectionBoxMove
    );
    this.selectionBoxContainer.addEventListener(
      "pointerup",
      this.handleSelectionBoxEnd
    );
  };

  disableSelectionBox = (mode) => {
    this.setSelectionBoxIsEnabled(false);
    this.selectionBoxHelper.disable(mode);

    this.selectionBoxContainer.removeEventListener(
      "pointerdown",
      this.handleSelectionBoxStart
    );
    this.selectionBoxContainer.removeEventListener(
      "pointermove",
      this.handleSelectionBoxMove
    );
    this.selectionBoxContainer.removeEventListener(
      "pointerup",
      this.handleSelectionBoxEnd
    );
  };

  // pick & unpick

  pickElementEntity({modelId, entityID}) {
    const modelEntity = this.editor.getEntity(modelId);
    if (modelEntity?.pickElement) modelEntity.pickElement(entityID);
    this.pickedElement = {modelId, entityID};
  }

  unpick() {
    const {modelId, entityID} = this.pickedElement;
    if (modelId && entityID) {
      const modelEntity = this.editor.getEntity(modelId);
      if (modelEntity?.unpickElement) modelEntity.unpickElement(entityID);
      this.pickedElement = {};
    }
  }

  // select & unselect

  testEntityExists({entityID}) {
    return this.editor.objectEntities.find((e) => e.entityID === entityID);
  }

  selectElementEntity({modelId, entityID, keepSelection, fromPdf}) {
    // console.log("haaaaaaaaaaaa", keepSelection, fromPdf)
    // const pdfOpen = this.editor.caplaEditor.openSections.fixedViewersBox;
    if (!keepSelection && !this.selectionBoxIsEnabled) {
      this.unselect();
    }
    if (modelId && entityID) {
      const modelEntity = this.editor.getEntity(modelId);

      if (!modelEntity?.selectElement || !modelEntity?.unselectElement) return;

      const element = modelEntity?.getMeasurement(entityID);

      const isAlreadySelected = this.selectedElements.find(
        (e) => e.entityID === entityID && e.modelId === modelId
      );

      if (keepSelection && isAlreadySelected) {
        // modelEntity?.unselectElement(entityID);
        element?.unselect();
        this.selectedElements = this.selectedElements.filter(
          (e) => e.modelId !== modelId || e.entityID !== entityID
        );
        if (!fromPdf) {
          this.editor.caplaEditor.editorPdf.updateSelection = false;
          const annotationManager =
            this.editor.caplaEditor.editorPdf.webViewer.Core.annotationManager;
          // if (!keepSelection) annotationManager.deselectAllAnnotations();
          const annots = annotationManager
            ?.getAnnotationsList()
            .filter((a) => a.Id === entityID);
          if (annots.length > 0) {
            annotationManager.deselectAnnotations(annots);
          }
          this.editor.caplaEditor.editorPdf.updateSelection = true;
        }
      } else if (!isAlreadySelected) {
        // modelEntity?.selectElement(entityID);
        element?.select();
        this.selectedElements.push({modelId, entityID});
        this.selectedElement = {modelId, entityID};
        if (!fromPdf) {
          this.editor.caplaEditor.editorPdf.updateSelection = false;
          const annotationManager =
            this.editor.caplaEditor.editorPdf.webViewer.Core.annotationManager;
          const annots = annotationManager
            ?.getAnnotationsList()
            .filter((a) => a.Id === entityID);
          if (annots.length > 0) {
            if (!keepSelection) annotationManager.deselectAllAnnotations();
            annotationManager.selectAnnotations(annots);
          }
          this.editor.caplaEditor.editorPdf.updateSelection = true;
        }
      }
    }
    // } else {
    //   if (!keepSelection && !this.selectionBoxIsEnabled) {
    //     // this.selectedElement = {};
    //     // this.selectedElements = [];
    //     this.unselect();
    //   }
    // }
    return fromPdf;
  }

  unselectElementEntity({modelId, entityID}) {
    const modelEntity = this.editor.getEntity(modelId);
    modelEntity?.unselectElement(entityID);
    this.selectedElements = this.selectedElements.filter(
      (e) => e.modelId !== modelId || e.entityID !== entityID
    );
  }

  unselect() {
    // console.log("haaaaaaaaaaaa unselect")
    this.selectedElements.forEach((selectedElement) => {
      const {modelId, entityID} = selectedElement;
      if (modelId && entityID) {
        const modelEntity = this.editor.getEntity(modelId);
        if (modelEntity?.unselectElement) {
          modelEntity.unselectElement(entityID);
          this.selectedElements = this.selectedElements.filter(
            (e) => e.modelId !== modelId || e.entityID !== entityID
          );
        }
      }
    });
    this.selectedElement = {};
    // this.selectedElements = [];
  }

  // // ifc element

  // selectIfcElement({modelId, expressID}) {
  //   const entity = this.editor.getEntity(modelId);
  //   if (entity?.selectElement) entity.selectElement(expressID);
  //   this.selectedIfcElement = {modelId, expressID};
  // }

  // async selectIfcElementByFaceIndex({modelId, faceIndex}) {
  //   let expressID;
  //   const entity = this.editor.getEntity(modelId);

  //   // clear selection if null
  //   if (!modelId || !faceIndex) {
  //     this.clearIfcElementSelection();
  //   } else if (
  //     this.selectedIfcElement?.modelId &&
  //     this.selectedIfcElement?.modelId !== modelId
  //   ) {
  //     // clear selection if different model
  //     this.clearIfcElementSelection();
  //   }
  //   if (entity?.ifcModelEntity) {
  //     console.log("click41");
  //     const element = await entity.ifcModelEntity.selectElementByFaceIndex(
  //       faceIndex
  //     );
  //     console.log("click42");
  //     expressID = element.expressID;
  //   }
  //   this.selectedIfcElement = {modelId, expressID};
  //   return {modelId, expressID};
  // }

  // clearIfcElementSelection() {
  //   const {modelId} = this.selectedIfcElement;
  //   if (modelId) {
  //     const entity = this.editor.getEntity(modelId);
  //     if (entity?.ifcModelEntity?.unselectElement)
  //       entity.ifcModelEntity.unselectElement();
  //   }
  //   this.selectedIfcElement = {};
  // }

  // markers

  deleteMarker({markerId, markersModelId}) {
    const markersModelE = this.editor.getEntity(markersModelId);
    markersModelE.deleteMarker(markerId);
  }

  selectMarker(marker) {
    this.unselectMarker(this.selectedMarker);

    if (!marker || !marker.markerId) {
      this.selectedMarker = null;
    } else {
      const {markerId, markersModelId} = marker;
      const markersModelE = this.editor.getEntity(markersModelId);
      markersModelE?.selectMarker(markerId);
      this.selectedMarker = marker;
    }
  }

  unselectMarker(marker) {
    if (!marker) return;
    const {markerId, markersModelId} = marker;
    const markersModelE = this.editor.getEntity(markersModelId);
    markersModelE?.unselectMarker(markerId);
  }

  // parse camera

  parseCamera(mode) {
    const cam = this.editor.cameras.camera;
    const orb = this.editor.controls.orbitControls;
    const cam1 = this.editor.cameras.camera1;
    const orb1 = this.editor.controls.orbitControls1;

    const camera = mode === "3D" ? cam : cam1;
    const controls = mode === "3D" ? orb : orb1;

    const p = camera.position;
    const t = controls.target;
    return {
      mode,
      position: {x: p.x, y: p.y, z: p.z},
      target: {x: t.x, y: t.y, z: t.z},
    };
  }
  // temp object and marker

  createTempObject() {
    let w, h, d;
    w = h = d = 1;

    const box = new Mesh(new BoxGeometry(w, h, d), material);
    this.editor.scene.add(box);

    return box;
  }

  setTempObjectPosition(position) {
    this.tempObject.position.set(position.x, position.y, position.z);
  }

  hideTempObject() {
    this.tempObject.layers.disable(1);
  }

  showTempObject() {
    this.tempObject.layers.enable(1);
  }

  clearScene() {
    this.hideTempObject();
  }

  showEdge() {
    this.edges = true;
    this.editor.entities.forEach((entity) => {
      if (entity.showEdges) entity.showEdges();
    });
  }

  hideEdge() {
    this.edges = false;
    this.editor.entities.forEach((entity) => {
      if (entity.hideEdges) entity.hideEdges();
    });
  }

  showVoid() {
    this.voids = true;
    this.editor.entities.forEach((entity) => {
      if (entity.showVoids) entity.showVoids();
    });
  }

  hideVoid() {
    this.voids = false;
    this.editor.entities.forEach((entity) => {
      if (entity.hideVoids) entity.hideVoids();
    });
  }

  showTransparent() {
    this.globalTransparency = true;
    this.editor.entities.forEach((entity) => {
      if (entity.setTransparent) entity.setTransparent();
    });
  }

  showOpaque() {
    this.globalTransparency = false;
    this.editor.entities.forEach((entity) => {
      if (entity.setOpaque) entity.setOpaque();
    });
  }

  // markerTemp

  showMarkerTemp() {
    this.markerTemp.show();
  }

  hideMarkerTemp() {
    this.markerTemp.hide();
  }

  moveMarkerTempTo(position, normal) {
    this.markerTemp.setPosition(position, normal);
  }

  hideMarkers() {
    this.markers.forEach((m) => m.hide());
  }

  moveMarkerTemp1To(position, normal) {
    this.markerTemp1.setPosition(position, normal);
  }

  showMarkerTemp1() {
    this.markerTemp1.show();
  }
  // views

  getTargetAndPositionFromBox(bb) {
    const target = new Vector3();
    //
    const size = new Vector3();
    bb.getSize(size);
    const center = new Vector3();
    bb.getCenter(center);

    target.copy(center);

    const position2 = new Vector3(
      center.x + Math.max(2, size.x * 0.6),
      center.y + Math.max(2, size.y * 0.6),
      center.z + Math.max(3, size.z * 0.6)
    );
    return {target, position: position2};
  }

  getSceneBox3() {
    const objectEntities = this.editor.objectEntities.filter(
      (e) =>
        e.object?.layers.isEnabled(1) && e.object?.type === "Mesh" && !e.hidden
    );
    const entitiesCount = objectEntities?.length;
    const bb = this.getBox3FromObjectEntities(objectEntities);
    return {bb, entitiesCount};
  }

  getBox3FromObjectEntities(objectEntities) {
    if (objectEntities?.length > 0) {
      let bbUnit = new Box3();
      const objects = objectEntities
        .filter(
          (e) => e.object?.layers.isEnabled(1) && e.object?.type === "Mesh"
        )
        .map((e) => e.object);

      bbUnit.setFromObject(objects[0]);
      let {x, y, z} = bbUnit.max;
      let {x: _x, y: _y, z: _z} = bbUnit.min;

      for (const object of objects) {
        bbUnit.setFromObject(object);
        const {x: minx, y: miny, z: minz} = bbUnit.min;
        const {x: maxx, y: maxy, z: maxz} = bbUnit.max;
        x = !isNaN(maxx) ? Math.max(x, maxx) : x;
        y = !isNaN(maxy) ? Math.max(y, maxy) : y;
        z = !isNaN(maxz) ? Math.max(z, maxz) : z;
        _x = !isNaN(minx) ? Math.min(_x, minx) : _x;
        _y = !isNaN(minx) ? Math.min(_y, miny) : _y;
        _z = !isNaN(minx) ? Math.min(_z, minz) : _z;
      }
      return new Box3(new Vector3(_x, _y, _z), new Vector3(x, y + 7, z));
    } else {
      return new Box3(new Vector3(0, 0, 0), new Vector3(10, 10, 10)); // need that for the initial camera computation
    }
  }
  // Misc

  zoomOut() {
    const {bb, entitiesCount} = this.getSceneBox3();

    // state change for initial camera
    if (entitiesCount > 0)
      this.editor.dispatch(setInitZoomOutWithEntities(true));

    // camera

    const {target, position} = this.getTargetAndPositionFromBox(bb);

    const controls = this.editor.controls.orbitControls;
    const camera = this.editor.cameras.camera;

    this.editor.animator.move(camera.position, position);
    this.editor.animator.move(controls.target, target);

    // camera 1

    const height = Math.abs(bb.max.z - bb.min.z);
    const center = bb.getCenter(new Vector3());
    this.updateCam1(center.x, center.z, height);
  }

  hideGrid() {
    this.editor.dispatch(setGrid(false));
    this.editor.grid.hide();
  }

  setShowEdges(show) {
    if (show) {
      this.editor.dispatch(setShowEdge(true));
      this.showEdge();
    } else {
      this.editor.dispatch(setShowEdge(false));
      this.hideEdge();
    }
  }

  setTransparency(transparent) {
    if (transparent) {
      this.showTransparent();
    } else {
      this.showOpaque();
    }
  }

  hideMarkersModels() {
    this.editor.entities.forEach((entity) => {
      if (entity.type === "MARKERS_MODEL") {
        entity.hide();
      }
    });
  }

  // optimization

  saveState() {
    this.state = {
      edges: this.edges,
    };
  }

  restoreState() {
    if (this.optimIsEnabled) {
      console.log("stop optim");
      this.optimized = false;
      if (this.state.edges) {
        this.showEdge();
      } else {
        this.hideEdge();
      }
    }
  }

  optimize() {
    if (this.optimIsEnabled) {
      console.log("optimize");
      this.optimized = true;
      this.saveState();
      this.hideEdge();
    }
  }

  setOptimIsEnabled(bool) {
    this.optimIsEnabled = Boolean(bool);
    this.editor.dispatch(setOptimIsEnabled(bool));
  }

  // cameras

  activeCamera3D() {
    this.editor.cameras.activeCamera = this.editor.cameras.camera;
    this.editor.cameras.activeCameraName = "CAMERA";
    this.editor.controls.activeControls = this.editor.controls.orbitControls;
    this.editor.controls.orbitControls.object = this.editor.cameras.camera;
    this.editor.controls.orbitControls.minPolarAngle = 0;
    this.editor.controls.orbitControls.maxPolarAngle = Math.PI;
    this.editor.controls.orbitControls.minAzimuthAngle = Infinity;
    this.editor.controls.orbitControls.maxAzimuthAngle = Infinity;
    this.editor.controls.orbitControls.update();
  }

  resetCameras() {
    //this.editor.controls.orbitControls.touches.ONE = TOUCH.DOLLY_ROTATE;
    //this.editor.controls.orbitControls.touches.TWO = TOUCH.PAN;
    this.editor.controls.orbitControls.target.set(0, 0, 0);
    this.editor.controls.orbitControls.update();
    this.editor.cameras.camera.position.set(10, 10, 10);

    this.editor.controls.orbitControls1.target.set(0, -1000, 0);
    this.editor.controls.orbitControls1.update();
    this.editor.cameras.camera.position.set(0, 500, 0);
  }

  getCam1BoundsCoord() {
    const cam1 = this.editor.cameras.camera1;
    const {x, y, z} = cam1.position;
    const {top, bottom, left, right, zoom} = cam1;
    const bl = {x: x - right / zoom, z: z + top / zoom, y: 0};
    const tr = {x: x + right / zoom, z: z - top / zoom, y: 0};
    return {bl, tr};
  }

  getCam1BoundsLatLng() {
    const {bl, tr} = this.getCam1BoundsCoord();
    const sw = this.editor.mapEditor.azmEditor.coordToLatLng(bl);
    const ne = this.editor.mapEditor.azmEditor.coordToLatLng(tr);
    return {sw, ne};
  }

  updateCam1(x, z, height, width) {
    const el1 = this.editor.multiviews.el1;
    const aspect1 = el1.offsetWidth / el1.offsetHeight;

    const cam1 = this.editor.cameras.camera1;
    const orb1 = this.editor.controls.orbitControls1;

    if (width)
      this.editor.cameras.updateOrthoCamera(cam1, width / aspect1, width);

    if (typeof x === "number" && !isNaN(x)) {
      cam1.position.setX(x);
      orb1.target.setX(x);
    }
    if (typeof z === "number" && !isNaN(z)) {
      cam1.position.setZ(z);
      orb1.target.setZ(z);
    }

    orb1.update();
  }

  // mode

  setPointerMode(value) {
    this.pointerMode = value;
  }

  disablePointer() {
    this.setPointerMode(false);
    this.editor.pointer.disable();
  }

  // animate

  jumpModel(modelId) {
    try {
      const entity = this.editor.getEntity(modelId);
      const p = entity?.object.position;
      if (p) this.jumpPosition(p);
    } catch (e) {
      console.log("error", e);
    }
  }

  jumpPosition(p) {
    try {
      const delta = 0.5;
      const initPosition = {x: p.x, y: p.y, z: p.z};
      const finalPosition = {x: p.x, y: p.y + delta, z: p.z};
      const path = [finalPosition, initPosition];
      this.editor.animator.moveAlongPath(p, path, 0.5);
    } catch (e) {
      console.log("error", e);
    }
  }

  // size of the scene

  getSize() {
    const {bb} = this.getSceneBox3();
    if (bb) {
      const size = new Vector3();
      bb.getSize(size);
      return {
        width: size.x,
        height: size.y,
        depth: size.z,
        minZ: bb.min.y,
        maxZ: bb.max.y,
        maxX: bb.max.x, // to place zone on horizontal plane by default
      };
    }
  }

  // rotate

  rotateCam1(angle) {
    const rot = angle;
    this.editor.controls.orbitControls1.minAzimuthAngle = rot;
    this.editor.controls.orbitControls1.maxAzimuthAngle = rot;
  }

  // camera

  updateCamera(parsedCamera) {
    const {mode, position, target} = parsedCamera;

    const cam = this.editor.cameras.camera;
    const orb = this.editor.controls.orbitControls;
    const cam1 = this.editor.cameras.camera1;
    const orb1 = this.editor.controls.orbitControls1;

    const camera = mode === "2D" ? cam1 : cam;
    const controls = mode === "2D" ? orb1 : orb;

    const positionV = new Vector3(position.x, position.y, position.z);
    const targetV = new Vector3(target.x, target.y, target.z);

    this.editor.cameras.animator.move(camera.position, positionV);
    this.editor.cameras.animator.move(controls.target, targetV);

    controls.update();
  }

  focusMarker(marker) {
    if (!marker) return;
    const {mode, position, target} = this.parseCamera();

    const newPosition = {
      x: marker.x - 5,
      y: marker.y + 1,
      z: marker.z + 2,
    };
    const {x, y, z} = marker;
    const newTarget = {x, y, z};

    const parsedCamera = {mode, position: newPosition, target: newTarget};
    this.updateCamera(parsedCamera);
  }

  focusElementType(elementTypeId) {
    const objectEntities = this.editor.objectEntities.filter(
      (e) => e.elementTypeId === elementTypeId && !e.hidden
    );
    if (objectEntities.length > 0) {
      const bb = this.getBox3FromObjectEntities(objectEntities);
      const {target, position} = this.getTargetAndPositionFromBox(bb);

      const viewMode = this.editor.viewMode === "3D" ? "3D" : "2D";
      const {mode} = this.parseCamera(viewMode);

      const parsedCamera = {mode, position, target};

      this.updateCamera(parsedCamera);
    }
  }

  focusElementTypes(elementTypeIds) {
    const objectEntities = this.editor.objectEntities.filter(
      (e) => elementTypeIds.includes(e.elementTypeId) && !e.hidden
    );
    if (objectEntities.length > 0) {
      const bb = this.getBox3FromObjectEntities(objectEntities);
      const {target, position} = this.getTargetAndPositionFromBox(bb);

      const viewMode = this.editor.viewMode === "3D" ? "3D" : "2D";
      const {mode} = this.parseCamera(viewMode);

      const parsedCamera = {mode, position, target};

      this.updateCamera(parsedCamera);
    }
  }

  focusMeasurement(measurement) {
    console.log("FOCUSM", measurement);
    if (!measurement) return;
    // avoid to move camera is the measurment is selected from the 3D view.
    const entity = this.editor.getEntity(measurement.measurementsModelId);
    const element = entity?.getMeasurement(measurement.id);
    if (element?.object) this.jumpPosition(element.object.position);
    if (element?.selected) return;

    const viewMode = this.editor.viewMode === "3D" ? "3D" : "2D";
    const {mode, position, target} = this.parseCamera(viewMode);
    const {path3D, zSup, zInf, height} = measurement;

    if (path3D?.length === 0) return;

    if (typeof zSup === "number" || typeof zInf === "number") {
      const y = typeof zSup === "number" ? zSup : zInf + height;

      const [x, z] = path3D[0];

      const deltaTx = x - target.x;
      const deltaTz = z - target.z;

      // test if camera Z far away and below measurement
      const needMajorMove =
        position.y < y ||
        Math.abs(position.y - y) > 10 ||
        Math.abs(position.x - x) > 10 ||
        Math.abs(position.z - z) > 10;

      const newPosition = {
        x: needMajorMove ? x + 3 : position.x + deltaTx,
        y: needMajorMove ? y + 2 : position.y,
        z: needMajorMove ? z + 3 : position.z + deltaTz,
      };
      const newTarget = {x, y: needMajorMove ? y : target.y, z};

      const parsedCamera = {mode, position: newPosition, target: newTarget};

      this.updateCamera(parsedCamera);
    }
  }

  zoomOutMeasurement(measurement) {
    if (!measurement) return;
    const viewMode = this.editor.viewMode === "3D" ? "3D" : "2D";
    const {mode} = this.parseCamera(viewMode);
    const {path3D, zSup, zInf, height} = measurement;
    if (path3D?.length === 0) return;
    if (typeof zSup === "number" || typeof zInf === "number") {
      const y = typeof zSup === "number" ? zSup : zInf + height;
      const [x, z] = path3D[0];
      const newTarget = {x, y, z};
      const newPosition = {x: x + 3, y: y + 2, z: z + 3};
      const parsedCamera = {mode, position: newPosition, target: newTarget};
      this.updateCamera(parsedCamera);
    }
  }

  focusImageModels(modelIds) {
    const objectEntities = this.editor.objectEntities.filter((e) =>
      modelIds.includes(e.modelId)
    );
    if (objectEntities.length > 0) {
      const bb = this.getBox3FromObjectEntities(objectEntities);
      const {target, position} = this.getTargetAndPositionFromBox(bb);

      const viewMode = this.editor.viewMode === "3D" ? "3D" : "2D";
      const {mode} = this.parseCamera(viewMode);

      const parsedCamera = {mode, position, target};

      this.updateCamera(parsedCamera);
    }
  }

  focusImageModel(model) {
    if (model?.type !== "IMAGE") return;
    const {mode, position, target} = this.parseCamera();

    const entity = this.editor.getEntity(model.id);

    if (!entity) return;

    const newTargetP = entity.object.position;
    const newTarget = {x: newTargetP.x, y: newTargetP.y, z: newTargetP.z};

    const dir = new Vector3(0, 0, 5);
    entity.object.localToWorld(dir);

    //const newPositionV = new Vector3(newTarget.x, newTarget.y, newTarget.z);
    //const dist = 5;
    //newPositionV.sub(dir.normalize().multiplyScalar(dist));

    // const newPosition = {
    //   x: newPositionV.x,
    //   y: newPositionV.y,
    //   z: newPositionV.z,
    // };

    const newPosition = {
      x: dir.x,
      y: dir.y,
      z: dir.z,
    };

    const parsedCamera = {mode, position: newPosition, target: newTarget};
    this.updateCamera(parsedCamera);
  }

  /*
   * Image model
   */
  /*
   * Images
   */

  hideImageModel(modelId) {
    const entity = this.editor.getEntity(modelId);
    if (entity) entity.hide();
    this.editor.dispatch(
      updateModel({
        updatedModel: {id: modelId, hidden: true},
        sync: false,
        updateModelInStore: false,
      })
    );
  }

  hideAllImageModels() {
    const modelsVisibility = {};
    const imageEntities = this.editor.entities.filter(
      (e) => e.type === "IMAGE_MODEL"
    );
    imageEntities.forEach((entity) => {
      entity.hide();
      modelsVisibility[entity.entityID] = false;
    });
    this.editor.dispatch(updateModelsVisibility(modelsVisibility));
  }

  async showImageModel(modelId, imageTransfo) {
    const state = store.getState();
    const modelsStatus = state.viewer3D.modelsSyncStatus;
    const status = modelsStatus.find((s) => s.modelId === modelId);
    const modelsWithInitialTexture = state.viewer3D.modelsWithInitialTexture;
    const model = state.viewer3D.models.find((m) => m.id === modelId);

    const loaded = status?.status === "loaded";
    const noTexture = !modelsWithInitialTexture.includes(modelId);
    const notLoaded = status?.status !== "loaded";

    if (notLoaded) {
      await this.editor.loader.initialLoadingImageModel(model, {
        loadTexture: true,
      });
    } else if (noTexture && loaded) {
      await this.editor.loader.loadImageModelInitialTexture(modelId);
    }
    // v comment below v  // used to show the main part of the image model after initial loading
    // } else if (loaded) {
    //   const entity = this.editor.getEntity(modelId);
    //   if (entity) entity.show();
    // }
    const entity = this.editor.getEntity(modelId);
    if (entity) {
      entity.show();
      if (imageTransfo?.showMainPart) {
        entity.showMainPart();
        if (imageTransfo?.delta && entity.tempTranslate) {
          entity.tempTranslate(imageTransfo.delta);
        }
        if (imageTransfo?.opacity && entity.setOpacity) {
          entity.setOpacity(imageTransfo.opacity);
        }
      }
    }

    //

    //

    this.editor.dispatch(
      updateModel({
        updatedModel: {
          id: modelId,
          hidden: false,
          showMainPart: imageTransfo?.showMainPart,
        },
        sync: false,
        updateModelInStore: false,
      })
    );
  }

  async showImageModelsAsync(modelIds, imagesTransfos) {
    await Promise.all(
      modelIds.map((modelId) => {
        const imageTransfo = imagesTransfos?.find(
          (t) => t.imageModelId === modelId
        );
        return this.showImageModel(modelId, imageTransfo);
      })
    );
  }

  showImageModels(imageModelIds) {
    const imageEntities = this.editor.entities.filter(
      (e) => e.type === "IMAGE_MODEL"
    );
    imageEntities.forEach((imageEntity) => {
      if (imageModelIds.includes(imageEntity.entityID)) {
        imageEntity.show();
      } else {
        imageEntity.hide();
      }
    });
  }

  /*
   * zoneImage <=> zonePdf
   */

  get2DPointsInHorizontalImageModelCoord(points, imageModelId) {
    const entity = this.editor.getEntity(imageModelId);
    const imageY = entity?.object.position.y;

    const newPoints = points.map(([x, y]) => {
      const P = new Vector3(x, imageY, y);
      entity?.object.worldToLocal(P);
      return [P.x, P.y];
    });

    return newPoints;
  }

  /*
   * 2D <=> 3D
   */

  addMeasurement3D(measurement) {
    const entity = this.editor.getEntity(measurement.measurementsModelId);
    if (entity) entity.addMeasurement(measurement);
  }

  updateMeasurement3D(measurement) {
    const entity = this.editor.getEntity(measurement.measurementsModelId);
    if (entity) {
      entity.updateMeasurement(measurement);
    }
  }

  getAutoGeo3D(point3D, deltaZ) {
    const origin = new Vector3(point3D.x, point3D.y + deltaZ, point3D.z);
    const topDir = new Vector3(0, 1, 0);
    const bottomDir = new Vector3(0, -1, 0);

    this.raycaster.far = 20;
    this.raycaster.firstHitOnly = true;

    // results
    let zSup, zInf, height;
    let intersects, intersect;

    // objects
    let objects = this.editor.objectEntities
      .filter((e) => e.type === "MEASUREMENT_ELEMENT" && !e.hidden)
      .map((entity) => entity.object);

    // zSup
    this.raycaster.set(origin, topDir);
    intersects = this.raycaster.intersectObjects(objects);
    intersect = intersects[0];
    if (intersect) zSup = origin.y + intersect.distance;

    // zInf
    this.raycaster.set(origin, bottomDir);
    intersects = this.raycaster.intersectObjects(objects);
    intersect = intersects[0];
    if (intersect) zInf = origin.y - intersect.distance;

    if (typeof zInf === "number" && typeof zSup === "number")
      height = zSup - zInf;

    return {height, zSup, zInf};
  }

  /*
   * Element types
   */

  updateElementTypeMaterial(type) {
    let material = this.elementTypesMaterials[type.id];
    if (!material) {
      material = new MeshLambertMaterial({
        color: type.color,
      });
    }
    if (Array.isArray(material)) material = material[1];
    material.color = type.color;
    if (this.globalTransparency) {
      material.transparent = true;
      material.opacity = this.editor ? this.editor.opacity : 0.74;
      material.side = DoubleSide;
    } else {
      material.transparent = false;
      material.opacity = 1.0;
      material.side = FrontSide;
    }
    if (["BRIDGE", "BOWL", "BANK"].includes(type.drawingShape))
      material.side = DoubleSide;
    if (type.drawingShape !== "BEAM")
      this.elementTypesMaterials[type.id] = material;
    else {
      // this.elementTypesMaterials[type.id] = [this.embeddedBeamMaterial, material];
      const beamMaterial = new MeshLambertMaterial({
        color: type.color,
        transparent: true,
        opacity: material.opacity * 0.5,
        depthTest: true,
        side: DoubleSide,
      });
      this.elementTypesMaterials[type.id] = [beamMaterial, material];
    }
    // if (type.style3D === "OPAQUE") material.transparent = false;
    // if (type.style3D === "TRANSPARENT") material.transparent = true;
  }

  applyElementTypeMaterial(elementType) {
    const entities = this.editor.entities.filter(
      (e) => e.type === "MEASUREMENTS_MODEL"
    );
    entities.forEach((e) => {
      e.applyElementTypeMaterial(elementType);
    });
  }

  applyMeasurementsMaterials(elementType) {
    const entities = this.editor.entities.filter(
      (e) => e.type === "MEASUREMENTS_MODEL"
    );
    entities.forEach((e) => {
      e.applyMeasurementsMaterials(elementType);
    });
  }

  /*
   * Hide / show models
   */

  showModel(modelId) {
    const entity = this.editor.getEntity(modelId);
    if (entity?.show) entity.show();
  }
  hideModel(modelId) {
    const entity = this.editor.getEntity(modelId);
    if (entity?.hide) entity.hide();
  }
  // hideAllImageModels() {
  //   const entities = this.editor.entities.filter(
  //     (e) => e.type === "IMAGE_MODEL"
  //   );
  //   console.log("hide entities", entities);
  //   entities.forEach((entity) => {
  //     if (entity?.hide) entity.hide();
  //   });
  // }

  /*
   * Measurements void
   */

  // addVoids(measurements) {
  //   measurements
  //     .filter((m) => m.voids?.length > 0)
  //     .forEach((measurement) => {
  //       const voidIds = measurement.voids;
  //       const entity = this.editor.objectEntities.find(
  //         (e) => e.entityID === measurement.id
  //       );
  //       if (!entity) return;
  //       const voidEntities = this.editor.objectEntities.filter((e) =>
  //         voidIds.includes(e.entityID)
  //       );
  //       const voidMeshes = voidEntities.map((e) => e.object);
  //       entity.substractVoids(voidMeshes);
  //     });
  // }

  // fillVoids(measurements) {
  //   measurements
  //     .filter((m) => m.voids?.length > 0)
  //     .forEach((measurement) => {
  //       const entity = this.editor.objectEntities.find(
  //         (e) => e.entityID === measurement.id
  //       );
  //       if (!entity) return;

  //       entity.fillVoids();
  //     });
  // }

  /*
   * Measurement filters
   */

  applyFiltersToMeasurementsByMeasurementIds(
    measurementsModelIds,
    measurementIds,
    viewMode,
    applyFilters = true
  ) {
    //
    const entities = this.editor.entities?.filter(
      (e) => e.type === "MEASUREMENTS_MODEL"
    );
    console.log(
      "applyFiltersMeasurementsDFD",
      entities?.length,
      measurementsModelIds?.length,
      measurementIds?.length
    );
    entities.forEach((e) => {
      if (measurementsModelIds.includes(e.entityID) || !applyFilters) {
        e.show();
        e.applyFiltersByMeasurementIds(measurementIds, viewMode, applyFilters);
      } else {
        e.hide();
      }
    });
    //
    this.editor.dispatch(setFiltersAppliedToMeasurementsIn3DAt(Date.now()));
  }

  updateLayoutFromProgress({measurementsProgress, mode}) {
    const entities = this.editor.entities.filter(
      (e) => e.type === "MEASUREMENTS_MODEL"
    );
    entities.forEach((e) => {
      e.updateLayoutFromProgress({measurementsProgress, mode});
    });
  }

  /*
   * View by sector
   */

  fitCam1To({modelIds}) {
    const bb = new Box3();
    if (!modelIds || modelIds?.length === 0) return;
    const objects = [];
    modelIds.forEach((modelId) => {
      const entity = this.editor.getEntity(modelId);
      const imageEntity = entity?.imageEntity;
      if (imageEntity) objects.push(imageEntity.object);
    });
    for (const object of objects) {
      if (object?.position) bb.expandByObject(object);
    }
    const size = bb.getSize(new Vector3());
    const center = bb.getCenter(new Vector3());
    const width = size.x;
    const {x, z} = center;

    console.log("FITCAM1 - 2", x, z, width);

    this.updateCam1(x, z, null, width);
  }

  setViewBySector({zoneImageModelIds}) {
    // cam1
    this.fitCam1To({modelIds: zoneImageModelIds});

    // images opacity
    zoneImageModelIds.forEach((id) => {
      const entity = this.editor.getEntity(id);
      if (entity) {
        entity.showMainPart();
        entity.setOpacity(50);
      }
    });
  }

  /*
   * cam <=> cam1
   */

  getVisibleMeasurementsHeights() {
    return this.editor.objectEntities
      .filter((e) => e.type === "MEASUREMENT_ELEMENT" && !e.hidden)
      .map((e) => e.object?.position.y);
  }

  setCamFromCam1() {
    const heights = this.getVisibleMeasurementsHeights();
    const height = heights.length > 0 ? Math.max(...heights) : 0;

    console.log("heightHH", height);

    const cam1 = this.editor.cameras.camera1;
    const cam = this.editor.cameras.camera;

    const orbit1 = this.editor.controls.orbitControls1;
    const orbit = this.editor.controls.orbitControls;

    const top = cam1.top;
    const bottom = cam1.bottom;
    const viewHeight = (top - bottom) / cam1.zoom;

    const tan = Math.tan(((cam.fov / 2) * Math.PI) / 180);

    const distance = viewHeight / 2 / tan;

    const target = new Vector3(orbit1.target.x, height, orbit1.target.z);

    const cam1Dir = new Vector3();
    cam1.getWorldDirection(cam1Dir);

    const position = new Vector3();
    position.copy(target);
    position.sub(cam1Dir.multiplyScalar(distance));

    cam.position.set(position.x, position.y, position.z);
    orbit.target.set(target.x, target.y, target.z);
    orbit.update();

    this.editor.cameras.activeCamera = cam; // need that for the raycaster
  }

  /*
   * CAM 2
   */

  setCam2Height(height) {
    this.editor.cameras.camera2.position.setY(height);
    this.editor.controls.orbitControls2.target.setY(height);
    this.editor.controls.orbitControls2.update();
  }

  //resetCameras;

  /*
   * Context
   */

  applyContext(context, showImages = true, applyFilters = true) {
    console.log("Apply issue context", context);
    // image models
    const imageModelIds = context.imageModelIds;
    const imagesTransfos = context.imagesTransfos;

    if (imageModelIds?.length > 0 && showImages) {
      this.hideAllImageModels();
      this.showImageModelsAsync(imageModelIds, imagesTransfos);
    }
    // measurement filters
    if (applyFilters) {
      this.editor.dispatch(setFilters({reset: true}));
      let filters = context.measurementsFilters;
      if (!filters) filters = {};
      const {
        elementTypes,
        sectors,
        zones,
        rooms,
        materials,
        ressources,
        phases,
        p1s,
        p2s,
        p3s,
      } = filters;
      const _filters = {};
      let filterElementTypeId,
        filterSectorId,
        filterZoneId,
        filterRoomId,
        filterMaterialId,
        filterRessourceId,
        filterPhaseId,
        filterP1,
        filterP2,
        filterP3;
      if (Array.isArray(elementTypes)) {
        filterElementTypeId = elementTypes.filter((i) => i)[0];
        if (filterElementTypeId)
          _filters.filterElementTypeId = filterElementTypeId;
      }
      if (Array.isArray(sectors)) {
        filterSectorId = sectors.filter((i) => i)[0];
        if (filterSectorId) _filters.filterSectorId = filterSectorId;
      }
      if (Array.isArray(zones)) {
        filterZoneId = zones.filter((i) => i)[0];
        if (filterZoneId) _filters.filterZoneId = filterZoneId;
      }
      if (Array.isArray(rooms)) {
        filterRoomId = rooms.filter((i) => i)[0];
        if (filterRoomId) _filters.filterRoomId = filterRoomId;
      }
      if (Array.isArray(materials)) {
        filterMaterialId = materials.filter((i) => i)[0];
        if (filterMaterialId) _filters.filterMaterialId = filterMaterialId;
      }
      if (Array.isArray(ressources)) {
        filterRessourceId = ressources.filter((i) => i)[0];
        if (filterRessourceId) _filters.filterRessourceId = filterRessourceId;
      }
      if (Array.isArray(phases)) {
        filterPhaseId = phases.filter((i) => i)[0];
        if (filterPhaseId) _filters.filterPhaseId = filterPhaseId;
      }
      if (Array.isArray(p1s)) {
        filterP1 = p1s.filter((i) => i)[0];
        if (filterP1) _filters.filterP1 = filterP1;
      }
      if (Array.isArray(p1s)) {
        filterP2 = p2s.filter((i) => i)[0];
        if (filterP2) _filters.filterP2 = filterP2;
      }
      if (Array.isArray(p1s)) {
        filterP3 = p3s.filter((i) => i)[0];
        if (filterP3) _filters.filterP3 = filterP3;
      }
      this.editor.dispatch(setFilters(_filters));
    }
  }
}
